import React, {useState} from 'react';
import Table from "../ui/Table";
import {ISetting} from "../../models/types";
import {Input} from "../ui/Field";
import {IconClose, IconEdit, IconUpdate, StyleDeleteIcon} from "../ui/Icons";
import Flex from "../ui/Flex";
import styled from "styled-components";
import {EnumSettingsDef} from "../../consts/settingsDef";
import EditCountryCodePhone from "./EditCountryCodePhone";


interface IProps {
    setting: ISetting,
    onEdit: ({name, slug, value, id}: any, cb: any) => void,
    isNotEdit: boolean,
    onDelete: (id: number) => void
}
const StyledInput = styled(Input)`
width: 50%;
  @media(min-width: 992px){
    width: 100%;
  }
`
const TrItemSetting = ({setting, onEdit, isNotEdit, onDelete}: IProps) => {
    const [isEdit, setEdit] = useState(false);
    const [name, setName] = useState<string>(setting.name);
    const [slug, setSlug] = useState<string>(setting.slug);
    const [value, setValue] = useState<string>(setting.value);
    return (<>
            {isEdit ?
                <Table.TR key={`edit_tr_${setting.id}`}>
                    <Table.TD  key={`edit_td_id_${setting.id}`} title={'№'}>{setting.id}</Table.TD>
                    <Table.TD key={`edit_td_params_${setting.id}`}  title={'Параметр'}>
                        {/*{isNotEdit ? setting.name :*/}
                        <StyledInput key={`edit_input_params_${setting.id}`}  value={name} onChange={(e) => setName(e.target.value)}/>
                        {/*}*/}
                    </Table.TD>
                    <Table.TD  key={`edit_td_symbol_${setting.id}`}  title={'Символ'}>{isNotEdit ? setting.slug :
                        <StyledInput key={`edit_input_symbol_${setting.id}`}  value={slug} onChange={(e) => setSlug(e.target.value)}/>}</Table.TD>
                    <Table.TD key={`edit_td_value_${setting.id}`}  title={'Значение'}>
                        {EnumSettingsDef.FIXED_CODE_COUNTRY_PHONE === setting.slug ?
                            <EditCountryCodePhone key={`edit_code_country_${setting.id}`}  value={value}  setValue={(value)=> setValue(value)}/>
                            :
                        <StyledInput key={`edit_input_value_${setting.id}`}  value={value} onChange={(e) => setValue(e.target.value)}/>
                        }</Table.TD>
                    <Table.TD key={`edit_td_action_${setting.id}`}  title={'Действие'}>
                        <Flex align={'center'} key={`edit_flex_action_${setting.id}`} >
                            <IconUpdate title={'Сохранить'}
                                        key={`edit_action_save_${setting.id}`}
                                        onClick={() => onEdit({
                                            id: setting.id,
                                            name: name,
                                            value: value,
                                            slug: slug
                                        }, () => setEdit(false))}/>

                            <IconClose
                                key={`edit_action_close_${setting.id}`}
                                color={'red'}
                                style={{cursor: 'pointer'}}
                                onClick={() => setEdit(false)}
                                title={'Отменить'}/>
                        </Flex>
                    </Table.TD>
                </Table.TR>
                :
                <Table.TR key={`setting_tr_${setting.id}`} >
                    <Table.TD  key={`setting_td_id_${setting.id}`} title={'№'}>{setting.id}</Table.TD>
                    <Table.TD  key={`setting_td_params_${setting.id}`} title={'Параметр'}>{setting.name}</Table.TD>
                    <Table.TD  key={`setting_td_symbol_${setting.id}`} title={'Символ'}>{setting.slug}</Table.TD>
                    <Table.TD  key={`setting_td_title_${setting.id}`} title={'Значение'}>{setting.value}</Table.TD>
                    <Table.TD  key={`setting_td_action_${setting.id}`} title={'Действие'}>
                        <Flex  key={`setting_action_flex_${setting.id}`}  justify={'start'} align={'center'}>

                            <IconEdit  key={`setting_action_edit_${setting.id}`}  title={'Редактировать'} onClick={() => setEdit(true)}/>
                            {isNotEdit ? <></> :
                                <StyleDeleteIcon
                                    key={`setting_action_close_${setting.id}`}
                                    title={'Удалить'} onClick={() => onDelete(setting.id)}/>}
                        </Flex>

                    </Table.TD>
                </Table.TR>
            }
        </>
    );
};

export default TrItemSetting;