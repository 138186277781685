import React from 'react';
import styled from "styled-components";

interface IFlex {
    direction? : 'row' | 'column',
    align?: 'stretch' | 'center' | 'start' | 'end',
    justify?: 'start' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch' | 'end',
    margin?: string,
    wrap?: 'wrap' | 'no-wrap',
    children?: React.ReactNode
}
const StyledFlex = styled.div<IFlex>`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  align-items: ${props => props.align || 'stretch'};
  justify-content: ${props => props.justify || 'stretch'};
  margin: ${props => props.margin || '0'};
  flex-wrap: ${props => props.wrap || 'wrap'};
`

const Flex = (props:IFlex) => {
    return <StyledFlex  {...props}/>;
};

export default Flex;