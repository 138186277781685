import axiosApiInstance from "./settings";
import {IOrderStatus} from "../models/types";
import {ResponseType} from "./types/types";

interface IResponseData {
    'hydra:member': IOrderStatus[],
    'hydra:totalItems': number,
    page: number
}

export const OrderStatusHttp = {
    getAll: (page: number) => axiosApiInstance.get<IResponseData>(`/order-statuses?page=${page}&itemsPerPage=30`)
        .then(res => ({
            entry: res.data['hydra:member'],
            page: page,
            total: res.data['hydra:totalItems']
        })).catch(e => Promise.reject(e)),
    getById: (id: number) => axiosApiInstance.get<IOrderStatus>(`/order-statuses/${id}`)
        .then(res => res.data).catch(e => Promise.reject(e)),
    removeById: (id: number) => axiosApiInstance.delete<any>(`/order-statuses/${id}`).then(res => res).catch(e => Promise.reject(e)),
}