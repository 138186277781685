import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IMaterial, IMedia, IOrderAttribute, IPagination} from "../../models/types";
import {IModalDeleteMaterial} from "../../models/stateTypes";


interface ICommonSliceState {
    isLoading: boolean,
    isError: boolean,
    error: any | null,
    isChangeProjectState: boolean,
    togglePanelOrder: {
        isOpen: boolean,
        materialName: string,
        calculationTotal: number
    }
    materialModalSearch: {
        isLoading: boolean
        isOpen: boolean,
        pagination: {
            page: number,
            total: number
        },
        selectedMaterial: IMaterial[] | null,
        findListMaterial: IMaterial[] | []
    },
    modalDeleteMaterial: IModalDeleteMaterial,
    modalSettingCreate: {
        isOpen: boolean
    },
    modalCreateOrder: {
        isOpen: boolean
    },
    modalUploadMedia: IModalUploadMedia,
    modalImportDetailing: IModalImportDetailing

    // error: {title: string, status: string | number}
}

export interface IModalUploadMedia {
    isOpen: boolean,
    detailing: IOrderAttribute | null,
}
export interface IModalImportDetailing {
    isOpen: boolean,
    maxProductNumber: number
}
const CommonSlice = createSlice({
    name: 'common',
    initialState: {
        isLoading: false,
        isError: false,
        error: null,
        isChangeProjectState: false,
        togglePanelOrder: {
            isOpen: false,
            materialName: '',
            calculationTotal: 0
        },
        materialModalSearch: {
            isLoading: false,
            isOpen: false,
            pagination: {
                page: 1,
                total: 0
            },
            selectedMaterial: [],
            findListMaterial: []
        },
        modalDeleteMaterial: {
            isOpen: false,
            materialId: null
        },
        modalSettingCreate: {
            isOpen: false
        },
        modalCreateOrder: {
            isOpen: false
        },
        modalUploadMedia: {
            isOpen: false,
            detailing: null,
        },
        modalImportDetailing:{
            isOpen: false,
            maxProductNumber: 0
        }
    } as ICommonSliceState,
    reducers: {
        toggleLoader: (state: ICommonSliceState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        toggleError: (state: ICommonSliceState, action: PayloadAction<Pick<ICommonSliceState, 'isError' | 'error'>>) => {
            state.isError = action.payload.isError;
            state.error = action.payload.error

        },
        toggleChangeProjectState: (state, action: PayloadAction<boolean>) => {
            state.isChangeProjectState = action.payload;
        },
        togglePanelOrder: (state, action: PayloadAction<Pick<ICommonSliceState, 'togglePanelOrder'>>) => {
            state.togglePanelOrder.isOpen = action.payload.togglePanelOrder.isOpen;
            state.togglePanelOrder.materialName = action.payload.togglePanelOrder.materialName;
            state.togglePanelOrder.calculationTotal = action.payload.togglePanelOrder.calculationTotal;
        },

        toggleIsOpenModalMaterialSearch: (state, action: PayloadAction<boolean>) => {
            state.materialModalSearch.isOpen = action.payload;
        },
        addModalSelectMaterial: (state, action: PayloadAction<IMaterial>) => {
            if (state.materialModalSearch.selectedMaterial) {
                state.materialModalSearch.selectedMaterial.push(action.payload);
            } else {
                state.materialModalSearch.selectedMaterial = [action.payload];
            }
        },
        removeModalSelectMaterial: (state, action: PayloadAction<string>) => {
            state.materialModalSearch.selectedMaterial = state.materialModalSearch.selectedMaterial && state.materialModalSearch.selectedMaterial.filter(item => item['@id'] !== action.payload)
        },
        addedModalSelectFindMaterial: (state, action: PayloadAction<{ materials: IMaterial[] | [], pagination: IPagination }>) => {
            state.materialModalSearch.findListMaterial = action.payload.materials;
            state.materialModalSearch.pagination.page = action.payload.pagination.page;
            state.materialModalSearch.pagination.total = action.payload.pagination.total;
        },
        toggleIsLoadingSearch: (state, action: PayloadAction<boolean>) => {
            state.materialModalSearch.isLoading = action.payload;
        },
        resetSearchModal: (state) => {
            state.materialModalSearch.findListMaterial = [];
            state.materialModalSearch.pagination.page = 1;
            state.materialModalSearch.pagination.total = 0;
            state.materialModalSearch.selectedMaterial = [];
            state.materialModalSearch.isOpen = false;
            state.materialModalSearch.isLoading = false;
        },
        toggleModalDeleteMaterial: (state, action: PayloadAction<IModalDeleteMaterial>) => {
            state.modalDeleteMaterial.isOpen = action.payload.isOpen;
            state.modalDeleteMaterial.materialId = action.payload.materialId;
        },
        toggleModalCreateSetting: (state, action: PayloadAction<boolean>) => {
            state.modalSettingCreate.isOpen = action.payload;
        },
        toggleModalCreateOrder: (state, action: PayloadAction<boolean>) => {
            state.modalCreateOrder.isOpen = action.payload;
        },
        toggleModalUploadMedia: (state, action: PayloadAction<IModalUploadMedia>) => {
            state.modalUploadMedia.isOpen = action.payload.isOpen;
            state.modalUploadMedia.detailing = action.payload.detailing;
        },
        toggleModalImportDetailing: (state, action:PayloadAction<IModalImportDetailing>)=>{
            state.modalImportDetailing.isOpen = action.payload.isOpen;
            state.modalImportDetailing.maxProductNumber = action.payload.maxProductNumber;
        }

    },
    extraReducers: {}
});


export const {actions: CommonActions, reducer: commonReducer} = CommonSlice;
