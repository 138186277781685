import React, {useEffect} from 'react';
import Container from "../../components/ui/Container";
import {MaterialCard} from "../../components/Materials";
import Flex from "../../components/ui/Flex";
import {CalculationCard} from "../../components/Calculation";
import DetailingSection from "../../components/Detaling";
import Loader from "../../components/ui/Loader";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import AlertBox from "../../components/common/AlertBox";
import {fetchingProjectData} from "../../store/service/FetchingProjectData";
import {useParams} from "react-router-dom";
import Panel from "../../components/Detaling/Panel";
import PanelInfoOrder from "../../components/Materials/PanelInfoOrder";
import DeleteMaterialModal from "../../components/Modal/DeleteMaterialModal";
import {ProjectActions} from "../../store/slice/ProjectOrder/ProjectOrderSlice";
import UploadMediaModal from "../../components/Modal/lMedia/UploadMediaModal";
import ImportDetailingModal from "../../components/Modal/ImportDetailing/ImportDetailingModal";
import styled from "styled-components";

const StyleGridPanel = styled.div`
@media(min-width: 768px){
  display: grid;
  grid-template-columns: 45% 1fr;
  grid-gap: 15px;
  align-items: flex-start;
}
`
const OrderAcceptance = () => {
    const {isLoading, isError, error} = useAppSelector(state => state.common)
    const dispatch = useAppDispatch();
    const {id} = useParams();
    useEffect(() => {
        if (id) {
            dispatch(fetchingProjectData(Number(id)));
        }
        return () => {
            dispatch(ProjectActions.toggleChoiceMaterial({material: null}))
        }
    }, [id, dispatch])


    return (
        <Container key={'OrderAcceptanceContainer'} padding={'15px 15px'}>
            <PanelInfoOrder/>

            {isLoading && <Loader/>}
            <AlertBox fixedTop={true} key={'error-box'} isError={isError} error={error}/>
            <Panel>
                <StyleGridPanel>
                    <MaterialCard/>
                    <CalculationCard/>
                </StyleGridPanel>
            </Panel>
            <DetailingSection/>

            {/* modals */}

            <DeleteMaterialModal/>
            <UploadMediaModal/>
            <ImportDetailingModal/>
        </Container>
    );
};

export default OrderAcceptance;