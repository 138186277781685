import {ISetting} from "../models/types";
import {EnumPhoneCount} from "./phoneCountry";

export enum EnumSettingsDef {
    EDGE_TRIM = 'EDGE_TRIM',
    LINK_MOBILE_PORTAL = 'LINK_MOBILE_PORTAL',
    PROJECT_LOGO = 'PROJECT_LOGO',
    PRICE_SERVICE_CUTTING = 'PRICE_SERVICE_CUTTING',
    PRICE_SERVICE_EDGE = 'PRICE_SERVICE_EDGE',
    EDGE_WASTE_PERCENTAGE = 'EDGE_WASTE_PERCENTAGE',
    MINIMUM_EDGE_LENGTH = 'MINIMUM_EDGE_LENGTH',
    CURRENCY='CURRENCY',
    NAME_SERVICE= 'NAME_SERVICE',
    COST_CNC_OPERATION = 'COST_CNC_OPERATION',
    COST_PACKAGING = 'COST_PACKAGING',
    MIN_COST_DETAILING ='MIN_COST_DETAILING',
    FIXED_CODE_COUNTRY_PHONE ='FIXED_CODE_COUNTRY_PHONE',
}

export const getSettingValueForSlug = (slugFind: string, settings: ISetting[] | null) => {
    let find = settings && settings.find(({slug}) => slug === slugFind);
    return find ? find.value : ''
}

export const SettingsDef = [
    {
        slug: EnumSettingsDef.EDGE_TRIM,
        name: 'Длина остатка кромки по краям',
        value: '35'
    }, {
        slug: EnumSettingsDef.MINIMUM_EDGE_LENGTH,
        name: 'Минимальная длина кромкования',
        value: '120'
    }, {

        slug: EnumSettingsDef.EDGE_WASTE_PERCENTAGE,
        name: 'Процент отхода кромки',
        value: '10'
    }, {

        slug: EnumSettingsDef.PRICE_SERVICE_EDGE,
        // slug: 'production.price_service_edge',
        name: 'Стоимость услуги кромкования',
        value: '10'
    },
    {
        slug: EnumSettingsDef.PRICE_SERVICE_CUTTING,
        // slug: 'production.price_service_porezka',
        name: 'Стоимость услуги порезка',
        value: '10'
    },
    {
        slug: EnumSettingsDef.PROJECT_LOGO,
        name: 'Ссылка на логотип',
        value: 'https://api.ifurn.pro/modules/logo/1.svg'
    }, {
        slug: EnumSettingsDef.LINK_MOBILE_PORTAL,
        name: 'Ссылка на мобильный портал ifurn',
        value: 'https://q2.ifurn.pro/v/p/'
    },{
        slug: EnumSettingsDef.CURRENCY,
        name: 'Валюта',
        value: 'EUR'
    },{
        slug: EnumSettingsDef.NAME_SERVICE,
        name: 'Название сервиса',
        value: 'iFurn'
    },
    {
        slug: EnumSettingsDef.COST_CNC_OPERATION,
        name: 'стоимость ЧПУ операций за м2',
        value: '100'
    },{
        slug: EnumSettingsDef.COST_PACKAGING,
        name: 'Стоимость упаковки за м2',
        value: '100'
    },{
        slug: EnumSettingsDef.MIN_COST_DETAILING,
        name: 'Минимальная цена детали',
        value: '5000'
    },
    {
        slug: EnumSettingsDef.FIXED_CODE_COUNTRY_PHONE,
        name: 'Фиксированный код телефона',
        value: EnumPhoneCount.all
    },
]


