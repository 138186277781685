import React, {PropsWithChildren} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {CommonActions} from "../../store/slice/CommonSlice";
import {IconPaneClose} from "../ui/Icons";

const Panel = ({children}: PropsWithChildren) => {
    const {isOpen} = useAppSelector(state => state.common.togglePanelOrder)
    const {choiceMaterial} = useAppSelector(state => state.projectOrder);
    const dispatch = useAppDispatch();
    const onOpen = () => dispatch(CommonActions.togglePanelOrder({
        togglePanelOrder: {isOpen: true, calculationTotal: 0, materialName: choiceMaterial && choiceMaterial.name || ''}
    }))
    return (
        <div style={{position:'relative'}}>
            {!isOpen && children}

            {!isOpen && choiceMaterial &&  <IconPaneClose onClick={()=> onOpen()}/>}
        </div>
    );
};

export default Panel;