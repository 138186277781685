import React, {PropsWithChildren, useEffect, useMemo} from 'react';
import styled from "styled-components";
import Table from "../ui/Table";
import {IMaterial} from "../../models/types";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {ProjectActions} from "../../store/slice/ProjectOrder/ProjectOrderSlice";
import MaterialItem from "./MaterialItem";
import {IModalDeleteMaterial} from "../../models/stateTypes";
import {CommonActions} from "../../store/slice/CommonSlice";
import {calculationMaterial} from "../../helpers/calculation";
import {EnumSettingsDef, getSettingValueForSlug} from "../../consts/settingsDef";

const StyleMaterialList = styled.div`
  max-height: 250px;
  overflow-y: auto;
  height: 100%;
`


interface IProps {
    // materials: IMaterial[]

}

const MaterialTable = ({}: PropsWithChildren<IProps>) => {
    const {materials, choiceMaterial, order, settings} = useAppSelector(state => state.projectOrder);
    let currency = useMemo(() => {
        return getSettingValueForSlug(EnumSettingsDef.CURRENCY, settings.entry)
    }, [settings.entry]);

    const dispatch = useAppDispatch();
    const onChoiceMaterial = (material: IMaterial) => {

        if (material['@id'] !== (choiceMaterial ? choiceMaterial['@id'] : '')) {
            dispatch(ProjectActions.toggleChoiceMaterial({material: material}));
        } else {
            dispatch(ProjectActions.toggleChoiceMaterial({material: null}));
        }
    }
    const onToggleModal = (data: IModalDeleteMaterial) => {
        dispatch(CommonActions.toggleModalDeleteMaterial(data))
    }
    useEffect(() => {
        if (!choiceMaterial && materials && materials.length) {
            dispatch(ProjectActions.toggleChoiceMaterial({material: materials[0]}));
        }
    }, [materials])
    return (
        <StyleMaterialList>
            <Table key={'material_list_table'}>
                <Header key={'material_list_table_head'}/>
                <Table.Body key={'material_list_table_body'}>
                    {materials ? materials.map((material: IMaterial) => {
                        let parts = order ? order.orderAttributes : [];
                        console.log('material',calculationMaterial(material, parts, 'material', settings.entry).amount)
                        console.log('edge', calculationMaterial(material, parts, 'edge', settings.entry).amount)
                        console.log('cutting', calculationMaterial(material, parts, 'cutting', settings.entry).amount)
                        console.log('package', calculationMaterial(material, parts, 'package', settings.entry).amount)
                        console.log('edging', calculationMaterial(material, parts, 'edging', settings.entry).amount)
                        console.log('cnc',  calculationMaterial(material, parts, 'cnc', settings.entry).amount)
                        let amount =
                            Math.round(calculationMaterial(material, parts, 'material', settings.entry).amount +
                                calculationMaterial(material, parts, 'edge', settings.entry).amount +
                                calculationMaterial(material, parts, 'cutting', settings.entry).amount +
                                calculationMaterial(material, parts, 'package', settings.entry).amount +
                                calculationMaterial(material, parts, 'edging', settings.entry).amount +
                                calculationMaterial(material, parts, 'cnc', settings.entry).amount
                            )
                        return <MaterialItem
                            key={'material_item' + material.id}
                            amount={amount}
                            currency={currency}
                            materialId={material['@id']}
                            checked={Boolean(material['@id'] === (choiceMaterial ? choiceMaterial['@id'] : ''))}
                            onChoiceMaterial={() => onChoiceMaterial(material)} {...material}
                            deleteMaterial={() => onToggleModal({isOpen: true, materialId: material['@id']})}
                        />
                    }) : <Table.Empty title={'Список материалов пуст'} key={'material_list_table_empty'}></Table.Empty>}
                </Table.Body>

            </Table>
        </StyleMaterialList>
    );
};


const Header = () => {
    return <Table.Head isSticky={true} key={'material_list_head'}>
        <Table.TH background={'#d6d8db'}>№</Table.TH>
        <Table.TH background={'#d6d8db'}>Материал</Table.TH>
        <Table.TH background={'#d6d8db'}>Сумма</Table.TH>
        <Table.TH background={'#d6d8db'}>Действия</Table.TH>
    </Table.Head>
}
export default MaterialTable;