import React from 'react';
import {BASE_URL_IMG} from "../../../api/settings";
import {IconClose} from "../../ui/Icons";
import styled from "styled-components";
import Flex from "../../ui/Flex";
import {CiImageOn} from "react-icons/ci";
import {StyledTheme} from "../../../styled";

interface IProps {
    contentUrl: string,
    onDeleteMedia: () => void,
    id: number
}

const StyledItem = styled(Flex)`
  border-bottom: 1px solid #ccc;
  padding: 8px 5px;
  font-size: 14px;

`
const StyleLink = styled.a`

  max-width: 90%;
  //& > a{
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 8px;
  cursor: pointer;
  &:hover{
    color: ${props => props.theme.colors.theme};
  }
  //}
`
const MediaItem = ({contentUrl, onDeleteMedia, id}: IProps) => {

    return (
        <StyledItem align={'center'}>
            <CiImageOn color={StyledTheme.colors.theme} fontSize={18}/>
            <StyleLink href={`${BASE_URL_IMG}${contentUrl}`} className=""
                       target={'_blank'}>{contentUrl}  </StyleLink>

            <IconClose style={{marginLeft: "auto"}}
                       onClick={() => onDeleteMedia()}/>
        </StyledItem>
    );
};

export default MediaItem;