import React, {useMemo} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {Text} from "../ui/Typography";
import styled from "styled-components";
import Flex from "../ui/Flex";
import {IconPanelOpen} from "../ui/Icons";
import {CommonActions} from "../../store/slice/CommonSlice";
import {StyledTheme} from "../../styled";
import {calculateAdditional, calculationProject} from "../../helpers/calculation";
import {EnumSettingsDef, getSettingValueForSlug} from "../../consts/settingsDef";

const StyledWrapper = styled(Flex)`
  background: white;
  border: 1px solid #eee;
  position: relative;
  padding: 8px 45px 8px 8px;
`
const StyledBlock = styled(Flex)`
`
const Icon = styled(IconPanelOpen)`
  position: absolute;
  right: 0;
  top: 0;

`
const PanelInfoOrder = () => {
    const {isOpen, materialName} = useAppSelector(state => state.common.togglePanelOrder);
    const {materials, order, settings} = useAppSelector(state => state.projectOrder);
    let currency =  useMemo(()=>{return getSettingValueForSlug(EnumSettingsDef.CURRENCY, settings.entry)}, [settings.entry]);
    const dispatch = useAppDispatch();
    const calculateValueCache = useMemo(()=>{
        return materials && order ?  Number((
            calculationProject(materials, order.orderAttributes, 'material', settings.entry).amount +
            calculationProject(materials, order.orderAttributes, 'cutting', settings.entry).amount +
            calculationProject(materials, order.orderAttributes, 'edging', settings.entry).amount +
            calculationProject(materials, order.orderAttributes, 'edge', settings.entry).amount+
            calculationProject(materials, order.orderAttributes, 'cnc', settings.entry).amount+
            calculationProject(materials, order.orderAttributes, 'package', settings.entry).amount

        ).toFixed(2)) : 0
    }, [materials, order, settings]);
    const aditionalCost = useMemo(()=>{
        if (materials && order) {
            return calculateAdditional(calculateValueCache, order.orderAttributes, settings.entry, materials)
        }
        return 0
    }, [calculateValueCache, order, materials])
    const onClose = () => dispatch(CommonActions.togglePanelOrder({
        togglePanelOrder: {isOpen: false, calculationTotal: 0, materialName: ''}
    }))
    return (
        <>
            {isOpen ?
                <StyledWrapper justify={'space-between'} align={'center'}>
                    <Text key={''} margin={'0px 15px 0px 0px'}><strong>{materialName}</strong></Text>
                    <Text key={''}
                          margin={''}><strong>ИТОГО:</strong>
                        <strong
                            style={{marginLeft: ' 8px', color: `${StyledTheme.colors.navy_blue}`, fontWeight: 'bold'}}>
                            {Number((calculateValueCache + aditionalCost).toFixed(2)).toLocaleString()} {currency}
                            </strong>
                    </Text>
                    <Icon onClick={() => onClose()}/>
                </StyledWrapper>
                : <></>}
        </>
    );
};

export default PanelInfoOrder;