import {Dispatch} from "react";
import {AppDispatch} from "../index";
import {CommonActions} from "../slice/CommonSlice";
import {MediaHttp} from "../../api/MediaHttp";
import {ProjectActions} from "../slice/ProjectOrder/ProjectOrderSlice";
import {IMedia} from "../../models/types";
import {fetchingSaveProject} from "./FetchingProjectData";

interface IParamCreate {
    data: any,
    partId: number
}

export const fetchingCreateMedia = (data: any, productNumber: number) => async (dispatch: AppDispatch) => {
    dispatch(CommonActions.toggleIsLoadingSearch(true));
    try {
        const res = await MediaHttp.create(data);
       await dispatch(ProjectActions.addMediaDetailing({productNumber: productNumber, medias: res}));
        dispatch(fetchingSaveProject());

    } catch (e) {
        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
        dispatch(CommonActions.toggleIsLoadingSearch(false));
    }
}

export const fetchingDeleteMedia = (media: IMedia, productNumber: number) => async (dispatch: AppDispatch) => {
    dispatch(CommonActions.toggleIsLoadingSearch(true));
    try {
        await MediaHttp.removeById(media.id);
      await  dispatch(ProjectActions.deleteMediaDetailing({productNumber: productNumber, mediaId: media['@id'] || ''}));
        dispatch(fetchingSaveProject());


    } catch (e) {
        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
        dispatch(CommonActions.toggleIsLoadingSearch(false));
    }
}