import axios from "axios";
import {AuthHttp} from "./AuthHttp";
import {AuthStatusCode, NamingToken} from "../models/authTypes";
import {Simulate} from "react-dom/test-utils";
import {store} from "../store";
import {fetchingLogoutSystem, MemberActions} from "../store/slice/MembersSlice";

// let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxY2I4OWZiMjY3ZmVmYTAyYjg1NTRjZWRhOGJkM2U0OCIsImp0aSI6ImViNzJlNGRkZDk2ZjYxZGZlNjFmMTQxZTVlZDNhZjE0NWVmMzAxMzk5MDc0NmUzNmVlZGEwNTAwMWY4NWY0NThjZjZiYjU3ZGQyYzNiYzVlIiwiaWF0IjoxNjc4MzU4MTUzLjcxMDEzOSwibmJmIjoxNjc4MzU4MTUzLjcxMDE0NCwiZXhwIjoxNjc4MzYxNzUzLjY5MTAzMiwic3ViIjoiKzM4MDIzMDY2MjE0NCIsInNjb3BlcyI6WyJ1c2VyIl19.Ilf0l4d7ll22ps6dZUMnV3K3TgLkeZuQ2DrLUEUmpQh7GyeHqiTFSAJ6A636VrPQSwhkD7fAER01rmOFutawiEyfPiNGQNGmZ85ObTkWsJMlU9658XFdt6Yj5ZHAU1ldB0FvOxKbP6Mgi1CpvuvequOfvPaIKyjR3UZz3BGV5OMSzImqXGLEQGdkjGfRexdodhbSBitIhjN6bnu8aU6Rt4SlOBaCOZg5D2Cgxys2c_m7kuvvP3AF0p8wx5M5FiwCjAel9Yll6n3aAoedWI7nPA0i4qYvSyCqr6bVX73y4AZt6HOevnt1eth1r5UlzsnQgXkcpxgYVui2Cf8mBhCKbg'

const createBaseUrl = () => {
    if (window.location.hostname !== 'localhost') {
        return `${window.location.protocol}//api.${window.location.hostname}/api`
    }
    return 'https://api.simple-page-test.pp.ua/api'
}
const createBaseUrlImg = () => {
    if (window.location.hostname !== 'localhost') {
        return `${window.location.protocol}//api.${window.location.hostname}`
    }
    return 'https://api.simple-page-test.pp.ua'
}
export const BASE_URL: string = createBaseUrl();
export const BASE_URL_IMG: string = createBaseUrlImg();


//@params status code 401 =>
//@params status code 403 =>


const axiosApiInstance = axios.create({
    baseURL: BASE_URL,
    // withCredentials: true,
    headers: {
        'Accept': 'application/json, application/ld+json,  application/merge-patch+json',
        'Content-Type': 'application/x-www-form-urlencoded',
        // "Accept-Language": "ru"
    },

});

/**
 * Роуты, которые не требуют авторизацию.
 */
function noAuthRoute(uri: string) {
    return /^(regitration$)/.test(uri);
    // return /^(auth\/|password\/|register$)/.test(uri);
}


//@params expiry -  Server data expiry_in
//return current data + (expiry + (10min))

export const getExpiryDate = (expiry: number) => {
    let today = new Date();
    //expiry - 2 min
    let expiryCalc = expiry;
    // let expiryCalc = expiry - 120;
    let milliseconds = expiryCalc * 1000;
    // let milliseconds = expiry * 1000;
    let data = new Date(today.getTime() + milliseconds);
    return Date.parse(data.toString());
}
// export const getExpiryDate = (expiry: number) => Date.now() + (expiry);

//@ params expiry - data LS
//return true -  время истекло
export const compareIfExpirationDate = (expiry: number | null) => {
    if (expiry) return Date.now() >= expiry
    return true
}
// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
    async (config) => {
        // /**
        //  * Получаем текущее время и время устаревания токена
        //  */
        // let expTime = 3600 * 1000;
        // let curTime = new Date().getTime();
        //
        // /**
        //  * Сравниваем, если разница более 3 секунд - запускаем процедуру получения нового токена из refresh_token
        //  * Доработайте эту часть под свой код.
        //  */
        // if (expTime - curTime <= -3000) {
        //     await window.app.$store.dispatch("auth/refreshToken");
        // }

        let access_token = window.localStorage.getItem(NamingToken.ACCESS_TOKEN);
        if (access_token) {
            config.headers['authorization'] = 'Bearer ' + access_token;
        }


        return config;
    },

    error => {
        Promise.reject(
            error.response.data,
        )
    });
let isFetchingRefresh = false
const {dispatch} = store;
// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response) => {
    return response
}, async function (err) {
    // сохраняем данные запроса которые завершились 401 - для повтороного запроса после успешного обновления токена
    const originalConfig = err.config;


    if (err.response.status === AuthStatusCode.Unauthorized && err.config && !err.config._isRetry) {
        // предохранитель который запрещает зацыкленный вызов рефреш токена
        // указываем что запрос мы уже делали
        originalConfig._isRetry = true;
        // isFetchingRefresh = true;
        try {
            let res = await AuthHttp.refreshToken();
            window.localStorage.setItem(NamingToken.ACCESS_TOKEN, res.access_token);
            window.localStorage.setItem(NamingToken.REFRESH_TOKEN, res.refresh_token);
            window.localStorage.setItem(NamingToken.ACCESS_TOKEN_EXPIRES, `${getExpiryDate(res.expires_in)}`);
            // isFetchingRefresh = false;

            axiosApiInstance.defaults.headers['authorization'] = 'Bearer ' + res.access_token;
            // }


        } catch (e) {
            // console.log('Пользователь не авторизован e', e)
            // console.log('Пользователь не авторизован catch refresh token', err.response.status);
            if (err.response.status === AuthStatusCode.Unauthorized) {
                dispatch(fetchingLogoutSystem());
            }
        }

        originalConfig._isRetry= false;
        return axiosApiInstance.request(originalConfig);
    }


    if (err.response.status === AuthStatusCode.Forbidden && err.response.data) {
        return  Promise.reject(err.response)
    }

    return Promise.reject(
        err.response.data,
    );


});


export default axiosApiInstance;

