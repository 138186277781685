import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import styled from "styled-components";
import Card from "../../components/ui/Card";
import Table from "../../components/ui/Table";
import PaginationContainer from "../../components/ui/Pagination/PaginationContainer";
import Container from "../../components/ui/Container";
import {
    fetchingSettingsCreate,
    fetchingSettingsDelete,
    fetchingSettingsEdit,
    fetchingSettingsProject
} from "../../store/service/FetchingProjectData";
import Loader from "../../components/ui/Loader";
import AlertBoxError from "../../components/common/AlertBox";
import {ISetting} from "../../models/types";
import {SettingsDef} from "../../consts/settingsDef";
import TrItemSetting from "../../components/Settings/TrItemSetting";
import {StyledTheme} from "../../styled";
import CreateSettingModal from "../../components/Modal/Setting/CreateSettingModal";
import {CommonActions} from "../../store/slice/CommonSlice";
import Button from "../../components/ui/Button";
import Flex from "../../components/ui/Flex";

//::TODO Private Page access only role role_admin
const StyleCard = styled(Card)`
  overflow-y: auto;
  @media (min-width: 1024px) {
    overflow-y: auto;

  }
`
const StyleTable = styled(Table)`

`
const SettingsPage = () => {

    const {entry, pagination} = useAppSelector(state => state.projectOrder.settings);
    const {isLoading, isError, error, modalSettingCreate} = useAppSelector(state => state.common);
    const dispatch = useAppDispatch();
    const onPageFetchingData = (page: number) => dispatch(fetchingSettingsProject(page));

    useEffect(() => {
        onPageFetchingData(1);
    }, []);

    const isFindDefSlug = (slug: string) => {
        return !!SettingsDef.find(item => slug === item['slug'])
    }

    const onEdit = ({value, name, slug, id}: any, cb: any) => {
        dispatch(fetchingSettingsEdit({value, slug, id, name}, () => cb()));
    }
    const onDelete = (id: number) => dispatch(fetchingSettingsDelete(id));
    const onCreate = (setting: Pick<ISetting, 'slug' | 'name' | 'value'>) => dispatch(fetchingSettingsCreate(setting))

    const onToggleCreateModal = (isOpen: boolean) => dispatch(CommonActions.toggleModalCreateSetting(isOpen))
    return (
        <Container key={'settings_page'} padding={'15px 15px'}>
            {isLoading && <Loader key={'settings_loader'}/>}
            {<AlertBoxError key={'settings_error'} fixedTop={true} error={error} isError={isError}/>}
            <Flex justify={'end'} key={'settings_page_panel'}>
                <Button key={'settings_page_panel_create'} onClick={() => (onToggleCreateModal(true))} variant={'theme'} margin={'15px 0px'}>Создать</Button>

            </Flex>

            <StyleCard key={'settings_page_body'}>
                <div className="table-response" key={'settings_page_body_response'}>
                    <StyleTable key={'settings_page_table'}>
                        <Table.Head key={'settings_page_head'} background={StyledTheme.table_bg.secondary}>
                            <Table.TH key={'settings_page_th_count'} background={StyledTheme.table_bg.secondary}>№</Table.TH>
                            <Table.TH key={'settings_page_th_params'} background={StyledTheme.table_bg.secondary}>Параметр</Table.TH>
                            <Table.TH key={'settings_page_th_symbol'} background={StyledTheme.table_bg.secondary}>Символ</Table.TH>
                            <Table.TH key={'settings_page_th_value'} background={StyledTheme.table_bg.secondary}>Значение</Table.TH>
                            <Table.TH key={'settings_page_th_action'} background={StyledTheme.table_bg.secondary}>Действие</Table.TH>
                        </Table.Head>
                        <Table.Body>
                            {entry && Array.isArray(entry) && entry.length ?
                                entry.map((setting) => {
                                    return <TrItemSetting key={`setting_item_tr_${setting.id}`} setting={setting} onEdit={onEdit}
                                                          isNotEdit={isFindDefSlug(setting.slug)}
                                                          onDelete={onDelete}
                                    />
                                })
                                : <Table.Empty/>}
                        </Table.Body>
                    </StyleTable>

                </div>
                <PaginationContainer key={'settings_page_pagination'} page={pagination.page} setPage={() => {
                }} total={pagination.total} limit={30}/>

            </StyleCard>

            <CreateSettingModal key={'settings_page_modal_create'}  isOpen={modalSettingCreate.isOpen} onClose={() => (onToggleCreateModal(false))}
                                onSubmit={onCreate}/>
        </Container>
    );
};

export default SettingsPage;