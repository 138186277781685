import React, {useState} from 'react';
import styled from "styled-components";
import {IconEye, IconEyeOff} from "../Icons";

type IStyleInput = {
    readonly borderRadius?: string,
    readonly borderColor?: string,
    readonly width?: string,
    minWidth?: string,
    padding?: string
}

interface IInputProps extends IStyleInput {
    value?: string | number,
    placeholder?: string,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
    type?: 'text' | 'number' | 'email',
    name?: string,
    ref?: React.RefObject<HTMLInputElement>,
    required?:boolean
}

interface IInputNumberProps extends IInputProps {
    min?: number,
    max?: number,
    step?: number
}

const StyleInput = styled.input<IStyleInput>`
  border: 1px solid ${props => props.borderColor || props.theme.colors.grey};
  padding:${props => props.padding || '5px 5px'} ;
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.colors.navy_blue};
  display: block;
  min-width: ${({minWidth}) => minWidth || 'auto'};
  width: ${({width}) => width || 'auto'};
  border-radius: ${({borderRadius}) => borderRadius || '0px'};

  &:focus {
    outline: none;
    border-color:  ${props => props.theme.colors.theme} ;
  }
`

const Input = (props:IInputProps ) => {
    return <StyleInput {...props}/>;
};

const StyleInputNumber = styled(StyleInput)`
`

const InputNumber = (props: IInputNumberProps) => {
    return <StyleInputNumber {...props}/>;
};

const StyleInputPassword = styled.input`
  border: 1px solid ${props => props.theme.colors.grey};
  padding: ${'8px 15px'};
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.colors.navy_blue};
  display: block;
  min-width: auto;
  width: auto;
  border-radius: 0px;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.theme};
  }
`
const StyledPasswordWrap= styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > input{
    flex: 1 1 auto;
    padding-right: 35px;
    //margin-right: 8px;
  }
  &> svg{
    position: absolute;
    right: 10px;
  }
`
export const InputPassword = (props:any)=>{
    const [isSee, setIsSee] = useState<boolean>(false);
    return <StyledPasswordWrap>

        <StyleInputPassword type={isSee ? 'text' :'password'} {...props}/>
        {isSee ? <IconEyeOff onClick={()=>{setIsSee(false)}}/>  :<IconEye onClick={()=>{setIsSee(true)}}/>}
    </StyledPasswordWrap>
}
export {Input, InputNumber};