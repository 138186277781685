import React, {useEffect, useMemo} from 'react';
import FormLogin from "../../components/Form/FormLogin";
import {ILogin, NamingToken} from "../../models/authTypes";
import Container from "../../components/ui/Container";
import Card from "../../components/ui/Card";
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {fetchingLogin, fetchingRegistrationCredential} from "../../store/service/FetchingAuth";
import {Title} from "../../components/ui/Typography";
import Flex from "../../components/ui/Flex";
import {ROUTES} from "../../consts/routs";
import {Link, Navigate, useNavigate} from "react-router-dom";
import {AiOutlineArrowRight} from "react-icons/ai";
import Loader from "../../components/ui/Loader";
import AlertBox from "../../components/common/AlertBox";
import {fetchingCheckAuthenticationSystem} from "../../store/slice/MembersSlice";
import {compareIfExpirationDate} from "../../api/settings";
import {EnumSettingsDef, getSettingValueForSlug} from "../../consts/settingsDef";
import {fetchingSettingsNotAuthorized, fetchingSettingsProject} from "../../store/service/FetchingProjectData";

const StyledCard = styled(Card)
    ` position: relative;
      box-shadow: 0 0 5px #ccc;
      width: 100%;
      @media (min-width: 576px) {
        max-width: 560px;

      }
    `
const StyledWrapFlex = styled(Flex)`
  align-items: center;
  min-height: calc(100vh - 50px);
  margin: 25px auto;
  justify-content: center;
  flex-direction: column;
`
 const StyledLink = styled(Link)`
 display: flex;
  align-items: flex-end;
  justify-content: center;
  text-decoration: none;
  & > svg{
    margin-left: 8px;
    transform: translateX(0px);
    transition: transform 0.9ms;
  }
  &:hover{
    color: ${props => props.theme.colors.theme};
    
  }
  &:hover svg{
    transform: translateX(10px);
    fill:   ${props => props.theme.colors.theme};;
  }
`
const LoginPage = () => {
    const dispatch = useAppDispatch();
    const {isError, isLoading, error} = useAppSelector(state => state.common);
    const {settings} = useAppSelector(state => state.projectOrder);
    const {member} = useAppSelector(state => state.members);
    const navigate = useNavigate();
    const settingsCodeCountry = useMemo(()=>{
        return getSettingValueForSlug(EnumSettingsDef.FIXED_CODE_COUNTRY_PHONE, settings.entry)
    }, [settings.entry])
    const onSubmit = (data: ILogin) => {
        dispatch(fetchingLogin(data, ()=>{
            navigate('/');
        }));
    }
    useEffect(()=>{
        if(localStorage.getItem(NamingToken.REFRESH_TOKEN) && localStorage.getItem(NamingToken.ACCESS_TOKEN)) {
            dispatch(fetchingCheckAuthenticationSystem());
        }

    }, [member]);
    useEffect(() => {
        if (compareIfExpirationDate(Number(localStorage.getItem(NamingToken.CLIENT_TOKEN_EXPIRES)) || null)) {
            dispatch(fetchingRegistrationCredential());
        }
       if(!compareIfExpirationDate(Number(localStorage.getItem(NamingToken.CLIENT_TOKEN_EXPIRES)) || null)){
           dispatch(fetchingSettingsNotAuthorized(1));
       }
    }, []);
    if(member.current) return <Navigate to={ROUTES.orders}/>
    return (
        <div>
            <Container>
                <AlertBox fixedTop={true} key={'error-box'} isError={isError} error={error}/>
                <StyledWrapFlex>
                    <StyledCard>
                        {isLoading && <Loader isFixedParent={true} key={'loader'}/>}
                        <Flex justify={'center'}>
                            <Title level={2}>Авторизация</Title>
                        </Flex>
                        <FormLogin onHandlerSubmit={onSubmit} settingsCodeCountry={settingsCodeCountry}/>
                    </StyledCard>

                    <Flex justify={'center'} margin={'25px 0px 0px 0px'}>
                        <StyledLink to={ROUTES.registration}>Регистрация <AiOutlineArrowRight/></StyledLink>
                    </Flex>
                </StyledWrapFlex>
            </Container>

        </div>
    );
};

export default LoginPage;