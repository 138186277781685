import styled, {css} from 'styled-components';
import {TypeBody, TypeHead, TypeTable, TypeTd, TypeTh, TypeTr} from "./models";


export const StyledTable = styled.table<TypeTable>`
  width: ${props => props.width || '100%'};

`;

export const THead = styled.thead<TypeHead>`
    //background-color: ${props => props.background || 'transparent'};
  ${props => props.isSticky && css`
    & > th {
      position: sticky;
      z-index: 6;
      top: 0
    }
  `}
`;

export const TFoot = styled.tfoot`
`;

export const TBody = styled.tbody<TypeBody>`
  & > tr:nth-child(even) {
    background-color: ${props => props.background || 'transparent'};
  }
`;

export const TR = styled.tr<TypeTr>`
  ${({isBgChild, background, color}) => isBgChild ? css`
            & > th,
            & > td {
              background-color: ${background || ''} !important;
              color: ${color || ''} !important;
            }
          `
          : ''}
`;

export const TH = styled.th<TypeTh>`
  font-weight: ${props => props.fontWeight || '500'};
  background-color: ${props => props.background || 'transparent'};
  color: ${props => props.color || props.theme.colors.black};
`;

export const TD = styled.td<TypeTd>`
  font-weight: ${props => props.fontWeight || '500'};
  background-color: ${props => props.background || 'transparent'};
  background-color: ${props => props.background || 'transparent'};

  &:hover {
    background-color: transparent;
  }
`;