import React from 'react';
import styled, {keyframes} from 'styled-components'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }

`

const CircleLoader = styled.div`
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid ${props => props.theme.colors.theme};
  width: 120px;
  height: 120px;
  animation: ${spin} 1s linear infinite;
`
const fixed = `
    position: fixed;
    width: 100vw;
    height: 100vh;
`
const fixedParent = `
 position: absolute;
`
const WrapperLoader = styled.div<IWrapper>`
  ${props => props.isFixedParent ? fixedParent : fixed}
  
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  z-index: 9999;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

interface IWrapper {
    isFixedParent?: boolean
}

const Loader = ({isFixedParent = false}: IWrapper) => {
    return (<WrapperLoader isFixedParent={isFixedParent}>
            <CircleLoader/>
        </WrapperLoader>

    );
};

export default Loader;