import React, {useState} from 'react';
import {FileUpload} from "../ui/Field";
import Flex from "../ui/Flex";
import Button from "../ui/Button";
interface IProps{
    sendFiles: (data:any)=> void
}
const UploadForm = ({sendFiles}:IProps) => {
    const [uploadFile, setIsUploadFiles] = useState<any>(null);
    const onChangeUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {files} = e.target;
        const selectedFiles = files as FileList;
        setIsUploadFiles(selectedFiles);
    }
    return (
        <div>
            <FileUpload htmlFor={'media_upload'} id={'media_upload'} onChange={onChangeUpload} multiple={false}
                        title={!Boolean(uploadFile) ? 'Выберите файл(ы)' : `Загружено ${uploadFile.length} файл(ов)`}
                        required={true}/>
            <Flex margin={'25px 0px 0px 0px'} justify={'center'}>
                <Button disabled={!Boolean(uploadFile)} variant={'theme'} onClick={()=> sendFiles(uploadFile)}
                        padding={'10px 15px'}>Загрузить</Button>
            </Flex>
        </div>
    );
};

export default UploadForm;