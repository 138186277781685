import React, {PropsWithChildren, useEffect, useRef, useState} from 'react';
import useDebounce from "../../../hooks/useDebounce";
import {fetchingMaterialAddProject} from "../../../store/service/FetchingMaterial";
import {Input} from "./Input";
import styled, {css} from "styled-components";
import {IconClose} from "../Icons";
import {ifError} from "assert";
import {useOnClickOutside} from "../../../hooks/useOutsideClick";

interface IProps {
    onFetchingData: (term: string) => void,
    options: any,
    onHandlerClick: (item: any) => void,
    optionTitle: string,
    optionValue: string,
    value: string,
    onReasetField: ()=> void,
    term: string,
    setTerm: (term: string)=> void,
    inputPadding?:string
}

const StyleInput = styled.input`
  border: 1px solid ${props => props.theme.colors.grey};
  padding: ${props => '5px 15px 5px 5px'};
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.colors.navy_blue};
  display: block;
  min-width: 100%;
  width: 100%;
  border-radius: 0px;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.theme};
  }
`
const WrapperInput = styled.div`
  position: relative;
`
const IconRemoveTerm = styled(IconClose)<{ isVisible: boolean }>`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  ${props => props.isVisible ? '  visibility: visible' : '  visibility: hidden'};

`
const OptionsContainer = styled.div<{ isOverflowAllSide?: boolean }>`
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #CDCCD7;
  border-top: 0;
  background: #fff;
  transition: all 0.5s;
  max-height: 250px;
  ${props => props.isOverflowAllSide ? 'overflow: hidden' : 'overflow-y: auto'};
  padding: 0px 0px;

  z-index: 2;
  opacity: 1;
  visibility: visible;
  pointer-events: all;

  & > div {

    border-bottom: 1px solid #CDCCD7;
    font-size: 14px;
    padding: 8px 10px;
    cursor: pointer;

    &:last-child {
      border-bottom: 0px;
    }
  }

  & > div.selected {
    color: white;
    background: ${props => props.theme.colors.theme};
  }
`
const AutocompleteContainer = styled.div<{inputPadding?:string}>`
  position: relative;
  user-select: none;
  width: 100%;
  input{
    ${props => props.inputPadding && css`
      padding: ${props.inputPadding} !important;
    `}
  }
  
`
const Autocomplete = ({onFetchingData, options, onHandlerClick, optionTitle, optionValue , value, onReasetField, term, setTerm, inputPadding}: PropsWithChildren & IProps) => {
    // const [term, setTerm] = useState<string>('')
    const debounceTerm = useDebounce(term, 250)
    useEffect(
        () => {
            if (debounceTerm) {
                onFetchingData(term);

            } else {
            }
        },
        [debounceTerm] // Only call effect if debounced search term changes
    );
    const [isShow, setIsShow] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null)
    useOnClickOutside(ref, () => {
        setIsShow(false)
    });

    return (
        <AutocompleteContainer ref={ref} key={'autocomplete'} inputPadding={inputPadding} >
            <WrapperInput key={'autocomplete-wrapper-input'}>
                <StyleInput
                    key={'autocomplete-iput'}
                    onFocus={()=> setIsShow(true)}
                    type="text"
                    autoComplete={term}
                    placeholder={'...'}
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}/>
                <IconRemoveTerm isVisible={Boolean(term.length)} onClick={() => {
                    setTerm(' ');
                    onReasetField();
                }
                }/>
            </WrapperInput>
            {isShow &&  <OptionsContainer key={'autocomplete-options'}>
                {options.length  ? options.map((item: any) => {
                    return <div key={'autocomplete-option'}
                        className={`${item[optionValue] === value ? 'selected' : ''}`}
                        onClick={() => {onHandlerClick(item);
                        setTerm(item[optionTitle]);
                        setIsShow(false);
                        }
                        }>{item[optionTitle]}</div>
                }) : <div key={'autocomplete-option-empty'} style={{textAlign:'center'}}>No options</div> }
            </OptionsContainer>}
        </AutocompleteContainer>
    );
};

export default Autocomplete;