import axiosApiInstance, {BASE_URL} from "./settings";
import {IOrders, ISetting} from "../models/types";
import axios from "axios";
import {NamingToken} from "../models/authTypes";

interface ResponseSetting {
    'hydra:member': ISetting[],
    "hydra:totalItems": number
}

export const SettingsHttp = {
    getNotAuthorized: (page: number) => axios.get<ResponseSetting>(`${BASE_URL}/settings?page=${page}&itemsPerPage=30`, {
        headers:{
            'Authorization' : `Bearer ${localStorage.getItem(NamingToken.CLIENT_TOKEN)}`
        }
    })
        .then((res) => {
            return {
                pagination: {
                    page: page,
                    total: res.data["hydra:totalItems"],
                },
                settings: res.data["hydra:member"],
            }
        })
        .catch((error) => {
            return Promise.reject(error)
        }),
    getAll: (page: number) => axiosApiInstance.get<ResponseSetting>(`/settings?page=${page}&itemsPerPage=30`)
        .then((res) => {
            return {
                pagination: {
                    page: page,
                    total: res.data["hydra:totalItems"],
                },
                settings: res.data["hydra:member"],
            }
        })
        .catch((error) => {
            return Promise.reject(error)
        }),
    getById: (id: number) => axiosApiInstance.get<ISetting>(`/settings/${id}`)
        .then((res) => {
            return res.data
        })
        .catch((error) => Promise.reject(error)),
    create: (setting: Pick<ISetting, 'slug' | 'name'| 'value'>) => axiosApiInstance.post<ISetting>(`/settings`, setting,
        {
            headers: {
                'Content-Type': 'application/ld+json',
            }
        })
        .then((res) => {
            return res.data
        })
        .catch((error) => Promise.reject(error)),
    updateById: (id: number, order: any) => axiosApiInstance.patch<ISetting>(`/settings/${id}`, order,
        {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            }
        }
    ).then(res => res.data).catch(e => Promise.reject(e)),
    deleteById: (id: number) => axiosApiInstance.delete(`/settings/${id}`).then(res => res.data).catch(e => Promise.reject(e))
}


