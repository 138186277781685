import React, {FormEvent, useMemo, useState} from 'react';
import {ILogin} from "../../models/authTypes";

import PhoneInput, {CountryData} from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {Field, Input, Label, RequiredFiled} from "../ui/Field";
import Button from "../ui/Button";
import {InputPassword} from "../ui/Field/Input";
import styled from "styled-components";
import Flex from "../ui/Flex";
import {Text} from "../ui/Typography";
import InputCheckbox from "../ui/Field/InputCheckbox";
import {EnumPhoneCount, PHONE_COUNTRY} from "../../consts/phoneCountry";

interface IProps {
    onHandlerSubmit: (data: ILogin) => void,
    settingsCodeCountry: string
}

const StyledInput = styled(Input)`
  padding: 8px 15px;
`
const StyleForgotPassword = styled.div`
  color: ${props => props.theme.colors.theme};
  cursor: pointer;
`

enum TypeAuth {
    phone = 0,
    email = 1
}

const FormLogin = ({onHandlerSubmit, settingsCodeCountry}: IProps) => {
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [isTypeAuth, setIsTypeAuth] = useState<number>(TypeAuth.phone);
    const [password, setPassword] = useState<string>('');
    const [isForgot, setIsForgot] = useState<boolean>(false);
    const isDisableDropdown = useMemo(()=>{
        if(!settingsCodeCountry){
            return false;
        }
        if(settingsCodeCountry && settingsCodeCountry === EnumPhoneCount.all){
            return  false
        }
        if(settingsCodeCountry && settingsCodeCountry !== EnumPhoneCount.all){
            return  true
        }
    },[settingsCodeCountry])
    return (
        <form onSubmit={(e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            onHandlerSubmit({
                username:
                    !isTypeAuth ? `+${phone}` : email,
                password,
            });
        }

        }>
            <Field direction={'row'} wrap={'wrap'} align={'center'} justify={'center'}>
                <Flex direction={'row'} align={'center'} margin={'0px 25px 0px 0px'}>
                    <Label margin={'0px     8px 0px 0px'}>Телефон</Label>
                    <InputCheckbox id={'phone'} htmlFor={'phone'} checked={TypeAuth.phone === isTypeAuth}
                                   variant={'radio'} onChange={() => (setIsTypeAuth(TypeAuth.phone))}/>
                </Flex>
                <Flex direction={'row'} align={'center'}>
                    <Label  margin={'0px 8px 0px 0px'}>Email</Label>
                    <InputCheckbox id={'email'} htmlFor={'email'} checked={TypeAuth.email === isTypeAuth}
                                   variant={'radio'} onChange={() => (setIsTypeAuth(TypeAuth.email))}/>
                </Flex>

            </Field>
            {isTypeAuth ?
                <Field direction={'column'}>
                    <Label>Email <RequiredFiled>*</RequiredFiled></Label>
                    <StyledInput type={'email'} value={email} onChange={(e) => (setEmail(e.target.value))}/>
                </Field>
                :
                <Field direction={'column'}>
                    <Label>Телефон <RequiredFiled>*</RequiredFiled></Label>
                    <PhoneInput key={'phone'} value={phone}
                                country={ !!settingsCodeCountry  ?  settingsCodeCountry === EnumPhoneCount.all ? 'ua' : settingsCodeCountry : 'ua'}
                                inputProps={{
                                    require: true
                                }}
                                disableDropdown={isDisableDropdown}
                                onlyCountries={ [...PHONE_COUNTRY]}
                                onChange={(value, data: CountryData, _, formatter) => {
                                    setPhone(value);
                                }}/>
                </Field>
            }
            <Field direction={'column'}>
                <Label>Пароль <RequiredFiled>*</RequiredFiled></Label>
                <InputPassword required={true} value={password} onChange={(e: any) => (setPassword(e.target.value))}
                    // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    // title={'Должен содержать не менее одной цифры и одной прописной и строчной буквы, а также не менее 8 символов.'}
                />
            </Field>
            <Field direction={'column'}>
                <StyleForgotPassword onClick={() => setIsForgot(true)}>Забыли пароль?</StyleForgotPassword>
                {isForgot && <Text>Обратитесь к администратору!</Text>}
            </Field>

            <Flex margin={'25px 0px 0px 0px'} justify={'center'}>
                <Button variant={'theme'}
                        disabled={Boolean(!password && (isTypeAuth ? !email : !phone))}>Отправить</Button>
                {/*<Button variant={'theme'} disabled={Boolean( !password || (!code && !phone))}>Отправить</Button>*/}
            </Flex>
        </form>
    );
};

export default FormLogin;