import {AppDispatch} from "../index";
import {CommonActions} from "../slice/CommonSlice";
import {MaterialHttp} from "../../api/MaterialHttp";
import {IMaterial, IPagination} from "../../models/types";
import {ProjectActions} from "../slice/ProjectOrder/ProjectOrderSlice";

export const fetchingMaterialAddProject = (search: string, page: number) => async (dispatch: AppDispatch) => {
    dispatch(CommonActions.toggleIsLoadingSearch(true));
    try {
        const findMaterials = await MaterialHttp.getAll({page, search});
        dispatch(CommonActions.addedModalSelectFindMaterial({
            materials: findMaterials.materials,
            pagination: findMaterials.pagination
        }))
        dispatch(CommonActions.toggleIsLoadingSearch(false));
    } catch (e) {
        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
        dispatch(CommonActions.toggleIsLoadingSearch(false));
    }
}

export const fetchingMaterialDeleteProject = (material: string) => async (dispatch: AppDispatch) => {
    dispatch(CommonActions.toggleIsLoadingSearch(true));
    try {

        dispatch(ProjectActions.deleteMaterialAndDetailingInProject(material))

        dispatch(CommonActions.toggleIsLoadingSearch(false));
    } catch (e) {
        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
        dispatch(CommonActions.toggleIsLoadingSearch(false));
    }
}
