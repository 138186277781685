export  enum  EnumPhoneCount{
    ua = 'ua' ,
    pl ='pl',
    ro ='ro',
    hu ='hu',
    il ='il',
    ru='ru',
    bg='bg',
    md = 'md',
    all ='По умолчанию'
}
export type AliasCountry = 'ua'| 'pl'|'ro' | 'hu'| 'il'|'ru'| 'bg'| 'md'
export const PHONE_COUNTRY:Array<AliasCountry> = ['ua', 'pl', 'ro', 'hu', 'il', 'ru', 'bg', 'md']
