import {EnumSettingsDef} from "../consts/settingsDef";
import {IMaterial, IOrderAttribute, ISetting} from "../models/types";


//@params material =Материал
//@params cutting =Порезка
//@params edging =Кромкование
//@params edge =кромка
//@params cnc =ЧПУ операций за м2
//@params package =Стоимость упаковки за м2
type TCaseCalculation = 'material' | 'cutting' | 'edging' | 'edge' | 'cnc' | 'package';
type TSlug =
    EnumSettingsDef.EDGE_TRIM
    | EnumSettingsDef.MINIMUM_EDGE_LENGTH
    | EnumSettingsDef.EDGE_WASTE_PERCENTAGE
    | EnumSettingsDef.PRICE_SERVICE_EDGE
    | EnumSettingsDef.PRICE_SERVICE_CUTTING
    | EnumSettingsDef.COST_CNC_OPERATION
    | EnumSettingsDef.COST_PACKAGING
    | EnumSettingsDef.MIN_COST_DETAILING

const getValueSettings = (slug: TSlug, settings: ISetting[] | null) => {
    if (settings) {
        let findItem = settings.find(item => item['slug'] === slug);
        if (findItem) return findItem.value.trim()
        return 0
    }
    return 0
}
export const getMM = (value: number) => {
    return value / 1000
}
export const calculationProject = (materials: IMaterial[], orderAttributes: IOrderAttribute[], type: TCaseCalculation, settings: ISetting[] | null) => {

    switch (type) {
        case "material": {
            const calculate = orderAttributes && orderAttributes.reduce((accumulator: number, part: IOrderAttribute) => {
                let value = (Number(part.x) * Number(part.y)) * Number(part.quantity);

                return (accumulator + value )/ 1000000;
            }, 0);
            const amount = orderAttributes && orderAttributes.reduce((accumulator: number, part: IOrderAttribute) => {
                let material = materials.find(item => item['@id'] === part.material);
                if (material) {
                    let value = (Number(part.x) * Number(part.y)) * Number(part.quantity);
                    // let value = (Number(part.x) + Number(part.y)) * Number(part.quantity);
                    return accumulator + ((value/ 1000000) * Number(material.price));
                    // return accumulator + (getMM(value) * Number(material.price));
                } else {
                    // console.log('not found material', part.material);
                    return accumulator
                }
            }, 0);

            return {
                calculate: Number((calculate).toFixed(2)), amount: Number((amount).toFixed(2))
            }
        }
        case "cutting": {
            const calculate = orderAttributes && orderAttributes.reduce((accumulator: number, part: IOrderAttribute) => {
                let value = (Number(part.x) + Number(part.y)) * 2;
                return accumulator + getMM(value);
            }, 0);
            const amount = orderAttributes && orderAttributes.reduce((accumulator: number, part: IOrderAttribute) => {
                const findPriceCutting = getValueSettings(EnumSettingsDef.PRICE_SERVICE_CUTTING, settings)
                let value = ((Number(part.x) + Number(part.y)) * 2) * Number(part.quantity);
                return accumulator + (getMM(value) * Number(findPriceCutting));
            }, 0);
            return {
                calculate: Number((calculate).toFixed(2)), amount: Number((amount).toFixed(2))

            }
        }
        case "edging": {
            const calculate = orderAttributes && orderAttributes.reduce((accumulator: number, part: IOrderAttribute) => {
                let material = materials.find(item => item['@id'] === part.material);
                let side = {
                    top: part.edgeTop ? Number(part.x) : 0,
                    bottom: part.edgeBottom ? Number(part.x) : 0,
                    left: part.edgeLeft ? Number(part.y) : 0,
                    right: part.edgeRight ? Number(part.y) : 0,
                }
                let value = ((side.top + side.bottom + side.left + side.right) * part.quantity);
                return accumulator + getMM(value);
            }, 0);
            const amount = orderAttributes && orderAttributes.reduce((accumulator: number, part: IOrderAttribute) => {
                //@param  стоимость услуги кромкование
                const findPriceEdge = getValueSettings(EnumSettingsDef.PRICE_SERVICE_EDGE, settings);
                // settings ? settings.find(setting => setting.slug === 'PRICE_SERVICE_EDGE') : 0;

                let side = {
                    top: part.edgeTop ? Number(part.x) : 0,
                    bottom: part.edgeBottom ? Number(part.x) : 0,
                    left: part.edgeLeft ? Number(part.y) : 0,
                    right: part.edgeRight ? Number(part.y) : 0,
                }
                let value = (side.top + side.bottom + side.left + side.right) * part.quantity;
                return accumulator + (getMM(value) * Number(findPriceEdge));

            }, 0);
            return {
                calculate: Number((calculate).toFixed(2)), amount: Number((amount).toFixed(2))

            }


        }
        case "edge": {
            const calculate = orderAttributes && orderAttributes.reduce((accumulator: number, part: IOrderAttribute) => {
                //@param минимальная длина кромкования
                const minEdgeLength = getValueSettings(EnumSettingsDef.MINIMUM_EDGE_LENGTH, settings);
                //@param длина обрезка кромки
                const edgeTrim = getValueSettings(EnumSettingsDef.EDGE_TRIM, settings);
                //@param отход кромки в %
                const edgeWastePercentage = getValueSettings(EnumSettingsDef.EDGE_WASTE_PERCENTAGE, settings);

                let edgeTrimAmount = Number(edgeTrim) * 2;
                let percent = Number(edgeWastePercentage) !== 0 ? Number(edgeWastePercentage) / 100 : 0.1;

                let side = {
                    top: part.edgeTop ? (Number(part.x) >= Number(minEdgeLength) ? Number(part.x) : Number(minEdgeLength)) : false,
                    bottom: part.edgeBottom ? (Number(part.x) >= Number(minEdgeLength) ? Number(part.x) : Number(minEdgeLength)) : false,
                    left: part.edgeLeft ? (Number(part.y) >= Number(minEdgeLength) ? Number(part.y) : Number(minEdgeLength)) : false,
                    right: part.edgeRight ? (Number(part.y) >= Number(minEdgeLength) ? Number(part.y) : Number(minEdgeLength)) : false,
                }
                let resultSide = {
                    top: side.top ? ((Number(side.top) + edgeTrimAmount) + (Number(side.top) * percent)) : 0,
                    bottom: side.bottom ? ((Number(side.bottom) + edgeTrimAmount) + (Number(side.bottom) * percent)) : 0,
                    left: side.left ? ((Number(side.left) + edgeTrimAmount) + (Number(side.left) * percent)) : 0,
                    right: side.right ? ((Number(side.right) + edgeTrimAmount) + (Number(side.right) * percent)) : 0,
                }
                let value = (resultSide.top + resultSide.bottom + resultSide.left + resultSide.right) * part.quantity;
                return accumulator + getMM(value);
            }, 0);
            const amount = orderAttributes && orderAttributes.reduce((accumulator: number, part: IOrderAttribute) => {
                //@param return price edge
                const getPriceEdge = (id: string) => {
                    if (material && material.edges) {
                        let edge = material.edges.find(item => item['@id'] === id);
                        if (edge) return edge.price
                        else {
                            // console.log('not found edge')
                            return 0
                        }
                    }
                    // console.log('not found material or edges', material)
                    return 0
                }

                //@param find material
                let material = materials.find(item => item['@id'] === part.material);
                //@param минимальная длина кромкования
                const minEdgeLength = getValueSettings(EnumSettingsDef.MINIMUM_EDGE_LENGTH, settings);
                // const minEdgeLength = settings ? settings.find(setting => setting.slug === 'MINIMUM_EDGE_LENGTH') : 0;
                //@param длина обрезка кромки
                const edgeTrim = getValueSettings(EnumSettingsDef.EDGE_TRIM, settings);
                //@param отход кромки в %
                const edgeWastePercentage = getValueSettings(EnumSettingsDef.EDGE_WASTE_PERCENTAGE, settings);
                let edgeTrimAmount = Number(edgeTrim) * 2;
                let percent = Number(edgeWastePercentage) !== 0 ? Number(edgeWastePercentage) / 100 : 0.1;

                let side = {
                    top: part.edgeTop ? (Number(part.x) >= Number(minEdgeLength) ? Number(part.x) : Number(minEdgeLength)) : false,
                    bottom: part.edgeBottom ? (Number(part.x) >= Number(minEdgeLength) ? Number(part.x) : Number(minEdgeLength)) : false,
                    left: part.edgeLeft ? (Number(part.y) >= Number(minEdgeLength) ? Number(part.y) : Number(minEdgeLength)) : false,
                    right: part.edgeRight ? (Number(part.y) >= Number(minEdgeLength) ? Number(part.y) : Number(minEdgeLength)) : false,
                }
                let resultSide = {
                    top: side.top ? ((Number(side.top) + edgeTrimAmount) + (Number(side.top) * percent)) : 0,
                    bottom: side.bottom ? ((Number(side.bottom) + edgeTrimAmount) + (Number(side.bottom) * percent)) : 0,
                    left: side.left ? ((Number(side.left) + edgeTrimAmount) + (Number(side.left) * percent)) : 0,
                    right: side.right ? ((Number(side.right) + edgeTrimAmount) + (Number(side.right) * percent)) : 0,
                }
                let priceEdges = {
                    top: part.edgeTop ? getPriceEdge(part.edgeTop) : 0,
                    bottom: part.edgeBottom ? getPriceEdge(part.edgeBottom) : 0,
                    left: part.edgeLeft ? getPriceEdge(part.edgeLeft) : 0,
                    right: part.edgeRight ? getPriceEdge(part.edgeRight) : 0,
                }


                let result = {
                    top: resultSide.top && priceEdges.top ? (getMM((resultSide.top * Number(part.quantity))) * Number(priceEdges.top)) : 0,
                    bottom: resultSide.bottom && priceEdges.bottom ? (getMM((resultSide.bottom * Number(part.quantity))) * Number(priceEdges.bottom)) : 0,
                    left: resultSide.left && priceEdges.left ? (getMM((resultSide.left * Number(part.quantity))) * Number(priceEdges.left)) : 0,
                    right: resultSide.right && priceEdges.right ? (getMM((resultSide.right * Number(part.quantity))) * Number(priceEdges.right)) : 0,
                }

                return accumulator + (result.top + result.bottom + result.left + result.right);

            }, 0);
            return {
                calculate: Number((calculate).toFixed(2)), amount: Number((amount).toFixed(2))

            }
        }
        case "cnc": {
            const calculate = orderAttributes && orderAttributes.filter(part => part.medias && part.medias.length).reduce((accumulator: number, part: IOrderAttribute) => {
                let value = (Number(part.x) * Number(part.y)) * Number(part.quantity);
                return accumulator + value / 1000000;
            }, 0);
            const amount = orderAttributes && orderAttributes.filter(part => part.medias && part.medias.length).reduce((accumulator: number, part: IOrderAttribute) => {
                const costSetting = getValueSettings(EnumSettingsDef.COST_CNC_OPERATION, settings);
                let cost = Number(costSetting)
                if (cost) {
                    let value = (Number(part.x) * Number(part.y)) * Number(part.quantity);
                    return accumulator + (getMM(value) * getMM(cost));
                }
                return accumulator;
            }, 0);
            return {
                calculate: Number((calculate).toFixed(2)), amount: Number((amount).toFixed(2))
            }
        }
        case "package": {
            const calculate = orderAttributes && orderAttributes.reduce((accumulator: number, part: IOrderAttribute) => {
                let value = (Number(part.x) * Number(part.y)) * Number(part.quantity);
                return accumulator + value / 1000000;
            }, 0);
            const amount = orderAttributes && orderAttributes.reduce((accumulator: number, part: IOrderAttribute) => {
                const costSetting = getValueSettings(EnumSettingsDef.COST_PACKAGING, settings);
                let cost = Number(costSetting)
                if (cost) {
                    let value = (Number(part.x) * Number(part.y)) * Number(part.quantity);
                    return accumulator + (getMM(value) * getMM(cost));
                }
                return accumulator;
            }, 0);
            return {
                calculate: Number((calculate).toFixed(2)), amount: Number((amount).toFixed(2))
            }
        }
        default: {
            // console.log('not found type', type)
            return {
                calculate: 0, amount: 0
            }
        }
    }

}


export const calculationMaterial = (material: IMaterial, orderAttributes: IOrderAttribute[], type: TCaseCalculation, settings: ISetting[] | null) => {

    switch (type) {
        case "material": {
            const amount = orderAttributes && orderAttributes.filter(part => part.material === material['@id']).reduce((accumulator: number, part: IOrderAttribute) => {
                // let value = (Number(part.x) + Number(part.y)) * Number(part.quantity);
                let value = (Number(part.x) * Number(part.y)) * Number(part.quantity);
                // return accumulator + (getMM(value) * Number(material.price));
                return accumulator + ((value/1000000) * Number(material.price));


            }, 0);
            return {
                amount: Number((amount).toFixed(2))
            }
        }
        case "cutting": {
            const amount = orderAttributes && orderAttributes.filter(part => part.material === material['@id']).reduce((accumulator: number, part: IOrderAttribute) => {
                const findPriceCutting = getValueSettings(EnumSettingsDef.PRICE_SERVICE_CUTTING, settings)
                let value = ((Number(part.x) + Number(part.y)) * 2) * Number(part.quantity);
                return accumulator + (getMM(value) * Number(findPriceCutting));
            }, 0);
            return {
                amount: Number((amount).toFixed(2))
            }
        }
        case "edge": {
            const amount = orderAttributes && orderAttributes.filter(part => part.material === material['@id']).reduce((accumulator: number, part: IOrderAttribute) => {
                //@param return price edge
                const getPriceEdge = (id: string) => {
                    if (material && material.edges) {
                        let edge = material.edges.find(item => item['@id'] === id);
                        if (edge) return edge.price
                        else {
                            // console.log('not found edge')
                            return 0
                        }
                    }
                    // console.log('not found material or edges', material)
                    return 0
                }

                //@param минимальная длина кромкования
                const minEdgeLength = getValueSettings(EnumSettingsDef.MINIMUM_EDGE_LENGTH, settings);
                //@param длина обрезка кромки
                const edgeTrim = getValueSettings(EnumSettingsDef.EDGE_TRIM, settings);
                //@param отход кромки в %
                const edgeWastePercentage = getValueSettings(EnumSettingsDef.EDGE_WASTE_PERCENTAGE, settings);
                let edgeTrimAmount = Number(edgeTrim) * 2;
                let percent = Number(edgeWastePercentage) !== 0 ? Number(edgeWastePercentage) / 100 : 0.1;

                let side = {
                    top: part.edgeTop ? (Number(part.x) >= Number(minEdgeLength) ? Number(part.x) : Number(minEdgeLength)) : false,
                    bottom: part.edgeBottom ? (Number(part.x) >= Number(minEdgeLength) ? Number(part.x) : Number(minEdgeLength)) : false,
                    left: part.edgeLeft ? (Number(part.y) >= Number(minEdgeLength) ? Number(part.y) : Number(minEdgeLength)) : false,
                    right: part.edgeRight ? (Number(part.y) >= Number(minEdgeLength) ? Number(part.y) : Number(minEdgeLength)) : false,
                }
                let resultSide = {
                    top: side.top ? ((Number(side.top) + edgeTrimAmount) + (Number(side.top) * percent)) : 0,
                    bottom: side.bottom ? ((Number(side.bottom) + edgeTrimAmount) + (Number(side.bottom) * percent)) : 0,
                    left: side.left ? ((Number(side.left) + edgeTrimAmount) + (Number(side.left) * percent)) : 0,
                    right: side.right ? ((Number(side.right) + edgeTrimAmount) + (Number(side.right) * percent)) : 0,
                }
                let priceEdges = {
                    top: part.edgeTop ? getPriceEdge(part.edgeTop) : 0,
                    bottom: part.edgeBottom ? getPriceEdge(part.edgeBottom) : 0,
                    left: part.edgeLeft ? getPriceEdge(part.edgeLeft) : 0,
                    right: part.edgeRight ? getPriceEdge(part.edgeRight) : 0,
                }


                let result = {
                    top: resultSide.top && priceEdges.top ? (getMM((resultSide.top * Number(part.quantity))) * Number(priceEdges.top)) : 0,
                    bottom: resultSide.bottom && priceEdges.bottom ? (getMM((resultSide.bottom * Number(part.quantity))) * Number(priceEdges.bottom)) : 0,
                    left: resultSide.left && priceEdges.left ? (getMM((resultSide.left * Number(part.quantity))) * Number(priceEdges.left)) : 0,
                    right: resultSide.right && priceEdges.right ? (getMM((resultSide.right * Number(part.quantity))) * Number(priceEdges.right)) : 0,
                }

                return accumulator + (result.top + result.bottom + result.left + result.right);

            }, 0);
            return {
                amount: Number((amount).toFixed(2))
            }
        }
        case "edging": {
            const amount = orderAttributes && orderAttributes.filter(part => part.material === material['@id']).reduce((accumulator: number, part: IOrderAttribute) => {
                //@param  стоимость услуги кромкование
                const findPriceEdge = getValueSettings(EnumSettingsDef.PRICE_SERVICE_EDGE, settings);
                let side = {
                    top: part.edgeTop ? Number(part.x) : 0,
                    bottom: part.edgeBottom ? Number(part.x) : 0,
                    left: part.edgeLeft ? Number(part.y) : 0,
                    right: part.edgeRight ? Number(part.y) : 0,
                }
                let value = (side.top + side.bottom + side.left + side.right) * part.quantity;
                return accumulator + (getMM(value) * Number(findPriceEdge));

            }, 0);
            return {
                amount: Number((amount).toFixed(2))
            }
        }
        case "cnc": {
            const calculate = orderAttributes && orderAttributes.filter(part => (part.medias && part.medias.length) && (part.material === material['@id'])).reduce((accumulator: number, part: IOrderAttribute) => {
                let value = (Number(part.x) * Number(part.y)) * Number(part.quantity);
                return accumulator + value / 1000000;
            }, 0);
            const amount = orderAttributes && orderAttributes.filter(part => (part.medias && part.medias.length) && (part.material === material['@id'])).reduce((accumulator: number, part: IOrderAttribute) => {
                const costSetting = getValueSettings(EnumSettingsDef.COST_CNC_OPERATION, settings);
                let cost = Number(costSetting)
                if (cost) {
                    let value = (Number(part.x) * Number(part.y)) * Number(part.quantity);
                    return accumulator + (getMM(value) * getMM(cost));
                }
                return accumulator;
            }, 0);
            return {
                calculate: Number((calculate).toFixed(2)), amount:  Number((amount).toFixed(2))
            }
        }
        case "package": {
            const calculate = orderAttributes && orderAttributes.filter(part =>  (part.material === material['@id']))
                .reduce((accumulator: number, part: IOrderAttribute) => {
                let value = (Number(part.x) * Number(part.y)) * Number(part.quantity);
                return accumulator + value / 1000000;
            }, 0);
            const amount = orderAttributes && orderAttributes.filter(part =>  (part.material === material['@id']))
                .reduce((accumulator: number, part: IOrderAttribute) => {
                const costSetting = getValueSettings(EnumSettingsDef.COST_PACKAGING, settings);
                let cost = Number(costSetting)
                if (cost) {
                    let value = (Number(part.x) * Number(part.y)) * Number(part.quantity);
                    return accumulator + (getMM(value) * getMM(cost));
                }
                return accumulator;
            }, 0);
            return {
                calculate: Number((calculate).toFixed(2)), amount: Number((amount).toFixed(2))
            }
        }

        default: {
            return {
                amount: 0
            }
        }

    }
}

export const calculationDetailing = (material: IMaterial, part: IOrderAttribute, type: TCaseCalculation, settings: ISetting[] | null) => {

    switch (type) {
        case "material": {
            let value = (Number(part.x) *Number(part.y)) * Number(part.quantity);
            // let value = (Number(part.x) + Number(part.y)) * Number(part.quantity);
            // let amount = (getMM(value) * Number(material.price));
            let amount = ((value/1000000) * Number(material.price));
            return {
                amount: Number((amount).toFixed(2))
            }
        }
        case "cutting": {
            const findPriceCutting = getValueSettings(EnumSettingsDef.PRICE_SERVICE_CUTTING, settings)
            let value = ((Number(part.x) + Number(part.y)) * 2) * Number(part.quantity);
            let amount = (getMM(value) * Number(findPriceCutting));
            return {
                amount: Number((amount).toFixed(2))
            }
        }
        case "edge": {
            // const amount = orderAttributes && orderAttributes.filter(part => part.material === material['@id']).reduce((accumulator: number, part: IOrderAttribute) => {
            //@param return price edge
            const getPriceEdge = (id: string) => {
                if (material && material.edges) {
                    let edge = material.edges.find(item => item['@id'] === id);
                    if (edge) return edge.price
                    else {
                        // console.log('not found edge')
                        return 0
                    }
                }
                // console.log('not found material or edges', material)
                return 0
            }

            //@param минимальная длина кромкования
            const minEdgeLength = getValueSettings(EnumSettingsDef.MINIMUM_EDGE_LENGTH, settings);
            //@param длина обрезка кромки
            const edgeTrim = getValueSettings(EnumSettingsDef.EDGE_TRIM, settings);
            //@param отход кромки в %
            const edgeWastePercentage = getValueSettings(EnumSettingsDef.EDGE_WASTE_PERCENTAGE, settings);
            let edgeTrimAmount = Number(edgeTrim) * 2;
            let percent = Number(edgeWastePercentage) !== 0 ? Number(edgeWastePercentage) / 100 : 0.1;

            let side = {
                top: part.edgeTop ? (Number(part.x) >= Number(minEdgeLength) ? Number(part.x) : Number(minEdgeLength)) : false,
                bottom: part.edgeBottom ? (Number(part.x) >= Number(minEdgeLength) ? Number(part.x) : Number(minEdgeLength)) : false,
                left: part.edgeLeft ? (Number(part.y) >= Number(minEdgeLength) ? Number(part.y) : Number(minEdgeLength)) : false,
                right: part.edgeRight ? (Number(part.y) >= Number(minEdgeLength) ? Number(part.y) : Number(minEdgeLength)) : false,
            }
            let resultSide = {
                top: side.top ? ((Number(side.top) + edgeTrimAmount) + (Number(side.top) * percent)) : 0,
                bottom: side.bottom ? ((Number(side.bottom) + edgeTrimAmount) + (Number(side.bottom) * percent)) : 0,
                left: side.left ? ((Number(side.left) + edgeTrimAmount) + (Number(side.left) * percent)) : 0,
                right: side.right ? ((Number(side.right) + edgeTrimAmount) + (Number(side.right) * percent)) : 0,
            }
            let priceEdges = {
                top: part.edgeTop ? getPriceEdge(part.edgeTop) : 0,
                bottom: part.edgeBottom ? getPriceEdge(part.edgeBottom) : 0,
                left: part.edgeLeft ? getPriceEdge(part.edgeLeft) : 0,
                right: part.edgeRight ? getPriceEdge(part.edgeRight) : 0,
            }


            let result = {
                top: resultSide.top && priceEdges.top ? (getMM((resultSide.top * Number(part.quantity))) * Number(priceEdges.top)) : 0,
                bottom: resultSide.bottom && priceEdges.bottom ? (getMM((resultSide.bottom * Number(part.quantity))) * Number(priceEdges.bottom)) : 0,
                left: resultSide.left && priceEdges.left ? (getMM((resultSide.left * Number(part.quantity))) * Number(priceEdges.left)) : 0,
                right: resultSide.right && priceEdges.right ? (getMM((resultSide.right * Number(part.quantity))) * Number(priceEdges.right)) : 0,
            }

            let amount = (result.top + result.bottom + result.left + result.right);

            // }, 0);
            return {
                amount: Number((amount).toFixed(2))
            }
        }
        case "edging": {
            //@param  стоимость услуги кромкование
            const findPriceEdge = getValueSettings(EnumSettingsDef.PRICE_SERVICE_EDGE, settings);
            let side = {
                top: part.edgeTop ? Number(part.x) : 0,
                bottom: part.edgeBottom ? Number(part.x) : 0,
                left: part.edgeLeft ? Number(part.y) : 0,
                right: part.edgeRight ? Number(part.y) : 0,
            }
            let value = (side.top + side.bottom + side.left + side.right) * part.quantity;
            let amount = (getMM(value) * Number(findPriceEdge));

            return {
                amount: Number((amount).toFixed(2))
            }
        }
        case "cnc": {

            let amount = 0;
            const costSetting = getValueSettings(EnumSettingsDef.COST_CNC_OPERATION, settings);
            let cost = Number(costSetting)
            if (cost && part.medias && part.medias.length) {
                let value = (Number(part.x) * Number(part.y)) * Number(part.quantity);
                amount = (getMM(value) * getMM(cost));
            }

            return {
                amount: Number((amount).toFixed(2))
            }
        }
        case "package": {
            let amount = 0;
            const costSetting = getValueSettings(EnumSettingsDef.COST_PACKAGING, settings);
            let cost = Number(costSetting);
            if (cost) {
                let value = (Number(part.x) * Number(part.y)) * Number(part.quantity);
                amount = (getMM(value) * getMM(cost));
            }
            return {
                amount: Number((amount).toFixed(2))
            }
        }

        default: {
            return {
                amount: 0
            }
        }

    }
}

export const calculateAdditional = (amount: number, orderAttributes: IOrderAttribute[], settings: ISetting[] | null, materials:IMaterial[]) => {
    if (settings) {
        let minCost = getValueSettings(EnumSettingsDef.MIN_COST_DETAILING, settings);
        const amount = orderAttributes && orderAttributes.reduce((accumulator: number, part: IOrderAttribute) => {
            let material = materials.find(item => item['@id'] === part.material);
            if(material) {
                let costMaterial = calculationDetailing(material, part, 'material', settings).amount;
                let costEdge = calculationDetailing(material, part, 'edge', settings).amount;
                let costEdging = calculationDetailing(material, part, 'edging', settings).amount;
                let costPackage = calculationDetailing(material, part, 'package', settings).amount;
                let costCnc = calculationDetailing(material, part, 'cnc', settings).amount;
                let costCutting = calculationDetailing(material, part, 'cutting', settings).amount;
                let costAll = costEdging + costEdge + costCutting + costMaterial + costPackage + costCnc;
                if(costAll > Number(minCost)){
                    return  accumulator
                }
                if(costAll < Number(minCost)) {
                    return accumulator + (Number(minCost) - Number(costAll));
                }
            }return  accumulator
        }, 0);
        return Number(amount.toFixed(2))

    }
    return 0;
}