import React from 'react';
import Table from "../../ui/Table";
import {Input, InputNumber} from "../../ui/Field";
import Flex from "../../ui/Flex";
import InputCheckbox from "../../ui/Field/InputCheckbox";
import {BsFileEarmarkArrowUpFill} from "react-icons/bs";
import {IEdge, IMaterial, IOrderAttribute} from "../../../models/types";
import EdgingSelect from "./EdgingSelect";
import {IChangeValueDetailing} from "../DetailingSection";
import {StyleDeleteIcon} from "../../ui/Icons";
import {StyledTheme} from "../../../styled";
import {filteredEdgesActualChangeSpase} from "../../../helpers/edges";
import {IModalUploadMedia} from "../../../store/slice/CommonSlice";
import TooltipInfo from "../TooltipInfo";
import styled from "styled-components";


const StyledCost = styled.span`
  white-space: nowrap;
  font-size: 12px;
  color: ${props => props.theme.colors.grey_purple};
  font-weight: bold;
  margin-left: 8px;
`
interface IProps {
    part: IOrderAttribute,
    edges: IEdge[] | [] | undefined,
    row: number,
    commandEvent: {
        create: (e: React.KeyboardEvent<HTMLSelectElement | HTMLInputElement>, row: number) => void,
        next: (e: React.KeyboardEvent<HTMLSelectElement | HTMLInputElement>, row: number, col: number) => void,
        changeBand: (e: React.KeyboardEvent<HTMLSelectElement>, side: "edgeBottom" | "edgeLeft" | "edgeRight" | "edgeTop", currentValue: string, edges: IEdge[], part: IOrderAttribute) => void
    },
    // onCreatePart: () => void
    onChangeValues: ({key, value, part}: IChangeValueDetailing) => void,
    deleteDetailing: (id: number) => void,
    // material: Pick<IMaterial, 'x' | 'y' | 'z'>
    openModalMedia: ({isOpen, detailing}:IModalUploadMedia) => void,
    cost: string
}

const StyledInfo = styled(Flex)`
  position: relative;
  padding-right: 20px;
  & > div{
    right: 0;
    top: 0;
  }
  & ul{
    transform: translateX(0%);
    z-index: 3;
  }
`
const TDetailingItem = ({part, edges, row, commandEvent, onChangeValues, deleteDetailing, openModalMedia, cost}: IProps) => {

    return (
        <Table.TR key={`tr_part_${part.productNumber}`} isBgChild={true}  background={ `${part.medias && part.medias.length ? StyledTheme.table_bg.success : ''} `}>
            <Table.TD style={{position: 'relative'}}  key={`tr_part_id`} title={'#'}>  <StyledInfo > {row}  <TooltipInfo/> </StyledInfo> </Table.TD>
            <Table.TD  key={`tr_part_name`} title={'Наименование'}>
                <div className={`focused_${row}_${1} part-wrap-info`}>

                    <Input
                        required={true}
                        width={'100%'}
                        minWidth={'80px'}
                        placeholder={'...'}
                        type={'text'}
                        name={'part_name'}
                        value={part.name}
                        onChange={(e) => onChangeValues({key: "name", value: e.target.value, part})}
                        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if ((e.keyCode == 10 || e.keyCode == 13) && (e.ctrlKey || e.metaKey)) {

                                commandEvent.create(e, row);
                            } else if (e.key === "Enter" || e.keyCode === 13) {
                                commandEvent.next(e, row, 1);
                            }
                        }}
                        onKeyDown={(e) => {
                            if ((e.keyCode == 10 || e.keyCode == 13) && (e.ctrlKey || e.metaKey)) {
                                e.preventDefault();
                                commandEvent.create(e, row);
                            }
                        }}
                    />
                </div>
            </Table.TD>
            <Table.TD style={{position: 'relative'}}  key={`tr_part_width`} title={'Длина Х'}>
                <div className={`focused_${row}_${2} part-wrap-info`}>
                    <InputNumber
                        required={true}
                        width={'100%'}
                        minWidth={'100px'}
                        min={0}
                        max={1200}
                        value={part.x === 0 ? '' : part.x}

                        borderColor={Number(part.x) === 0 ? StyledTheme.colors.danger : StyledTheme.colors.grey}
                        placeholder={'...'}
                        type={'number'}
                        name={'part_width'}
                        onChange={(e) => onChangeValues({key: "x", value: e.target.value, part})}
                        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {

                            if ((e.keyCode == 10 || e.keyCode == 13) && (e.ctrlKey || e.metaKey)) {
                                e.preventDefault();
                                commandEvent.create(e, row);
                            } else if (e.key === "Enter" || e.keyCode === 13) {
                                e.preventDefault();
                                commandEvent.next(e, row, 2);
                            }
                        }}
                        onKeyDown={(e) => {
                            if ((e.keyCode == 10 || e.keyCode == 13) && (e.ctrlKey || e.metaKey)) {
                                e.preventDefault();
                                commandEvent.create(e, row);
                            }
                        }}
                    />
                </div>
            </Table.TD>
            <Table.TD style={{position: 'relative'}} key={`tr_part_edge_top`} title={'Кромка ↑'}>
                <div className={`focused_${row}_${3} part-wrap-info`}>
                    <EdgingSelect edges={edges}
                                  disabledEdges={filteredEdgesActualChangeSpase(edges || [], part, "edgeTop")}
                                  onChange={(e) => {
                                      onChangeValues({key: "edgeTop", value: e.target.value, part})
                                  }}
                                  onKeyPress={
                                      (e: React.KeyboardEvent<HTMLSelectElement>) => {
                                          // commandEvent.create(e, row);
                                          commandEvent.next(e, row, 3);
                                          commandEvent.changeBand(e, "edgeTop", part.edgeTop || '', filteredEdgesActualChangeSpase(edges || [], part, "edgeTop"), part);
                                      }}
                                  key={`edges_side_top`} value={part.edgeTop || ''}/>
                </div>
            </Table.TD>
            <Table.TD style={{position: 'relative'}} key={`tr_part_edge_bottom`} title={'Кромка ↓'}>
                <div className={`focused_${row}_${4} part-wrap-info`}>
                    <EdgingSelect edges={edges}
                                  disabledEdges={filteredEdgesActualChangeSpase(edges || [], part, "edgeBottom")}
                                  onChange={(e) => {
                                      onChangeValues({key: "edgeBottom", value: e.target.value, part})
                                  }}

                                  onKeyPress={
                                      (e: React.KeyboardEvent<HTMLSelectElement>) => {
                                          // commandEvent.create(e, row);
                                          commandEvent.next(e, row, 4);
                                          commandEvent.changeBand(e, "edgeBottom", part.edgeBottom || '', filteredEdgesActualChangeSpase(edges || [], part, "edgeBottom"), part);
                                      }}
                                  key={`edges_side_bottom`} value={part.edgeBottom || ''}/>
                </div>
            </Table.TD>
            <Table.TD style={{position: 'relative'}} key={`tr_part_height`} title={'Длина У'}>
                <div className={`focused_${row}_${5} part-wrap-info`}>
                    <InputNumber
                        required={true}

                        width={'100%'}
                        minWidth={'100px'}
                        min={0}
                        max={1200}
                        placeholder={'...'}
                        type={'number'}
                        value={part.y === 0 ? '' : part.y}
                        borderColor={Number(part.y) === 0 ? StyledTheme.colors.danger : StyledTheme.colors.grey}
                        name={'part_length'}
                        onChange={(e) => onChangeValues({key: "y", value: e.target.value, part})}
                        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if ((e.keyCode == 10 || e.keyCode == 13) && (e.ctrlKey || e.metaKey)) {

                                commandEvent.create(e, row);
                            } else if (e.key === "Enter" || e.keyCode === 13) {
                                commandEvent.next(e, row, 5);
                            }
                        }}
                        onKeyDown={(e) => {
                            if ((e.keyCode == 10 || e.keyCode == 13) && (e.ctrlKey || e.metaKey)) {
                                e.preventDefault();
                                commandEvent.create(e, row);
                            }
                        }}
                    /></div>
            </Table.TD>
            <Table.TD style={{position: 'relative'}} key={`tr_part_edge_left`} title={'Кромка ←'}>
                <div className={`focused_${row}_${6} part-wrap-info`}>
                    <EdgingSelect edges={edges}
                                  disabledEdges={filteredEdgesActualChangeSpase(edges || [], part, "edgeLeft")}
                                  onChange={(e) => {
                        onChangeValues({key: "edgeLeft", value: e.target.value, part})
                    }}

                                  onKeyPress={
                                      (e: React.KeyboardEvent<HTMLSelectElement>) => {
                                          // commandEvent.create(e, row);
                                          commandEvent.next(e, row, 6);
                                          commandEvent.changeBand(e, "edgeLeft", part.edgeLeft || '', filteredEdgesActualChangeSpase(edges || [], part, "edgeLeft"), part);
                                      }}
                                  key={`edges_side_top`} value={part.edgeLeft || ''}/>
                </div>


            </Table.TD>
            <Table.TD  style={{position: 'relative'}} key={`tr_part_edge_right`} title={'Кромка →'}>
                <div className={`focused_${row}_${7} part-wrap-info`}>
                    <EdgingSelect
                        edges={edges}
                        disabledEdges={filteredEdgesActualChangeSpase(edges || [], part, "edgeRight")}
                        onChange={(e) => {
                            onChangeValues({key: "edgeRight", value: e.target.value, part})
                        }} onKeyPress={
                        (e: React.KeyboardEvent<HTMLSelectElement>) => {
                            // commandEvent.create(e, row);
                            commandEvent.next(e, row, 7);
                            commandEvent.changeBand(e, "edgeRight", part.edgeRight || '', filteredEdgesActualChangeSpase(edges || [], part, "edgeRight"), part);
                        }}
                        key={`edges_side_top`}
                        value={part.edgeRight || ''}/>
                </div>

            </Table.TD>
            <Table.TD style={{position: 'relative'}} key={`tr_part_count`} title={'Количество'}>
                <div className={`focused_${row}_${8} part-wrap-info`}>
                    <InputNumber
                        width={'100px'}
                        minWidth={'100px'}
                        min={1}
                        borderColor={Number(part.quantity) === 0 ? StyledTheme.colors.danger : StyledTheme.colors.grey}
                        value={part.quantity}
                        placeholder={'...'}
                        type={'number'}
                        name={'part_width'}
                        onChange={(e) => onChangeValues({key: "quantity", value: Number(e.target.value), part})}
                        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if ((e.keyCode == 10 || e.keyCode == 13) && (e.ctrlKey || e.metaKey)) {
                                e.preventDefault();
                                commandEvent.create(e, row);
                            } else if (e.key === "Enter" || e.keyCode === 13) {
                                e.preventDefault();
                                commandEvent.next(e, row, 8);
                            }
                        }}
                        onKeyDown={(e) => {
                            if ((e.keyCode == 10 || e.keyCode == 13) && (e.ctrlKey || e.metaKey)) {
                                e.preventDefault();
                                commandEvent.create(e, row);
                            }
                        }}
                    />
                </div>
            </Table.TD>
            <Table.TD style={{position: 'relative'}} key={`tr_part_texture`} title={'Текстура'}>
                <Flex  justify={'center'}>
                    <div className={`focused_${row}_${9} part-wrap-info`} >

                        <InputCheckbox variant={'check'} value={'1'} checked={Boolean(part.texture)}
                                       htmlFor={`part_texture${part.productNumber}`}
                                       id={`part_texture${part.productNumber}`}
                                       onChange={(e) => {
                                           onChangeValues({key: "texture", value: e.target.checked, part})
                                       }}
                                       key={'input-texture'}
                                       focused={true}
                                       onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {

                                           if ((e.keyCode == 10 || e.keyCode == 13) && (e.ctrlKey || e.metaKey)) {
                                               e.preventDefault();
                                               commandEvent.create(e, row);
                                           } else if (e.key === "Enter" || e.keyCode === 13) {
                                               e.preventDefault();
                                               commandEvent.next(e, row + 1, 0);
                                           }
                                           if (e.which === 32 || e.code === 'space') {
                                               e.preventDefault();
                                               onChangeValues({key: "texture", value: part.texture, part})
                                           }
                                       }}
                                       onKeyDown={(e) => {
                                           if ((e.keyCode == 10 || e.keyCode == 13) && (e.ctrlKey || e.metaKey)) {
                                               e.preventDefault();
                                               commandEvent.create(e, row);
                                           }
                                       }}
                        />
                    </div>
                </Flex>
            </Table.TD>
            <Table.TD key={`tr_part_action`} title={'Действие'}>
                <Flex align={'center'}>
                    <BsFileEarmarkArrowUpFill fontSize={'32px'} style={{cursor: 'pointer'}} onClick={()=>openModalMedia({isOpen: true, detailing:part})}/>
                    <StyleDeleteIcon title={'Удалить деталь'} onClick={() => deleteDetailing(part.productNumber)}/>
                    <StyledCost>{cost}</StyledCost>
                </Flex>
            </Table.TD>
        </Table.TR>
    );
};

export default TDetailingItem;