import React, {useEffect, useMemo} from 'react';
import Card from "../ui/Card";
import Table from "../ui/Table";
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import THeader from "./Table/THeader";
import TDetailingItem from "./Table/TDetailingItem";
import {IEdge, IOrderAttribute, ParamsUpdateAttribute} from "../../models/types";
import {ProjectActions} from "../../store/slice/ProjectOrder/ProjectOrderSlice";
import {focusedNexElementDetailing} from "./utils/helpers";
import Button from "../ui/Button";
import Flex from "../ui/Flex";
import {CommonActions, IModalUploadMedia} from "../../store/slice/CommonSlice";
import {EnumSettingsDef, getSettingValueForSlug} from "../../consts/settingsDef";
import {calculationDetailing} from "../../helpers/calculation";

const StyleCard = styled(Card)<{ isOpen: boolean }>`
  overflow-y: auto;
  @media (min-width: 992px) {
    ${props => props.isOpen ?
            `min-height: calc(100vh - 160px);
            max-height: calc(100vh - 160px)` :

            `min-height: calc(100vh - 430px);max-height: calc(100vh - 430px)`};
    overflow-y: auto;

  }
`
const StyleTable = styled(Table)`

`

export interface IChangeValueDetailing extends ParamsUpdateAttribute {
    part: IOrderAttribute
}

const DetailingSection = () => {
    const dispatch = useAppDispatch();
    const {isOpen} = useAppSelector(state => state.common.togglePanelOrder)
    const {order, choiceMaterial, settings} = useAppSelector(state => state.projectOrder, (prev, next) => {
        return false;
    });

    const currency = useMemo(()=>{
        return getSettingValueForSlug(EnumSettingsDef.CURRENCY, settings.entry);
    }, [settings.entry]);

    const minCostDetailing = useMemo(()=>{
        return getSettingValueForSlug(EnumSettingsDef.MIN_COST_DETAILING, settings.entry);
    }, [settings.entry])
    const onChangeValues = ({key, value, part}: IChangeValueDetailing) => {

        switch (key) {
            case "texture": {
                let newY = part.y;
                if (value) {
                    newY = choiceMaterial && (Number(part.y) > Number(choiceMaterial.y)) ? Number(choiceMaterial.y) : part.y;
                } else {
                    newY = choiceMaterial && (Number(part.y) > Number(choiceMaterial.x)) ? Number(choiceMaterial.x) : part.y;
                }
                dispatch(ProjectActions.updateValuesDetailing({key: key, value: value, part}));
                dispatch(ProjectActions.updateValuesDetailing({key: 'y', value: newY, part}));
                return;
            }
            case "x": {
                let newX = choiceMaterial && (Number(value) > Number(choiceMaterial.x)) ? Number(choiceMaterial.x) : value;
                dispatch(ProjectActions.updateValuesDetailing({key: key, value: newX, part}));
                return;
            }
            case "y": {
                let newY = value;
                if (part.texture) {
                    newY = choiceMaterial && (Number(value) > Number(choiceMaterial.y)) ? Number(choiceMaterial.y) : value;
                } else {
                    newY = choiceMaterial && (Number(value) > Number(choiceMaterial.x)) ? Number(choiceMaterial.x) : value;
                }
                dispatch(ProjectActions.updateValuesDetailing({key: key, value: value, part}));
                dispatch(ProjectActions.updateValuesDetailing({key: 'y', value: newY, part}));
                return;

            }
            default: {
                dispatch(ProjectActions.updateValuesDetailing({key: key, value: value, part}))

            }
        }
    }
    const deleteDetailing = (id: number) => {
        dispatch(ProjectActions.deleteDetailing(id));
    }
    const onCreatePart = (row: number) => {
        dispatch(ProjectActions.createDetailing(row));

    }
    const openModalMedia = ({isOpen, detailing}:IModalUploadMedia)=>{
        dispatch(CommonActions.toggleModalUploadMedia({isOpen, detailing}))
    }
    const commandEvent = {
        create: (e: React.KeyboardEvent<HTMLSelectElement | HTMLInputElement>, row: number) => {
            if ((e.keyCode == 10 || e.keyCode == 13) && (e.ctrlKey || e.metaKey)) {
                e.preventDefault()
                focusedNexElementDetailing({
                    classN: 'focused', row: (row + 1), col: 0, cb: () => {
                        onCreatePart(row+1);
                    }
                })
                return
            }
        },
        next: (e: React.KeyboardEvent<HTMLSelectElement | HTMLInputElement>, row: number, col: number) => {
            if (e.key === "Enter" || e.keyCode === 13) {
                e.preventDefault();
                focusedNexElementDetailing({
                    classN: 'focused', row, col: col, cb: () => {
                        onCreatePart(row);
                    }
                });
                return;
            }
        },
        changeBand: (e: React.KeyboardEvent<HTMLSelectElement>, side: "edgeBottom" | "edgeLeft" | "edgeRight" | "edgeTop", currentValue: string, edges: IEdge[], part: IOrderAttribute) => {
            if (e.which === 32 || e.code === 'space') {
                e.preventDefault();
                let nextBandV = '';

                if (currentValue === '' && Array.isArray(edges) && edges.length) {
                    nextBandV = edges[0]['@id'] || '';
                } else if (Array.isArray(edges) && edges.length) {

                    for (let i = 0; i < edges.length; i++) {

                        if (currentValue === edges[i]['@id']) {
                            let next = edges[i + 1] ? edges[i + 1]['@id'] : ''
                            nextBandV = next || '';

                        }
                    }
                }
                onChangeValues({key: side, value: nextBandV, part});
                return;
            }
        }
    }

    useEffect(()=>{
        if(order && order.orderAttributes && choiceMaterial && !order.orderAttributes.filter(item => item.material === choiceMaterial['@id']).length ){
            onCreatePart(1);
        }
    }, [choiceMaterial]);




    return (
        <>
            {order && order.orderAttributes && choiceMaterial && !order.orderAttributes.filter(item => item.material === choiceMaterial['@id']).length &&
                <Flex justify={'end'} margin={'15px 0px 15px 0px'}>
                    <Button key={'createPart'} variant={'theme'} onClick={() => onCreatePart(1)}>Добавить
                        деталь +</Button>
                </Flex>
            }
        <StyleCard margin={'25px 0px 0px 0px'} padding={'0px'} key={'detailing-section'} isOpen={isOpen}>

            <form className="table-response" key={'detailing-response-table'}>
                <StyleTable key={'detailing_table_table'}>
                    <THeader key={'detailing_table_head'}/>
                    <Table.Body key={'detailing_table_body'}>

                        {order && order.orderAttributes && choiceMaterial ? order.orderAttributes.filter(part => part.material === choiceMaterial['@id']).map((part, index) => {

                                    let cost =   Number((
                                        calculationDetailing(choiceMaterial, part, 'material', settings.entry).amount +
                                        calculationDetailing(choiceMaterial, part, 'cutting', settings.entry).amount +
                                        calculationDetailing(choiceMaterial, part, 'edging', settings.entry).amount +
                                        calculationDetailing(choiceMaterial, part, 'edge', settings.entry).amount +
                                        calculationDetailing(choiceMaterial, part, 'cnc', settings.entry).amount +
                                        calculationDetailing(choiceMaterial, part, 'package', settings.entry).amount
                                    ).toFixed(2));
                                    let amount = cost > Number(minCostDetailing) ? cost : Number(minCostDetailing);
                                    const amountDetailing = `${
                                      amount.toLocaleString()
                                    }  ${currency}`;
                                    return <TDetailingItem part={part} edges={choiceMaterial && choiceMaterial.edges}
                                                           key={'detailing_item' + part.productNumber}
                                                           row={index + 1}
                                                           commandEvent={commandEvent}
                                                           onChangeValues={onChangeValues}
                                                           deleteDetailing={deleteDetailing}
                                                           // material={choiceMaterial}
                                                           openModalMedia={openModalMedia}
                                                           cost={amountDetailing}

                                    />
                                }
                            )

                            : <Table.Empty/>}

                    </Table.Body>
                </StyleTable>
            </form>


        </StyleCard>
            </>
    );
};

export default DetailingSection;