import React from 'react';
import styled from "styled-components";
interface IStyleCard{
    padding?: string,
    margin?: string,
    background? : string,
    radius?: string,
    children?: React.ReactNode
}
const StyleCard = styled.div<IStyleCard>`
  padding: ${props => props.padding || '10px 10px'};
  background: ${props => props.background || props.theme.colors.white};
  margin: ${props => props.margin || '0'};
  border: 1px soldi ${props=> props.theme.colors.border};
  border-radius: ${props => props.radius || '0px'};
  
`

const Card= (props:IStyleCard) => {
    return (<StyleCard {...props}/>);
};

export default Card;