import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Container from "../../components/ui/Container";
import PaginationContainer from "../../components/ui/Pagination/PaginationContainer";
import OrderTable from "../../components/Orders/OrderTable";
import {Title} from "../../components/ui/Typography";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {fetchingOrders, IPayloadFilterChange, OrderAction} from "../../store/slice/OrderSlice";
import Loader from "../../components/ui/Loader";
import AlertBoxError from "../../components/common/AlertBox";
import OrderCreate from "../../components/Orders/OrderCreate";
import Flex from "../../components/ui/Flex";
import {
    fetchingCloneOrders,
    fetchingDeleteOrders,
    fetchingEditOrders,
    fetchingInitialOrders,
    fetchingPublishOrders
} from "../../store/service/FetchingOrders";
import {IOrders, ROLES} from "../../models/types";
import {EnumSettingsDef, getSettingValueForSlug} from "../../consts/settingsDef";
import OrdersFilter from "../../components/Orders/OrdersFilter/OrdersFilter";
import {IconFilter} from "../../components/ui/Icons";
import {returnObjHasValue} from "../../helpers/main";
import {fetchingGetAllMembers} from "../../store/slice/MembersSlice";
import {IParamsGetMembers} from "../../api/MemberHttp";
import OrdersHeader from "../../store/slice/OrdersHeader";

const OrdersPage = () => {
    const {isLoading, isError, entries, pagination, error, filter: filters} = useAppSelector(state => state.orders);
    const {settings, status} = useAppSelector(state => state.projectOrder);
    const {error: errorCommon, isError: isErrorCommon} = useAppSelector(state => state.common);
    const {member} = useAppSelector(state => state.members);

    const isAdmin = useMemo(() => {
        return !!(member && member.current && member.current.role === ROLES.ADMIN)
    }, [member.current]);

    const nameService = useMemo(() => {
        return getSettingValueForSlug(EnumSettingsDef.NAME_SERVICE, settings.entry).trim();
    }, [settings.entry])

    let linkMobilePortal = useMemo(() => {
        if (settings && settings.entry && Array.isArray(settings.entry)) {
            return getSettingValueForSlug(EnumSettingsDef.LINK_MOBILE_PORTAL, settings.entry).trim();
        }
    }, [settings.entry]);

    // let filters = useMemo(() => {
    //     return filter
    // }, [filter])

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchingInitialOrders());
    }, []);


    useEffect(()=>{
        dispatch(fetchingOrders({page:1, ...filters}));
    }, [filters.member]);

    const onFetchingOrder = (page: number) => {
        console.log('filter onFetchingOrder', filters);
        dispatch(fetchingOrders({page, ...filters}));
    }

    const onDeleteOrder = useCallback((id: number) => dispatch(fetchingDeleteOrders(id, {page: pagination.page, ...filters})), [dispatch, filters])
    const onCloneOrder = useCallback((order: IOrders) => dispatch(fetchingCloneOrders(order, {page: pagination.page, ...filters})), [dispatch, filters])
    const onPublishOrder = useCallback((id: number) => dispatch(fetchingPublishOrders(id, {page: pagination.page, ...filters})), [dispatch, filters])

    const onEditOrder = useCallback((id: number, status: string) => {
        dispatch(fetchingEditOrders(id, {
            status: status
        }, {page: pagination.page, ...filters}));
    }, [dispatch])

    const onChangeOrderName = useCallback((id: number, name: string) => {
        dispatch(fetchingEditOrders(id, {
            name: name
        }, {page: pagination.page, ...filters}));
    }, [dispatch])


    return (
        <>
            {isLoading && <Loader key={'order_page_loader'}/>}
            <AlertBoxError error={error || errorCommon} isError={isError || isErrorCommon} key={'error-orders'} fixedTop={true}/>
            <OrdersHeader filters={filters} isAdmin={isAdmin} onFetchingOrder={onFetchingOrder}/>
            <Container margin={'25px auto'}>
                {entries && Array.isArray(entries) &&

                    <OrderTable key={'orders'} orders={entries} onDeleteOrder={onDeleteOrder}
                                onCloneOrder={onCloneOrder}
                                linkMobilePortal={linkMobilePortal}
                                onEditOrder={onEditOrder}
                                statusList={status && Array.isArray(status.entry) ? status.entry : null}
                                onPublishOrder={onPublishOrder}
                                onChangeOrderName={onChangeOrderName}
                                isAdmin={isAdmin}
                                nameService={nameService}
                    />
                }

                <PaginationContainer page={pagination.page} setPage={(page: number) => {
                    onFetchingOrder(page)
                }} total={pagination.total} limit={30}/>

            </Container>
        </>
    );
};

export default OrdersPage;