import React, {useEffect} from 'react';
import useAlertMessages from "../../../hooks/useAlertMessages";
import {Text} from "../../ui/Typography";
import Alert from "../../ui/Alert";
import {StyledTheme} from "../../../styled";
import Flex from "../../ui/Flex";

interface IProps {
    error?: any | null,
    isError?: boolean,
    fixedTop: boolean,

}

const AlertBoxError = ({error, isError = false, fixedTop = false}: IProps) => {
    const {onClose, open, onOpen} = useAlertMessages()

    useEffect(() => {
        if (error && isError && !open) {
            onOpen()
        }
    }, [
        error, isError
    ]);
    return (
        <>{(error || isError) && open ?
            <Alert type={'error'} fixedTop={fixedTop} onClose={onClose} key={'alert'}>
                <Flex align={'center'}  key={'alert-flex'}>
                    <Text  key={'alert-title'}
                        margin={'0px 8px 0px 0px'}
                          color={StyledTheme.colors.danger}>{error.title ? error.title : error.message ? error.message : error['hydra:title'] ? error['hydra:title'] : ''}</Text>

                    <Text key={'alert-status'}
                          color={StyledTheme.colors.danger}
                          fontWeight={'bold'}>{error.status ? error.status : ''}</Text>
                </Flex>
                <Text  key={'alert-detail'}>{error.detail ? error.detail : ''}</Text>
                {error['violations'] && Array.isArray(error['violations']) ? error['violations'].map((item: any, index) => {
                    return <Text  key={'alert-violations-item'+index} fontWeight={'bold'}>{item.propertyPath} - {item.message}</Text>
                }) : <Text key={'alert-description'}
                           margin={'0px 8px 0px 0px'}
                           color={StyledTheme.colors.danger}>
                    <>{error['hydra:description'] ? error['hydra:description'] : ''}</>
                </Text>}

            </Alert>
            : <></>}
        </>

    );
};

export default AlertBoxError;