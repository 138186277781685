import React from 'react';
import {IconClose} from "../ui/Icons";

interface IProps {
    furnId: number,
    onDelete: () => void,
}

const TrOrderDelete = ({furnId, onDelete}: IProps) => {
    return (
        !furnId ? <IconClose title={'Удалить'} onClick={() => onDelete()}/> : <></>
    );
};

export default TrOrderDelete;