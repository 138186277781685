import React, {useEffect, useMemo} from 'react';
import FormRegistration from "../../components/Form/FormRegistration";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {fetchingRegistration, fetchingRegistrationCredential} from "../../store/service/FetchingAuth";
import {IRegistration, NamingToken} from "../../models/authTypes";
import styled from "styled-components";
import Card from "../../components/ui/Card";
import Container from "../../components/ui/Container";
import {Title} from "../../components/ui/Typography";
import Flex from "../../components/ui/Flex";
import {Link, Navigate} from "react-router-dom";
import {ROUTES} from "../../consts/routs";
import {AiOutlineArrowRight} from "react-icons/ai";
import AlertBox from "../../components/common/AlertBox";
import Loader from "../../components/ui/Loader";
import {AuthHttp} from "../../api/AuthHttp";
import {log} from "util";
import {compareIfExpirationDate} from "../../api/settings";
import {fetchingCheckAuthenticationSystem} from "../../store/slice/MembersSlice";
import {fetchingSettingsNotAuthorized} from "../../store/service/FetchingProjectData";
import {EnumSettingsDef, getSettingValueForSlug} from "../../consts/settingsDef";

const StyledCard = styled(Card)
    `position: relative;
      box-shadow: 0 0 5px #ccc;
      width: 100%;
      @media (min-width: 576px) {
        max-width: 560px;

      }
    `
const StyledWrapFlex = styled(Flex)`
  align-items: center;
  min-height: calc(100vh - 50px);
  margin: 25px auto;
  justify-content: center;
  flex-direction: column;
`
const StyledLink = styled(Link)`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-decoration: none;

  & > svg {
    margin-left: 8px;
    transform: translateX(0px);
    transition: transform 0.9ms;
  }

  &:hover {
    color: ${props => props.theme.colors.theme};

  }

  &:hover svg {
    transform: translateX(10px);
    fill: ${props => props.theme.colors.theme};;
  }
`
const RegistrationPage = () => {
    const dispatch = useAppDispatch();
    const {isError, error, isLoading} = useAppSelector(state => state.common);
    const {member} = useAppSelector(state => state.members);
    const {settings} = useAppSelector(state => state.projectOrder);
    const settingsCodeCountry = useMemo(()=>{
        return getSettingValueForSlug(EnumSettingsDef.FIXED_CODE_COUNTRY_PHONE, settings.entry)
    }, [settings.entry])
    useEffect(() => {
        if (compareIfExpirationDate(Number(localStorage.getItem(NamingToken.CLIENT_TOKEN_EXPIRES)) || null)) {
            dispatch(fetchingRegistrationCredential());

        }
        if(!compareIfExpirationDate(Number(localStorage.getItem(NamingToken.CLIENT_TOKEN_EXPIRES)) || null)){
            dispatch(fetchingSettingsNotAuthorized(1));
        }
    }, []);


    useEffect(() => {
        if(localStorage.getItem(NamingToken.REFRESH_TOKEN) && localStorage.getItem(NamingToken.ACCESS_TOKEN)) {
            dispatch(fetchingCheckAuthenticationSystem());
        }
    }, [member]);
    const onSubmit = (data: IRegistration) => {
        dispatch(fetchingRegistration(data));
    }

    if (member.current) return <Navigate to={ROUTES.orders}/>
    return (
        <Container>
            <AlertBox fixedTop={true} key={'error-box'} isError={isError} error={error}/>
            <StyledWrapFlex>
                <StyledCard>
                    {isLoading && <Loader isFixedParent={true} key={'loader'}/>}
                    <Flex justify={'center'}>
                        <Title level={2}>Регистрация</Title>
                    </Flex>
                    <FormRegistration onHandlerSubmit={onSubmit} settingsCodeCountry={settingsCodeCountry}/>

                </StyledCard>
                <Flex justify={'center'} margin={'25px 0px 0px 0px'}>
                    <StyledLink to={ROUTES.login}>Авторизация <AiOutlineArrowRight/></StyledLink>
                </Flex>
            </StyledWrapFlex>
        </Container>
    );
};

export default RegistrationPage;