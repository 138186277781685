import {Input, InputNumber} from "./Input";
import Select from "./Select";
import FileUpload from './FileUpload'
import styled from "styled-components";
import Flex from "../Flex";
import PhoneInput, {CountryData} from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {SelectDynamic} from "./SelectDynamic";
const Field = styled(Flex)`
  margin: 8px 0px;
`
const Label = styled.label<{margin?:string}>`
  font-size: 16px;
  margin:${props => props.margin ||  '0px 0px 8px 0px'};
`
const RequiredFiled = styled.sup`
  font-size: 12px;
  color: #dc3545;
  margin-left: 1px;
`

export  const StyleInputPhone = styled(PhoneInput)`
  border: 1px solid #CDCCD7;
  border-radius: 0px;
  line-height: 25px;
  height: 35px;
  width: 100%;
`
export {
    RequiredFiled,
    Input, InputNumber, Select, FileUpload, Field, Label, SelectDynamic
}