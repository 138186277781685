import {configureStore} from "@reduxjs/toolkit";
import ordersReducer from "./slice/OrderSlice";
import {commonReducer} from "./slice/CommonSlice";
import {projectOrderReducer} from "./slice/ProjectOrder/ProjectOrderSlice";
import {memberReducer} from "./slice/MembersSlice";

export const store = configureStore({
    reducer: {
        members: memberReducer,
        projectOrder: projectOrderReducer,
        common: commonReducer,
        orders: ordersReducer
        // [ApiService.reducerPath]: ApiService.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(),
});


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

//@ts-ignore
window.store = store;
