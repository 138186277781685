import React from 'react';
import {IconClone} from "../ui/Icons";

interface IProps{
    furnId:number, onCloneOrder:()=> void,
    hasDetail: boolean
}
const TdOrderClone = ({furnId, onCloneOrder, hasDetail}:IProps) => {
    return (
        !furnId  && hasDetail?  <IconClone title={'Копировать'} onClick={onCloneOrder}/> : <></>
            
    );
};

export default TdOrderClone;