import React from 'react';
import Card from "../ui/Card";
import MaterialItem from "./MaterialItem";
import styled from "styled-components";
import MaterialHead from "./MaterialHead";
import {useAppSelector} from "../../hooks/hooks";
import MaterialTable from "./MaterialTable";


const StyleCard = styled(Card)`
  //flex: 0 0 35%;
  //height: 100%;
  
  
  @media (max-width: 768px){
    //flex: 0 0 100%;
    width: 100%;
    height: revert;
  }
`
const MaterialCard:React.FC<any> = () => {


    return (
        <StyleCard key={'materialCard'}>
            <MaterialHead/>
            <MaterialTable key={'material-list'} ></MaterialTable>
        </StyleCard>

    );
};

export default MaterialCard;