import styled, {css, keyframes} from "styled-components";
import {BsFillTrashFill, BsFiletypeXlsx, BsInfoCircle} from "react-icons/bs";
import {CiFloppyDisk} from "react-icons/ci";
import {AiOutlineCaretDown, AiOutlineCaretUp, AiOutlineEye, AiOutlineSend, AiFillHome} from "react-icons/ai";
import {FiEdit2, FiSave, FiSettings, FiEyeOff} from 'react-icons/fi'
import {IoMdClose} from 'react-icons/io'
import {FaRegClone, FaFilter} from 'react-icons/fa'
import {BiFileBlank} from 'react-icons/bi'
import {StyledTheme} from "../../styled";

export const StyleDeleteIcon = styled(BsFillTrashFill)`
  cursor: pointer;
  font-size: 16px;
  display: flex;
  font-weight: bold;
  margin-left: 8px;

  &:hover {
    fill: red;
  }
`

interface IIconSave {
    ischangeorder: boolean,

}

const KeyFrameIconsSave = keyframes`
  from {
    fill: coral;
  }

  to {
    fill: grey;
  }
`
export const IconSave = styled(CiFloppyDisk)<IIconSave>`
  font-size: 26px;
  font-weight: bold;
  ${props => props.ischangeorder ? css`
            animation: ${KeyFrameIconsSave} 2s linear infinite;;
          `
          : ''};

  cursor: pointer;
  margin: 0px 8px;
  fill: grey;
`

export const IconPanelOpen = styled(AiOutlineCaretDown)`
  cursor: pointer;
  padding: 5px;
  background: #4262ff;
  border: 1px solid #eee;
  box-shadow: 0 0 5px #eee;
  font-size: 34px;
  fill: white;

`
export const IconPaneClose = styled(AiOutlineCaretUp)`
  cursor: pointer;
  font-size: 34px;
  padding: 5px;
  background: white;
  border: 1px solid #eee;
  box-shadow: 0 0 5px #eee;
  position: absolute;
  right: 0px;
  top: 0;
  fill: #4262ff;
`

export const IconEdit = styled(FiEdit2)`
  stroke: #4262ff;
  font-size: 22px;
  cursor: pointer;
  padding: 4px;
`

export const IconUpdate = styled(FiSave)`
  stroke: green;
  cursor: pointer;
  //padding: 4px;
  font-size: 22px;
`
export const IconClose = styled(IoMdClose)`
  fill: red;
  cursor: pointer;
  //padding: 4px;
  font-size: 22px;
`
export const IconSettings = styled(FiSettings)`
  cursor: pointer;
  font-size: 22px;
`

export const IconEyeOff = styled(FiEyeOff)`
  cursor: pointer;
  font-size: 22px;

  &:hover {
    stroke: ${props => props.theme.colors.theme};
  }
`
export const IconEye = styled(AiOutlineEye)`
  cursor: pointer;
  font-size: 22px;

  &:hover {
    fill: ${props => props.theme.colors.theme};
  }
`
export const IconSend = styled(AiOutlineSend)`
  cursor: pointer;
  font-size: 22px;

  &:hover {
    fill: ${props => props.theme.colors.theme};
  }
`

export const IconClone = styled(FaRegClone)`
  cursor: pointer;
  font-size: 22px;

  &:hover {
    fill: ${props => props.theme.colors.theme};
  }
`

export const IconBlank = styled(BiFileBlank)`
  cursor: pointer;
  font-size: 22px;

  &:hover {
    fill: ${props => props.theme.colors.theme};
  }
`
export const IconInfo = styled(BsInfoCircle)`
  cursor: pointer;
  font-size: 22px;

  &:hover {
    fill: ${props => props.theme.colors.theme};
  }
`

const AnimateIconColors = keyframes`
  to {
    fill: ${StyledTheme.colors.navy_blue};
  }
  from {
    fill: ${StyledTheme.colors.theme};
  }
`
export const IconFilter = styled(FaFilter)<{ isHasFilter?: boolean }>`
  cursor: pointer;
  font-size: 22px;

  &:hover {
    fill: ${props => props.theme.colors.theme};
  }
  ${props=> props.isHasFilter  && css`
  
    animation: ${AnimateIconColors}  1s linear infinite;
  `}

`
export const IconHome = styled(AiFillHome)`
  cursor: pointer;
  font-size: 22px;
  margin: 0px 8px;

  &:hover {
    fill: ${props => props.theme.colors.theme};
  }
 

`

export const IconXlsx = styled(BsFiletypeXlsx)`
  cursor: pointer;
  font-size: 22px;
  fill: #05ac47;
`