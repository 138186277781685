interface IKeyGlobal {
    '@id'?: string,
    '@type'?: string,
    '@@context'?: string,

}

interface IParamsRequest {
    page?: number,
    name?: string,
    search?: string
}

export interface IOrder extends IKeyGlobal {
    id: number,
    furnId: number
    furnCode: string,
    furnLink: string,
    code1c?: string,
    //@params primary key
    externalId?: string,
    createdAt?: string,
    updatedAt?: string,
    price: number,
    name: string,

}

export interface IOrders extends IOrder {
    orderAttributes: IOrderAttribute[]
    member: IMember
    status: IStatus
}

export interface IStatus extends IKeyGlobal {
    id: number,
    name: string
}

export enum ROLES {
    USER = 'ROLE_USER', ADMIN = 'ROLE_ADMIN'
}

// export type IRoles = 'ROLE_USER' | 'ROLE_ADMIN'

export interface IMember extends IKeyGlobal {
    id: number
    name: string
    role: ROLES.USER | ROLES.ADMIN
}

export interface IOrderAttribute extends IKeyGlobal {

    id: number,
    productNumber: number,
    name: string,
    quantity: number,
    material: string,
    texture: boolean
    edgeBottom: string | null,
    edgeLeft: string | null,
    edgeRight: string | null,
    edgeTop: string | null,
    x: number | string,
    y: number | string,
    medias: IMedia[] | null,
    order?: any
}

export interface IOrderAttributeCreate {
    productNumber: number,
    name: string,
    quantity: number,
    material: string,
    texture: boolean
    edgeBottom: string | null,
    edgeLeft: string | null,
    edgeRight: string | null,
    edgeTop: string | null,
    x: number | string,
    y: number | string,
    medias: IMedia[] | string[] | null
}

export interface IPagination {
    page: number,
    total: number
}


export interface IMaterialsParams extends IParamsRequest {

}

export interface IEdge extends IKeyGlobal {
    id: number,
    furnId: number
    externalId: string,
    x?: string,
    y: string,
    z: string,
    price: string
}

export interface IMaterial extends IKeyGlobal {
    '@id': string
    id: number,
    furnId: number
    externalId: string,
    price: number,
    name: string,
    x: string,
    y: string,
    z: string,
    edges?: IEdge[] | [],
    texture: boolean
}

export interface IMedia extends IKeyGlobal {
    '@id': string
    id: number,
    contentUrl: string

}

// Response orders collection
export interface IOrderAttributeCol extends IOrderAttribute {
    order: {
        "@context"?: string,
        "@id": string,
        "@type"?: string,
        orderAttributes: Array<string>
    },

}

export interface IOrderStatus extends IKeyGlobal {
    id: number,
    name: string
}

type TypeOrderAttributeKeys = keyof IOrderAttribute

export interface ParamsUpdateAttribute {
    key: Extract<TypeOrderAttributeKeys, "name" | "texture" | "edgeBottom" | "edgeLeft" | "edgeRight" | "edgeTop" | "x" | "y" | "quantity">
    value: string | boolean | number | null
}

export interface ISetting extends IKeyGlobal {
    id: number
    slug: string,
    name: string,
    value: string
}

export interface IOrderCreate {
    name: string,
    member?: string,
    orderAttributes: IOrderAttribute[] | []
}

export interface IFilterOrders {
    page: number,
    name?: string,
    'order[createdAt]'?: 'asc' | 'desc',
    'order[updateAt]'?: 'asc' | 'desc',
    'createdAt[before]'?: string,
    'createdAt[after]'?: string,
    furnId?: string,
    id?: string,
    furnCode?: string,
  'member'?: string

}

export interface IImportOrderAttribute {
    "@context": "string",
    "@id": "string",
    "@type": "string",
    "id": 0,
    productNumber: number,
    texture: boolean,
    quantity: number,
    name: string,
    x: string | number,
    y: string | number,
    order: {
        "@context": "string",
        "@id": "string",
        "@type": "string",
        orderAttributes: string[]
    },
    material: IMaterial,
    edgeTop: IEdge | null,
    edgeBottom: IEdge | null,
    edgeRight: IEdge | null,
    edgeLeft: IEdge | null
}