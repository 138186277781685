import React, {PropsWithChildren} from 'react';
import styled, {css} from "styled-components";
import Flex from "../../ui/Flex";

interface IItemStyle {
    onClick?: () => void,
    isActive?: boolean

}
const Active = css`
  background-color: blue;
  color: white;
`
const ItemStyle = styled(Flex)<IItemStyle>`
  padding: 8px 10px;
  color: ${props => props.theme.colors.navy_blue};
  font-size: 14px;
  border-bottom: 1px solid ${props => props.theme.colors.border_table};
  ${props => props.isActive ? Active : ''}


`
const Item = ({children, ...props}: PropsWithChildren<IItemStyle>) => {
    return (
        <ItemStyle {...props} direction={'row'} align={'start'} wrap={'no-wrap'}>
            {children}
        </ItemStyle>

    );
};

export default Item;