import React, {FormEvent, useMemo, useState} from 'react';
import {ILogin, IRegistration} from "../../models/authTypes";

import PhoneInput, {CountryData} from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {Field, Input, Label, RequiredFiled} from "../ui/Field";
import Button from "../ui/Button";
import {InputPassword} from "../ui/Field/Input";
import styled from "styled-components";
import Flex from "../ui/Flex";
import {EnumPhoneCount, PHONE_COUNTRY} from "../../consts/phoneCountry";

interface IProps {
    onHandlerSubmit: (data: IRegistration) => void,
    settingsCodeCountry: string
}

const StyledInput = styled(Input)`
  padding: 8px 15px;
`

const FormRegistration = ({onHandlerSubmit, settingsCodeCountry}: IProps) => {
    const [name, setUsername] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const isDisableDropdown = useMemo(()=>{
        if(!settingsCodeCountry){
            return false;
        }
        if(settingsCodeCountry && settingsCodeCountry === EnumPhoneCount.all){
            return  false
        }
        if(settingsCodeCountry && settingsCodeCountry !== EnumPhoneCount.all){
            return  true
        }
    },[settingsCodeCountry])
    return (
        <form onSubmit={(e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            onHandlerSubmit({
                name, email, plainPassword: password, phone: `+${phone}`
            });
        }

        }>
            <Field direction={'column'}>
                <Label>Имя пользователя <RequiredFiled>*</RequiredFiled></Label>
                <StyledInput type={'text'} value={name} onChange={(e) => (setUsername(e.target.value))}/>
            </Field>
            <Field direction={'column'}>
                <Label>Email</Label>
                <StyledInput type={'email'} value={email} onChange={(e) => (setEmail(e.target.value))}/>
            </Field>
            <Field direction={'column'}>
                <Label>Телефон <RequiredFiled>*</RequiredFiled></Label>
                <PhoneInput key={'phone'} value={phone}
                            country={ !!settingsCodeCountry  ?  settingsCodeCountry === EnumPhoneCount.all ? 'ua' : settingsCodeCountry : 'ua'}
                            inputProps={{
                                require: true
                            }}
                            disableDropdown={isDisableDropdown}
                            onlyCountries={ [...PHONE_COUNTRY]}
                            onChange={(value, data: CountryData, _, formatter) => {
                                setPhone(value);
                            }}/>
            </Field>
            <Field direction={'column'}>
                <Label>Пароль <RequiredFiled>*</RequiredFiled></Label>
                <InputPassword required={true} value={password} onChange={(e: any) => (setPassword(e.target.value))}
                               pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                               title={'Должен содержать не менее одной цифры и одной прописной и строчной буквы, а также не менее 8 символов.'}
                />
            </Field>

            <Flex margin={'25px 0px 0px 0px'} justify={'center'}><Button variant={'theme'}
                                                                         disabled={Boolean(!name || !password || (!code && !phone))}>Отправить</Button></Flex>
        </form>
    );
};

export default FormRegistration;