import {AppDispatch} from "../index";
import {CommonActions} from "../slice/CommonSlice";
import {AuthHttp} from "../../api/AuthHttp";
import {ILogin, IRegistration, NamingToken} from "../../models/authTypes";
import {MemberHttp} from "../../api/MemberHttp";
import {fetchingCheckAuthenticationSystem, fetchingCheckAuthToken, fetchingLogoutSystem} from "../slice/MembersSlice";
import {getExpiryDate} from "../../api/settings";
import {ROUTES} from "../../consts/routs";
import {fetchingSettingsNotAuthorized, fetchingSettingsProject} from "./FetchingProjectData";

export const fetchingLogin = (data: ILogin, cb: () => void) => async (dispatch: AppDispatch) => {
    dispatch(CommonActions.toggleIsLoadingSearch(true));
    try {
        const response = await AuthHttp.login(data);
        // let {refresh_token, access_token, expires_in} = response;
        if (response && response.refresh_token && response.access_token) {
            localStorage.setItem(NamingToken.ACCESS_TOKEN, response.access_token);
            localStorage.setItem(NamingToken.REFRESH_TOKEN, response.refresh_token);
            localStorage.setItem(NamingToken.ACCESS_TOKEN_EXPIRES, `${getExpiryDate(response.expires_in)}`);
            // await dispatch(fetchingCheckAuthenticationSystem());
            if (cb) cb()
        }
        dispatch(CommonActions.toggleIsLoadingSearch(false));
    } catch (e) {
        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
        dispatch(CommonActions.toggleIsLoadingSearch(false));
    }
}

export const fetchingRegistrationCredential = () => async (dispatch: AppDispatch) => {
    try {
        const {access_token, expires_in} = await AuthHttp.clientCredential();
        if (access_token && expires_in) {
            localStorage.setItem(NamingToken.CLIENT_TOKEN, access_token);
            localStorage.setItem(NamingToken.CLIENT_TOKEN_EXPIRES, `${getExpiryDate(expires_in)}`);
            dispatch(fetchingSettingsNotAuthorized(1));
            return;
        }

        dispatch(CommonActions.toggleError({
            error: {
                title: 'Произошла ошибка!!! '
            },
            isError: true
        }))


    } catch (e) {
        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
    }

}
export const fetchingRegistration = (data: IRegistration) => async (dispatch: AppDispatch) => {
    dispatch(CommonActions.toggleIsLoadingSearch(true));
    try {
        const response = await MemberHttp.registration(data);
        dispatch(CommonActions.toggleIsLoadingSearch(false));
        window.location.pathname = ROUTES.login;
    } catch (e) {

        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
        dispatch(CommonActions.toggleIsLoadingSearch(false));
    }
}


