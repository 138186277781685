import React from 'react';
import Table from "../ui/Table";
import {StyledTheme} from "../../styled";

interface IProps {
    isAdmin: boolean
}

const OrderTHeader = ({isAdmin}: IProps) => {
    return (
        <Table.Head>
            <Table.TH background={StyledTheme.table_bg.secondary}>№ Заказа</Table.TH>
            {isAdmin && <Table.TH background={StyledTheme.table_bg.secondary}>№ IFurn</Table.TH>}
            {isAdmin && <Table.TH background={StyledTheme.table_bg.secondary}>код IFurn</Table.TH>}
            <Table.TH background={StyledTheme.table_bg.secondary}>код 1c</Table.TH>
            <Table.TH background={StyledTheme.table_bg.secondary}>Имя заказа</Table.TH>
            {isAdmin && <Table.TH background={StyledTheme.table_bg.secondary}>Клиент</Table.TH>}

            <Table.TH background={StyledTheme.table_bg.secondary}>Статус</Table.TH>
            <Table.TH background={StyledTheme.table_bg.secondary}>Дата</Table.TH>
            <Table.TH background={StyledTheme.table_bg.secondary}>Действие</Table.TH>
        </Table.Head>
    );
};

export default OrderTHeader;