enum AuthStatusCode {
    REVORKE = 460, // пользователь авторизирован на другом устройстве - нужно отправить на розлогинивание member/revokre-previos,
    Unauthorized = 401,
    Forbidden = 403,
}

enum NamingToken {
    ACCESS_TOKEN = '_furniture_access_token',
    REFRESH_TOKEN = '_furniture_refresh_token',
    ACCESS_TOKEN_EXPIRES = '_furniture_access_token_expires_in',
    CLIENT_TOKEN = '_furniture_client_token',
    CLIENT_TOKEN_EXPIRES = '_furniture_client_token_expires_in',
}

enum BasicAuthDT {
    CLIENT_ID = '1cb89fb267fefa02b8554ceda8bd3e48',
    CLIENT_SECRET = '3819d734298e108ed48f863c0ca2062422d0342883a42aac869dc04d81cc290ac9c13590dce6fb9a70e952305cae70f2e50bd53151f8f609edd554526e0207cb',
    SCOPE = 'user'
}

enum GrantTypes {
    PASSWORD = 'password',
    REFRESH = 'refresh_token',
    CLIENT = 'client_credentials'
}


interface IAuntification {
    grant_type: GrantTypes.PASSWORD | GrantTypes.REFRESH | GrantTypes.CLIENT,
    client_id: BasicAuthDT.CLIENT_ID,
    client_secret: BasicAuthDT.CLIENT_SECRET,
    scope: BasicAuthDT.SCOPE
}

export interface IClientCredentials extends IAuntification {
    grant_type: GrantTypes.CLIENT
}

export interface IAuth extends IAuntification {
    grant_type: GrantTypes.PASSWORD
    password: string,
    email?: string,
    username?: string
}


export interface IRefresh extends IAuntification {
    grant_type: GrantTypes.REFRESH,
    refresh_token: string | null
}


export interface ILogin {
    username: string, //email or phone
    // email?: string,
    password: string
}

export interface IRegistration {
    phone?: string
    email?: string,
    name: string,
    plainPassword: string

}

//@exports enum
export {GrantTypes, BasicAuthDT, NamingToken, AuthStatusCode}

