import React from 'react';
import {IconSend} from "../ui/Icons";

interface IProps {
    furnId: number,
    hasDetail: boolean,
    onSendHandler: () => void,
    nameService: string
}

const TdOrderSendTo = ({furnId, onSendHandler, hasDetail, nameService}: IProps) => {

    return (
        !furnId && hasDetail ? <IconSend title={'Отправить в ' + nameService} onClick={onSendHandler}/> : <></>

    );
};

export default TdOrderSendTo;