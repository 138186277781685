import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import Flex from "../../ui/Flex";
import Logo from "../Logo";
import Container from "../../ui/Container";
import Logout from "../Logout";
import {IconSettings} from "../../ui/Icons";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {Link, useLocation} from "react-router-dom";
import {ROUTES} from "../../../consts/routs";
import HeaderActionOrder from "./HeaderActionOrder";
import {EnumSettingsDef} from "../../../consts/settingsDef";
import {fetchingLogoutSystem} from "../../../store/slice/MembersSlice";
import {ROLES} from "../../../models/types";

const StyleContainer = styled(Container)`
  padding: 0px;
`
const StyleHeader = styled.header<any>`
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 0px 5px ${props => props.theme.shadow.default};
  padding: 15px;
`

const Header = () => {
    const {entry} = useAppSelector(state => state.projectOrder.settings);
    let logoUrl = entry && entry.find(item => item.slug === EnumSettingsDef.PROJECT_LOGO);
    const dispatch = useAppDispatch();
    let location = useLocation();
    const {member} = useAppSelector(state => state.members);

    const isAdmin = useMemo(() => {
        return !!(member && member.current && member.current.role === ROLES.ADMIN)
    }, [member.current]);
    React.useEffect(() => {

    }, [location]);
    const onLogout = useCallback(() => {
        dispatch(fetchingLogoutSystem())
    }, [dispatch])
    return (
        <StyleHeader>
            <StyleContainer>

                <Flex key={'header-flex'} align={'center'} justify={'start'}>
                    {/*<Flex align={'center'} justify={'space-between'} direction={'row'} wrap={'no-wrap'}>*/}

                        {location.pathname.includes('/order') ? <HeaderActionOrder key={'header-action-order'} logoUrl={logoUrl && logoUrl.value || 'https://api.ifurn.pro/modules/logo/1.svg'}/> :
                            <Logo url={logoUrl && logoUrl.value || 'https://api.ifurn.pro/modules/logo/1.svg'} key={'logo-company'}/>}
                    {/*</Flex>*/}
                    <Flex align={'center'} margin={'0px 0px 0px auto'}>
                        {isAdmin && (
                            <Link to={ROUTES.settings}
                                  style={{
                                      marginRight: '8px',
                                      display: "flex"
                                  }}><IconSettings/></Link>
                        )}
                        <Logout onClick={onLogout}/>
                    </Flex>

                </Flex>
            </StyleContainer>
        </StyleHeader>
    );
};

export default Header;