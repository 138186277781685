import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IMaterial, IMedia, IOrderAttribute, ISetting, IStatus} from "../../../models/types";
import {focusedNexElementDetailing} from "../../../components/Detaling/utils/helpers";
import {IProjectOrderState} from "../../../models/stateTypes";


type createProjectDataPayload = Pick<IProjectOrderState, 'materials' | 'edges' | 'order'>
type PayloadChoiceMaterial = {
    material: IMaterial | null,
}


const ProjectOrderSlice = createSlice({
    name: 'projectOrder',
    initialState: {
        materials: null,
        edges: null,
        order: null,
        choiceMaterial: null,
        settings: {
            entry: null, pagination: {
                page: 1, total: 0
            }
        },
        status: {
            entry: null,
            pagination: {
                page: 1, total: 0
            }
        }
    } as IProjectOrderState,
    reducers: {
        addMaterialAfterImport: (state, action: PayloadAction<IMaterial[] | null>) => {

            if (state.materials && action.payload) {
                let unigMaterial = action.payload.filter(material=> state.materials && !state.materials.find(item=> item["@id"] === material["@id"]))
                state.materials = [...state.materials, ...unigMaterial];
            } else if (!state.materials && action.payload) {
                state.materials = action.payload;
            }
        },
        addMaterial: (state, action: PayloadAction<IMaterial[] | null>) => {
            if (state.materials && action.payload) {
                state.materials = state.materials.concat(action.payload);
            } else if (!state.materials && action.payload) {
                state.materials = action.payload;
            }
        },
        addInOrderDetailing: (state, action: PayloadAction<IOrderAttribute[] | []>) => {
            if (state.order && state.order.orderAttributes) {
                state.order.orderAttributes = state.order.orderAttributes.concat(action.payload);
            } else if (state.order) {
                state.order.orderAttributes = action.payload;
            }
        },
        deleteMaterialAndDetailingInProject: (state, action: PayloadAction<string>) => {
            if (state.materials) {
                state.materials = state.materials.filter((material: IMaterial) => material['@id'] !== action.payload)
            }
            if (state.order && state.order.orderAttributes) {
                state.order.orderAttributes = state.order.orderAttributes.filter((part: IOrderAttribute) => part.material !== action.payload)
            }
            if (state.choiceMaterial && state.choiceMaterial['@id'] === action.payload) {
                state.choiceMaterial = null;
            }
        },
        toggleChoiceMaterial: (state: IProjectOrderState, action: PayloadAction<PayloadChoiceMaterial>) => {
            state.choiceMaterial = action.payload.material;
            if ((state.order && state.order.orderAttributes) && !!action.payload.material) {
                let parts = state.order.orderAttributes.filter(part => {
                    if (part.material === (action.payload.material ? action.payload.material['@id'] : '')) {
                        return part;
                    }
                })
            }
        },

        updateValuesDetailing: (state, action) => {
            if (state.order && state.order.orderAttributes) {
                state.order.orderAttributes = state.order.orderAttributes.map(part => {
                    if (part.productNumber === action.payload.part.productNumber) {
                        return {
                            ...part,
                            [`${action.payload.key}`]: action.payload.value
                        }
                    }
                    return part;
                });
            }
        },
        createDetailing: (state, action) => {
            let material = state.choiceMaterial && state.choiceMaterial['@id'] ? state.choiceMaterial['@id'] : ''
            let detailing: IOrderAttribute = {
                id: -1,
                productNumber: 1,
                edgeTop: '',
                edgeRight: '',
                edgeBottom: '',
                edgeLeft: '',
                medias: [],
                x: 0,
                y: 0,
                material: material,
                texture: state.choiceMaterial && state.choiceMaterial.texture || false,
                quantity: 1,
                name: ''


            }
            if (state.order && state.order.orderAttributes) {
                let idProductNumber = state.order.orderAttributes.map(item => item.productNumber)
                let maxId = idProductNumber.length ? Math.max(...idProductNumber) + 1 : 1;
                state.order.orderAttributes.push({...detailing, productNumber: maxId});
                setTimeout(() => {
                    focusedNexElementDetailing({classN: 'focused', row: action.payload, col: 0})
                }, 100);
            } else {
                console.log('error create detailing');
            }
        },
        deleteDetailing: (state, action: PayloadAction<number>) => {
            if (state.order && state.order.orderAttributes) {
                state.order.orderAttributes = state.order.orderAttributes.filter(part => part.productNumber !== action.payload)
                    .map((part, index) => {
                        return {
                            ...part,
                        }
                    })
            }
        },

        createProjectData: (state: IProjectOrderState, action: PayloadAction<createProjectDataPayload>) => {
            state.materials = action.payload.materials;
            state.order = action.payload.order;
            state.orderServer = action.payload.order;
            state.edges = action.payload.edges;
            if (state.choiceMaterial && state.materials && state.materials.length) {
                let findMaterial = state.materials.find((material: IMaterial) => material['@id'] === (state.choiceMaterial && state.choiceMaterial['@id']));
                if (!findMaterial) {
                    state.choiceMaterial = state.materials[0];
                }
            } else if (state.materials && !state.materials.length) {
                state.choiceMaterial = null;
            }

        },
        setSettings: (state, action: PayloadAction<Pick<IProjectOrderState, 'settings'>>) => {
            state.settings.entry = action.payload.settings.entry;
            state.settings.pagination = action.payload.settings.pagination;
        },
        updateSetting: (state, action) => {
            if (state.settings.entry) {
                state.settings.entry = state.settings.entry.map(item => {
                    if (item['@id'] === action.payload['@id']) {
                        return {
                            ...action.payload
                        }
                    }
                    return item
                })
            }
        },
        deleteSetting: (state, action: PayloadAction<number>) => {
            if (state.settings.entry) {
                state.settings.entry = state.settings.entry.filter(item => item['id'] !== action.payload);
            }
        },
        addSetting: (state, action: PayloadAction<ISetting>) => {
            if (state.settings.entry) {
                state.settings.entry.push(action.payload)
            } else {
                state.settings.entry = [action.payload]
            }
        },
        setStatusProject: (state, action: PayloadAction<{ entry: IStatus[], page: number, total: number }>) => {
            state.status.entry = action.payload.entry;
            state.status.pagination.page = action.payload.page;
            state.status.pagination.total = action.payload.total;

        },
        updateOrderName: (state, action: PayloadAction<string>) => {
            if (state.order) {
                state.order.name = action.payload
            }
        },
        addMediaDetailing: (state, action: PayloadAction<{ productNumber: number, medias: IMedia }>) => {
            if (state.order && state.order.orderAttributes) {
                let updateDetailing = state.order.orderAttributes.map(part => {
                    if (part.productNumber === action.payload.productNumber) {
                        return {
                            ...part,
                            medias: part.medias ? part.medias.concat(action.payload.medias) : [action.payload.medias]
                        }
                    }
                    return part
                });

                state.order.orderAttributes = updateDetailing;
            }
        },
        deleteMediaDetailing: (state, action: PayloadAction<{ productNumber: number, mediaId: string }>) => {
            if (state.order && state.order.orderAttributes) {
                let updateDetailing = state.order.orderAttributes.map(part => {
                    if (part.productNumber === action.payload.productNumber) {
                        return {
                            ...part,
                            medias: part.medias ? part.medias.filter(media => media['@id'] !== action.payload.mediaId) : null
                        }
                    }
                    return {
                        ...part
                    }
                });

                state.order.orderAttributes = updateDetailing;
            }
        }
    },

    extraReducers: (builder) => {
        // builder.addCase(fetchingSaveProject.pending, (state, action)=>{
        //
        // })
    }

})


export const {reducer: projectOrderReducer, actions: ProjectActions} = ProjectOrderSlice