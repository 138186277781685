import React, {useMemo} from 'react';
import {Title} from "../ui/Typography";
import styled from "styled-components";
import Card from "../ui/Card";
import Table from '../ui/Table'
import Flex from "../ui/Flex";
import {StyledTheme} from "../../styled";
import {useAppSelector} from "../../hooks/hooks";
import Empty from "../ui/Empty";
import {IMaterial, IOrderAttribute, ISetting} from "../../models/types";
import {calculateAdditional, calculationProject} from "../../helpers/calculation";
import {EnumSettingsDef, getSettingValueForSlug} from "../../consts/settingsDef";

const StyleCard = styled(Card)`
  flex: 0 1 60%;

  @media (max-width: 991px) {
    flex: 0 0 100%;
    width: 100%;
  }
`
const StyleTableTotal = styled(Table)`
  flex: 0 1 auto;
  width: 100%;

  td {
    white-space: nowrap;
  }

  @media (min-width: 992px) {
    max-width: max-content;
    flex: 0 1 auto;
  }
`
const StyleTable = styled(Table)`
  //flex: 1 0 80%;
`
const StyledlWrap = styled(Flex)`
  flex-direction: column-reverse;

  @media (min-width: 992px) {
    flex-wrap: nowrap;
    flex-direction: row;
  }
`

const CalculationCard = () => {
    const {materials, order, settings} = useAppSelector(state => state.projectOrder);
    let currency = useMemo(() => {
        return getSettingValueForSlug(EnumSettingsDef.CURRENCY, settings.entry)
    }, [settings.entry]);
    const amountProject = useMemo(() => {
        if (materials && order) {
           return  Number((
                calculationProject(materials, order.orderAttributes, 'material', settings.entry).amount +
                calculationProject(materials, order.orderAttributes, 'cutting', settings.entry).amount +
                calculationProject(materials, order.orderAttributes, 'edging', settings.entry).amount +
                calculationProject(materials, order.orderAttributes, 'edge', settings.entry).amount +
                calculationProject(materials, order.orderAttributes, 'cnc', settings.entry).amount +
                calculationProject(materials, order.orderAttributes, 'package', settings.entry).amount


            ).toFixed(2));

        }
        return 0
    }, [materials, order, settings.entry]);

    const aditionalCost = useMemo(()=>{
        if (materials && order) {
            return calculateAdditional(amountProject, order.orderAttributes, settings.entry, materials)
        }
        return 0
    }, [amountProject, order, materials])

    return (
        <StyleCard key={'materialCard'}>
            <Title level={5}><>Калькуляция</>
            </Title>
            {order && order.orderAttributes && order.orderAttributes.length && materials ?
                <StyledlWrap align={'start'}>
                    <StyleTable>
                        <Table.Body>
                            <Table.TR>
                                <Table.TH background={StyledTheme.table_bg.secondary}>Материал</Table.TH>
                                <Table.TH background={StyledTheme.table_bg.secondary}></Table.TH>
                                <Table.TH background={StyledTheme.table_bg.secondary}>Количество</Table.TH>
                                {/*<TableUi.TH background={StyledTheme.table_bg.secondary}>Цена</TableUi.TH>*/}
                                <Table.TH background={StyledTheme.table_bg.secondary}>Сумма</Table.TH>
                            </Table.TR>
                            <Table.TR>
                                <Table.TH>Материал</Table.TH>
                                <Table.TD>м2</Table.TD>
                                <Table.TD>{calculationProject(materials, order.orderAttributes, 'material', settings.entry).calculate}</Table.TD>
                                {/*<TableUi.TD>10</TableUi.TD>*/}
                                <Table.TD>{calculationProject(materials, order.orderAttributes, 'material', settings.entry).amount.toLocaleString()} {currency}</Table.TD>
                            </Table.TR>
                            <Table.TR>
                                <Table.TH>Кромка</Table.TH>
                                <Table.TD>м. пог</Table.TD>
                                <Table.TD>{calculationProject(materials, order.orderAttributes, 'edge', settings.entry).calculate}</Table.TD>
                                {/*<TableUi.TD>10</TableUi.TD>*/}
                                <Table.TD>{calculationProject(materials, order.orderAttributes, 'edge', settings.entry).amount.toLocaleString()} {currency}</Table.TD>
                            </Table.TR>
                            <Table.TR>
                                <Table.TH background={StyledTheme.table_bg.secondary}>Услуги</Table.TH>
                                <Table.TD></Table.TD>
                                <Table.TD></Table.TD>
                                {/*<TableUi.TD></TableUi.TD>*/}
                                <Table.TD></Table.TD>
                            </Table.TR>
                            <Table.TR>
                                <Table.TH>Распил</Table.TH>
                                <Table.TD>м. пог</Table.TD>
                                <Table.TD>{calculationProject(materials, order.orderAttributes, 'cutting', settings.entry).calculate}</Table.TD>
                                {/*<TableUi.TD>10</TableUi.TD>*/}
                                <Table.TD>{calculationProject(materials, order.orderAttributes, 'cutting', settings.entry).amount.toLocaleString()} {currency}</Table.TD>
                            </Table.TR>
                            <Table.TR>
                                <Table.TH>Кромление</Table.TH>
                                <Table.TD>м. пог</Table.TD>
                                <Table.TD>{calculationProject(materials, order.orderAttributes, 'edging', settings.entry).calculate}</Table.TD>
                                {/*<TableUi.TD>10</TableUi.TD>*/}
                                <Table.TD>{calculationProject(materials, order.orderAttributes, 'edging', settings.entry).amount.toLocaleString()} {currency}</Table.TD>
                            </Table.TR>

                            <Table.TR>
                                <Table.TH>ЧПУ</Table.TH>
                                <Table.TD>м2</Table.TD>
                                <Table.TD>{calculationProject(materials, order.orderAttributes, 'cnc', settings.entry).calculate}</Table.TD>
                                {/*<TableUi.TD>10</TableUi.TD>*/}
                                <Table.TD>{calculationProject(materials, order.orderAttributes, 'cnc', settings.entry).amount} {currency}</Table.TD>
                            </Table.TR>

                            <Table.TR>
                                <Table.TH>Упаковка</Table.TH>
                                <Table.TD>м2</Table.TD>
                                <Table.TD>{calculationProject(materials, order.orderAttributes, 'package', settings.entry).calculate}</Table.TD>
                                {/*<TableUi.TD>10</TableUi.TD>*/}
                                <Table.TD>{calculationProject(materials, order.orderAttributes, 'package', settings.entry).amount.toLocaleString()} {currency}</Table.TD>
                            </Table.TR>
                            <Table.TR >
                                <Table.TH background={StyledTheme.table_bg.warning}>Дополнительно</Table.TH>
                                <Table.TD background={StyledTheme.table_bg.warning}></Table.TD>
                                <Table.TD background={StyledTheme.table_bg.warning}>{}</Table.TD>
                                {/*<TableUi.TD>10</TableUi.TD>*/}
                                <Table.TD background={StyledTheme.table_bg.warning}>{aditionalCost.toLocaleString()} {currency}</Table.TD>
                            </Table.TR>

                        </Table.Body>
                    </StyleTable>
                    <StyleTableTotal>
                        <Table.Body>
                            <Table.TR>
                                <Table.TH background={StyledTheme.table_bg.primary} fontWeight={'600'}>ИТОГО</Table.TH>
                            </Table.TR>
                            <Table.TR>
                                <Table.TD fontWeight={'600'}>{
                                    Number((amountProject + aditionalCost).toFixed(2)).toLocaleString()

                                } {currency}</Table.TD>
                            </Table.TR>
                        </Table.Body>
                    </StyleTableTotal>
                </StyledlWrap>
                : <Empty title={'Для отображения калькуляции по проекту, нужно создать деталь'}/>
            }

        </StyleCard>
    );
};

export default CalculationCard;