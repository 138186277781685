import React, {useCallback} from 'react';
import Modal from "../../ui/Modal";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {CommonActions} from "../../../store/slice/CommonSlice";
import UploadForm from "../../Form/UploadForm";
import {fetchingImportDetailing} from "../../../store/service/FetchingProjectData";

const ImportDetailingModal = () => {
    const dispatch = useAppDispatch();
    const {isOpen, maxProductNumber} = useAppSelector(state => state.common.modalImportDetailing);
    const onClose = useCallback((isOpen:boolean)=>{
        dispatch(CommonActions.toggleModalImportDetailing({isOpen, maxProductNumber: 0}))
    }, [dispatch, isOpen]);
    const onSendFile = useCallback( (uploadFile:any)=>{
        let bodyFormData = new FormData();
        bodyFormData.append('file', uploadFile[0]);
       dispatch(fetchingImportDetailing(bodyFormData,maxProductNumber ));
    }, [dispatch]);
    return (
        <Modal onClose={()=>(onClose(false))} open={isOpen} title={'Импорт'}>
            <UploadForm sendFiles={onSendFile}/>
        </Modal>
    );
};

export default ImportDetailingModal;