import React, {useEffect, useState} from 'react';
import useDebounce from "../../../hooks/useDebounce";
import Container from "../../ui/Container";
import {Field, Input, Label, SelectDynamic} from "../../ui/Field";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {fetchingOrders, IPayloadFilterChange} from "../../../store/slice/OrderSlice";
import Button from "../../ui/Button";
import styled from "styled-components";
import {setEnvironmentData} from "worker_threads";
import {IFilterOrders} from "../../../models/types";
import {IconFilter} from "../../ui/Icons";
import Autocomplete from "../../ui/Field/Autocomplete";
import {IParamsGetMembers} from "../../../api/MemberHttp";
import {IMembersPayload} from "../../../store/slice/MembersSlice";

const StyleFilterWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    flex: 0 0 calc(100% );
    max-width: calc(100%);
    margin: 5px auto;
  }
 
  @media (min-width:576px) and (max-width: 767px){
    & > div {
      flex: 0 0 calc(50% - 10px);
      max-width: calc(50% - 10px);
      margin: 5px auto;
    }
    & > div:last-child{
      max-width: 50%;
      flex: 0 0 50%;
      margin: 5px auto 5px 0px;
    }
  }
  @media (min-width: 768px) {
    & > div {
      flex: 0 0 calc(100% / 5 - 8px);
      margin: 5px 4px;
    }
  }

`
const SField = styled(Field)`
  width: 100%;
  max-width: 100%;
`

interface IProps {
    filter: Omit<IFilterOrders, 'page'>,
    onSearch: () => void,
    onChangeValue: ({key, value}: IPayloadFilterChange) => void,
    onReset: () => void,
    isAdmin?: boolean,
    onFetchingMembers: (search: string, page: number) => void,
    members: IMembersPayload
}

const StyledInput = styled.input`
  border: 1px solid ${props => props.theme.colors.grey};
  padding: 5px 5px;
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.colors.navy_blue};
  display: block;
  min-width: auto;
  width: auto;
  border-radius: 0px;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.theme};
  }
`
const OrdersFilter = ({filter, onSearch, onReset, onChangeValue, isAdmin, onFetchingMembers, members}: IProps) => {
    const [term, setTerm] = useState<string>('');

    return (
        <>

            <div className="" style={{background: 'white', padding: '8px 15px', margin: '25px 0px'}}>

                <StyleFilterWrap onKeyPress={(e) => {
                    if (e.key === "Enter" || e.keyCode === 13) {
                        console.log('enter')
                        onSearch();
                    }
                }
                }>
                    <Field direction={'column'}>
                        <Label> № Заказа</Label>
                        <Input width={'100%'} type={'text'} value={filter.id} onChange={(e) => {
                            onChangeValue({key: 'id', value: e.target.value})
                        }}/>

                    </Field>

                    {isAdmin &&  <Field direction={'column'}>
                        <Label>№ iFurn</Label>
                        <Input width={'100%'} value={filter.furnId}
                               onChange={(e) => (onChangeValue({key: 'furnId', value: e.target.value}))}/>

                    </Field>}
                    {isAdmin &&   <Field direction={'column'}>
                        <Label> Код iFurn</Label>
                        <Input width={'100%'} value={filter.furnCode}
                               onChange={(e) => (onChangeValue({key: 'furnCode', value: e.target.value}))}/>
                    </Field>}

                    <Field direction={'column'}>
                        <Label> Имя заказа </Label>
                        <Input width={'100%'} value={filter.name}
                               onChange={(e) => (onChangeValue({key: 'name', value: e.target.value}))}/>

                    </Field>
                    <Field direction={'column'}>
                        <Label>Дата от</Label>
                        <StyledInput id="date-from" type="date" min={'2018-01-01'}
                                     value={filter["createdAt[after]"]} onChange={(e) => {
                            onChangeValue({key: 'createdAt[after]', value: e.target.value});
                        }}/>

                    </Field>
                    <Field direction={'column'}>
                        <Label>Дата до</Label>
                        <StyledInput id="date-to" type="date" min={'2018-01-01'}
                                     value={filter["createdAt[before]"]} onChange={(e) => {
                            onChangeValue({key: 'createdAt[before]', value: e.target.value});
                        }}/>
                    </Field>
                    {isAdmin &&
                        <Field direction={'column'}>
                            <Label>Клиент </Label>
                            <Autocomplete
                                term={term}
                                setTerm={setTerm}
                                onReasetField={() => {
                                    onChangeValue({
                                        key: 'member',
                                        value: ''
                                    });
                                }}
                                onFetchingData={(search) => {
                                    onFetchingMembers(search, 1)
                                }}
                                optionTitle={'name'}
                                options={members.entry || []}
                                onHandlerClick={async (member) => {
                                    await onChangeValue({
                                        key: 'member',
                                        value: member['@id']
                                    });
                                }}
                                value={filter.member || ''}
                                optionValue={'@id'}
                            />

                        </Field>
                    }

                </StyleFilterWrap>
                <Field justify={'end'}>
                    <Button onClick={() => onSearch()} variant={'theme'} margin={'0px 8px'}>Поиск</Button>
                    <Button onClick={() => {
                        onReset();
                        setTerm(' ')
                    }
                    } variant={'danger'}>Очистить</Button>
                </Field>

            </div>

        </>
    );
};

export default OrdersFilter;