import React from 'react';
import Modal from "../ui/Modal";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {IModalDeleteMaterial} from "../../models/stateTypes";
import {CommonActions} from "../../store/slice/CommonSlice";
import Flex from "../ui/Flex";
import Button from "../ui/Button";
import {Title} from "../ui/Typography";
import {ProjectActions} from "../../store/slice/ProjectOrder/ProjectOrderSlice";

const DeleteMaterialModal = () => {
    const dispatch = useAppDispatch();
    const {isOpen, materialId} = useAppSelector(state => state.common.modalDeleteMaterial);
    const onToggleModal = () => {
        dispatch(CommonActions.toggleModalDeleteMaterial({isOpen:false, materialId: null}))
    }
    const onRemoved = () => {
        try {
            if (materialId) {
                dispatch(ProjectActions.deleteMaterialAndDetailingInProject(materialId));
                onToggleModal();
            }
        } catch (e) {
            dispatch(CommonActions.toggleError({
                error: e,
                isError: true
            }))
        }
    }
    return (
        <Modal onClose={() => (onToggleModal())} key={'material-remove-modal'}
               open={isOpen}>

            <Flex direction={'column'} align={'center'} justify={'center'}>
                <Title level={4} key={'remove_material_title'}>Будут удалены все детали, уверены?</Title>
                <Flex direction={'row'} align={'center'} justify={'center'}>
                    <Button margin={'0px 15px 0px 0px'} variant={'danger'} key={'remove-material'}
                            onClick={() => (onRemoved())}>Да</Button>

                    <Button variant={'theme'} key={'not_remove-material'}
                            onClick={() => (onToggleModal())}>Нет</Button>
                </Flex>
            </Flex>
        </Modal>
    );
};

export default DeleteMaterialModal;