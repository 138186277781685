import {createGlobalStyle} from "styled-components";

const StyledGlobal = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #000;
  }

  body {
    background: #F3F4F8;
    overflow-x: hidden;

  }

  h1, h2, h3, h4, h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    margin-bottom: 15px;
    color: #050038;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  td, th {
    border: 1px solid #f0f0f3;
    text-align: left;
    padding: 5px;
    font-size: 14px;
  }

  .react-tel-input .form-control {
    border: 1px solid #CDCCD7 !important;
    border-radius: 0px !important;
    width: 100% !important;
    height: 37px;
  }

  @media screen and (max-width: 992px) {
    .table-response {
      background-color: white;
      padding: 8px;
    }

    .table-response table {
      border: 0;
    }

    .table-response table caption {
      font-size: 1.3em;
    }

    .table-response table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    .table-response table tr {
      background-color: #F3F4F8;
      //border-bottom: 1px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }


    .table-response table td {
      //border-bottom: 1px solid #ddd;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //font-size: .8em;
      text-align: right;
    }

    .table-response table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(title);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px;
    }

    .table-response table td:last-child {
      border-bottom: 0;
    }
  }

  @media screen and (max-width: 576px) {
    td, th {
      border: 1px solid #f0f0f3;
      text-align: left;
      padding: 4px;
      font-size: 12px;
    }
  }
  .table-head-center thead > *{
    text-align: center;
  }
  .table-body-center tbody > *{
    text-align: center;
  }
  .part-wrap-info{
    margin-right: 10px;
  }
`;
const StyledTheme = {
    colors: {
        navy_blue: '#050038',
        black: '#000',
        white: '#fff',
        grey_purple: '#605D81',
        grey: '#CDCCD7',
        bg_grey: '#F3F4F8',
        border: '#D2D2D6',
        theme: '#4262ff',
        border_table: '#f0f0f3',
        danger: '#dc3545'

    },
    table_bg:{
        primary:'#b8daff',
        secondary:'#d6d8db',
        success:'#c3e6cb',
        danger:'#f5c6cb',
        warning:'#ffeeba',
        info:'#bee5eb'
    },
    shadow: {
        default: 'rgba(0, 0, 0, 0.15);'
    },
    media: {
        phone: '(max-width: 576px)',
        table: '(max-width: 992px) and (min-width:576px)',
        desktop: '(max-width: 1200px) and (min-width: 992px)',
        large: '(min-width: 1200px)',
    },
    title: {
        h1: '32px',
        h2: '28px',
        h3: '22px',
        h4: '18px',
        h5: '16px',
    }
}

export {
    StyledTheme, StyledGlobal
}