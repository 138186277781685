import React from 'react';
import styled from "styled-components";

import {AiOutlineCloudUpload} from 'react-icons/ai'
import {Title} from "../Typography";

const InputStyle = styled.input.attrs<IInput>(({multiple, id, required, value}) => ({
    multiple: multiple,
    type: "file",
    required: true, id: id, value: value
}))`
  opacity: 0;
  position: absolute;
  visibility: hidden;
`
const StyleLabel = styled.label.attrs<ILabel>(({htmlFor}) => ({
    htmlFor: htmlFor
}))`
  display: flex;
  align-items: center;
  background: transparent;
  border: 1px solid #d9d8e1;
  border-radius: 3px;
  color: #000;
  cursor: pointer;
  font-weight: 700;
  height: 60px;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  padding: 0px 10px;
`

interface IInput {
    multiple?: boolean,
    required?: boolean,
    id: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    value?: any
}

const StyleIconUpload = styled(AiOutlineCloudUpload)`
  font-size: 45px;
  display: flex;
  align-items: center;
  margin-right: 10px;
`

interface ILabel {
    htmlFor: string
}

interface IFieldUpload extends ILabel, IInput {
    title?: string
}

const FileUpload = ({htmlFor, title = '',  ...rest}: IFieldUpload) => {
    return (
        <>

            <StyleLabel htmlFor={htmlFor}>
                <StyleIconUpload/>
                <Title level={5} margin={'0px'}>{title}</Title>
                <InputStyle {...rest}/>
            </StyleLabel>
        </>
    );
};

export default FileUpload;