import axiosApiInstance from "./settings";
import {IMaterial, IMaterialsParams} from "../models/types";
import {ResponseType} from "./types/types";

interface IResponseData{
    'hydra:member': IMaterial[],
    'hydra:totalItems': number
}
export const MaterialHttp = {
    getAll: ({
                 page,
                 search = ''
             }: IMaterialsParams) => axiosApiInstance.get<IResponseData>(`/materials?page=${page}&itemsPerPage=30&search=${search}`)
        .then((res) => {
            return {
                materials: res.data['hydra:member'],
                pagination:{
                    page: page || 1,
                    total: res.data['hydra:totalItems']
                }

            }
        }).catch(e => Promise.reject(e)),

    getById: (id: number) => axiosApiInstance.get<ResponseType<IMaterial>>(`/materials/${id}`)
        .then(res => res.data).catch(e => Promise.reject(e)),

    removeById: (id: number) => axiosApiInstance.delete<ResponseType<any>>(`/materials/${id}`).then(res => res).catch(e => Promise.reject(e))
}