import React, {PropsWithChildren} from 'react';
import {IconInfo} from "../ui/Icons";
import styled, {css} from "styled-components";

const StyleWrap = styled.div`
  position: absolute;
  right: 3px;
  top: 0px;
  //background: white;
  width: 15px;

  svg {
    font-size: 15px;
  }

  ul {
    background: white;
    box-shadow: 0 0 5px #ccc;
    padding: 8px 8px 8px 25px;
    text-align: left;
    position: absolute;
    left: 0;
    display: none;
    width: 250px;
    z-index: 11;
    transform: translateX(-100%);
  }

  @media (max-width: 992px) {
    ul {
      left: revert;
      right: 0;
      transform: translateX(0%);
    }
  }

  ul li {
    font-size: 12px;
  }

  &:hover ul {
    display: block;
  }
`
const Icons = styled(IconInfo)<IPropsIcon>`
${props => props.color  && css`
  fill: ${props.color};
`}
`
type IPropsIcon={
    color?:string
}
const TooltipInfo = ({children, color='black'}: PropsWithChildren & IPropsIcon) => {
    return (
        <StyleWrap>
            <Icons color={color}/>

            <ul>
                {children ? children : <>
                    <li>ctrl+enter - создание новой детали без ввода кромки</li>
                    <li>перевод между полями Enter</li>
                    <li>замена кромки - пробел</li>
                    <li>вкл/выкл текстуру - пробел</li>
                </>
                }
            </ul>
        </StyleWrap>
    );
};

export default TooltipInfo;