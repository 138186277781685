import React from 'react';
import styled from "styled-components";
import {TbDatabaseOff, TbMoodEmpty} from 'react-icons/tb';
import Flex from "./Flex";
import {Title} from "./Typography";
import {StyledTheme} from "../../styled";


const StyleEmptyWrapper = styled(Flex)`
  width: 100%;
  flex: 0 0 100%;
  padding: 15px 0px;
  
`
const StyledIcon = styled(TbMoodEmpty)`
  font-size: 45px;
  stroke: ${props => props.theme.colors.grey};
`
interface IProps{
    title?:string
}
const Empty = ({title}:IProps) => {
    return (
        <StyleEmptyWrapper justify={'center'} align={'center'} direction={'column'}>
            <StyledIcon/>
            <Title level={5} margin={'5px 0px'} color={StyledTheme.colors.navy_blue}>{title ? title : ''}</Title>
        </StyleEmptyWrapper>
    );
};

export default Empty;