import React from 'react';
import styled, {css} from "styled-components";




const LabelRadio = styled.label.attrs<{htmlFor: string}>(({htmlFor})=>({
    htmlFor:htmlFor
}))`
  padding: 0px 0px 0px 0px;
  margin: 0;
  display: flex;
  align-items: center;
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #CDCCD7;
  overflow: hidden;

  &:after {
    position: absolute;
    background-color: #4262ff;
    border: 7px solid #4262ff;
    border-radius: 50%;
    top: calc(50%);
    left: calc(50%);
    transform: translate(-50%, -50%);

  }
`

const LabelCheckbox = styled.label.attrs<{htmlFor: string}>(({htmlFor})=>({
    htmlFor:htmlFor
}))`
  padding: 0px 0px 0px 0px;
  margin: 0;
  display: flex;
  align-items: center;
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  &:before{
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 0px;
    border: 1px solid #CDCCD7;
    position: absolute;
    top:0;
    left: 0;
  }
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    display: flex;
    left: 0;
    top:0;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    
  }
`
const InputCheckboxStyle = styled.input.attrs<IProps>(({ value, checked, id})=>({
    checked: checked, id: id, value: value
}))<{focused?: boolean}>`
  ${props => props.focused ?
         css `
          position:absolute;
           opacity: 0;
           z-index: -1;
          `
          : `
   display: none;
  
  `}
  &:checked + ${LabelRadio}:after {
    content: '';
  }
  &:checked + ${LabelCheckbox}:after {
    content: '✓';
  }
  &:focus + ${LabelCheckbox}:before {
    border-color: ${props => props.theme.colors.theme};
    //background: red;
  }

`

interface IProps {
    id: string,
    htmlFor:string,
    checked: boolean,
    value?:any,
    onChange?: (e:React.ChangeEvent<HTMLInputElement>) => void,
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
    variant: 'radio' | 'check',
    focused?: boolean
}
const InputCheckbox = ({htmlFor,variant = 'check', checked=false, id, focused = false, ...reset}:IProps) => {
    return<>

        <InputCheckboxStyle checked={checked}  id={id} type={'checkbox'} focused={focused}{...reset}/>
        {variant === 'check'?
            <LabelCheckbox htmlFor={htmlFor}/>
            :
            <LabelRadio htmlFor={htmlFor}/>}

    </>
};
export default InputCheckbox




