import React from 'react';
import {Link} from "react-router-dom";
import styled from "styled-components";

interface ILogo{
    url: string,
    alt?: string,
}

const StyleLink = styled(Link)`
  display: flex;
  align-items: center;
  max-width:100%;
  min-width: 80px;
  width:120px;

  & > img {
    max-width: 100%;
    object-fit: contain;
  }
`


const Logo = ({url, alt = ''}:ILogo) => {
    return (
        <StyleLink to={'/'} key={'logo'}>
            <img src={url} alt={alt}/>
        </StyleLink>
    );
};

export default Logo;