import React, {PropsWithChildren} from 'react';
import styled, {css} from "styled-components";
import {GrClose} from 'react-icons/gr';
import Flex from "../Flex";
interface IAlert {
    type: "warning" | "error" | "success",
    fixedTop: boolean
}
const variantTypesAlert = {
    warning: `background-color: #fffbe6; border-color: #ffe58f`,
    error: `background-color: #fff2f0; border-color: #ffccc7;`,
    success: `background-color: #f6ffed; border-color: #b7eb8f`
}
const FixedTop=css`
  position: fixed;
  top: 20px;
  right: 20px;
  max-width: 100%;
  min-width: 220px;
  box-shadow: 0 0 5px #bcbcbc99;
`
const AlertWrapper = styled.div<IAlert>`
  padding: 8px;
  border: 1px solid;
  ${props => variantTypesAlert[props.type] || ''};
  color: ${props => props.theme.colors.navy_blue};
  font-size: 14px;
  z-index: 99;
  ${props => props.fixedTop ? FixedTop : ''};
  max-height: 400px;
  box-shadow: 0 0 5px #bcbcbc99;
  overflow-y: auto;
  max-width: 300px;
  
`
const SIconClose = styled(GrClose)`
    fill: rgba(0,0,0,.45);
  cursor: pointer;
`
interface IProps extends IAlert{
    onClose?: ()=> void
}
const Alert = ({type, children, onClose, ...rest}:PropsWithChildren<IProps>) => {
    return (
        <AlertWrapper type={type} {...rest}>
            <Flex align={'start'} justify={'end'}>
                {onClose &&  <SIconClose onClick={onClose}/>}
            </Flex>
            {children}
        </AlertWrapper>
    );
};

export default Alert;