import {useState} from "react";
import {useAppDispatch} from "./hooks";
import {CommonActions} from "../store/slice/CommonSlice";

interface returnAlert {
    open: boolean,
    onClose: () => void,
    onOpen: () => void,
}

export default function (): returnAlert {
    const [open, toggleOpen] = useState<boolean>(true);
    const dispatch = useAppDispatch();

    return {
        open,
        onOpen: () => {
            toggleOpen(true)
        },
        onClose: () => {
            dispatch(CommonActions.toggleError({
                error: null,
                isError: false
            }));
            toggleOpen(false);
        }
    }
}