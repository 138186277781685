import React, {useState} from 'react';
import Modal from "../../ui/Modal";
import {Field, Input, Label, SelectDynamic} from "../../ui/Field";
import Button from "../../ui/Button";
import {IOrderCreate} from "../../../models/types";
import {IMembersPayload} from "../../../store/slice/MembersSlice";
import Autocomplete from "../../ui/Field/Autocomplete";
import styled from "styled-components";

interface IProps {
    isOpen: boolean,
    onClose: () => void,
    onSubmit: (data: IOrderCreate) => void,
    isAdmin: boolean,
    members: IMembersPayload,
    onFetchingMembers: (search: string, page: number) => void,
}

const StyledAutocomplete = styled(Autocomplete)`
  & input[type="text"] {
    padding: 12px 25px 12px 12px !important;
  }
`
const CreateOrderModal = ({isOpen, onClose, onSubmit, isAdmin, members,  onFetchingMembers}: IProps) => {
    const [name, setName] = useState<string>('');
    const [member, setMember] = useState<string>('');
    const [term, setTerm] = useState<string>('')


    return (
        <Modal title={''} onClose={onClose} key={'create-order'} open={isOpen} isRevertOverflowBody={true}>
            <Field direction={'column'}>
                <Label>Имя заказа</Label>
                <Input padding={'12px 10px'}
                       onChange={(e) => {
                           setName(e.target.value)
                       }} value={name}/>
            </Field>

            {isAdmin  &&

                <Field direction={'column'}>
                    <Label>Клиент</Label>
                    {/*<SelectDynamic options={members.entry} value={member} onChange={setMember}*/}
                    {/*               onLoadNext={onLoadNext} onLoadPrev={onLoadPrev}*/}
                    {/*               pagination={members.pagination} optionTitle={'name'}*/}
                    {/*               optionValue={'@id'} isLoading={members.isLoading}/>*/}

                    <StyledAutocomplete
                        inputPadding={'12px 15px 12px 10px'}
                        term={term}
                        setTerm={setTerm}
                        onReasetField={() => {
                            setMember('');
                        }}
                        onFetchingData={(search) => {
                            onFetchingMembers(search, 1);
                        }}
                        optionTitle={'name'}
                        options={members.entry || []} onHandlerClick={(member) => {
                        setMember(member['@id']);
                    }}
                        value={member || ''}
                        optionValue={'@id'}
                    />
                </Field>

            }

            <Field justify={'center'}>
                <Button variant={'theme'}
                        disabled={isAdmin ? !Boolean(name && member) : !Boolean(name)}
                        onClick={() => {
                            let data: IOrderCreate = {
                                name: name,
                                orderAttributes: []
                            };
                            if (isAdmin) {
                                data = {
                                    ...data,
                                    member: member
                                }
                            }
                            onSubmit(data)
                        }
                        }
                >Создать</Button>
            </Field>

        </Modal>
    );
};

export default CreateOrderModal;