import React from 'react';
import styled from "styled-components";

type IStyle={
    borderColor?:string,
    width?:string,
    borderRadius?: string,
    minWidth?: string
}

interface ISelectProps extends IStyle{
    children?: React.ReactNode
    value?:string | number,
    placeholder?: string,
    onChange?: (event:React.ChangeEvent<HTMLSelectElement>) => void,
    onKeyPress?:(event: React.KeyboardEvent<HTMLSelectElement>)=>void
    type?: 'text' |'number',
    name?:string,
    ref?: React.RefObject<HTMLSelectElement>
}
const StyleSelect = styled.select<IStyle>`
  border: 1px solid ${props => props.borderColor || props.theme.colors.grey};
  padding: 5px 5px;
  font-size: 14px;
  font-weight: 400;
  color:  ${props => props.theme.colors.navy_blue};
  display: block;
  min-width:  ${({minWidth})=> minWidth || 'auto'};
  width: ${({width})=> width || 'auto'};
  border-radius:  ${({borderRadius})=> borderRadius || '0px'};
  &:focus{
    outline: none;
    border-color:  ${props => props.theme.colors.theme} ;
  }
`
const Select = (props:ISelectProps) => {
    return <StyleSelect {...props}/>;
};

export default Select;