import React, {useCallback} from 'react';
import CreateOrderModal from "../Modal/Orders/CreateOrderModal";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {CommonActions} from "../../store/slice/CommonSlice";
import Flex from "../ui/Flex";
import Button from "../ui/Button";
import {fetchingCreateOrder} from "../../store/service/FetchingOrders";
import {IOrderCreate} from "../../models/types";
import {IParamsGetMembers} from "../../api/MemberHttp";
import {IMembersPayload} from "../../store/slice/MembersSlice";

interface IProps{
    isAdmin:boolean,
    onFetchingMembers: (search: string, page:number) => void,
    members: IMembersPayload
}
const OrderCreate = React.memo(({isAdmin, onFetchingMembers, members}:IProps) => {
    const {isOpen} = useAppSelector(state => state.common.modalCreateOrder);

    const dispatch = useAppDispatch();
    const onToggle = useCallback( (isOpen: boolean) => {
        dispatch(CommonActions.toggleModalCreateOrder(isOpen));
    }, [dispatch]);
    const onSubmit = useCallback(
        (data: IOrderCreate) => {
        dispatch(fetchingCreateOrder(data))
    }, [dispatch])

    // const onGetMembers = useCallback((page: number) => {
    //     onFetchingMembersSearch({page})
    // }, [])

    return (
        <>
            <Flex justify={'end'}>
                <Button variant={'theme'} onClick={() => onToggle(true)}>Создать </Button>
            </Flex>
            <CreateOrderModal isOpen={isOpen} onClose={() => (onToggle(false))} onSubmit={onSubmit} isAdmin={isAdmin} members={members}
                              onFetchingMembers={onFetchingMembers}/>

        </>
    );
}, () => {
    return false;
});

export default OrderCreate;