import React, {useEffect} from 'react';
import Header from "../Header";
import {ErrorBoundary} from "../ErrorBoundary";


const Layout: React.FC<{ children: JSX.Element }> = ({children}) => {

    // if (member.isLoading) {
    //     return <Loader/>
    // }
    // if (member.isError && member.error) {
    //      navigate(ROUTES.login);
    // }
    return (
        <>
            <Header/>
            <ErrorBoundary>
                {children}
            </ErrorBoundary>
        </>
    );
};

export default Layout;