import axiosApiInstance, {BASE_URL} from "./settings";
import {IRegistration, NamingToken} from "../models/authTypes";
import axios from "axios";
import {IMember} from "../models/types";

interface MembersHttpGetAll {
    'hydra:member': IMember[] | null,
    "hydra:totalItems": number


}

export interface IParamsGetMembers{
    page: number,
    search?: string
}
export const MemberHttp = {
    registration: (data: IRegistration) => {
        return axios.post(`${BASE_URL}/members/registration`, {
            ...data,
        }, {
            headers: {
                'Content-Type': 'application/ld+json',
                'Authorization': 'Bearer ' + localStorage.getItem(NamingToken.CLIENT_TOKEN)
            }
        }).then(res => res.data).catch(e => Promise.reject(e.response.data))
    },
    me: () => {
        return axiosApiInstance.get('/member/me').then((res) => {
            return res.data;
        }).catch(e => Promise.reject(e))
    },
    getAll: (param:IParamsGetMembers ) => {
        return axiosApiInstance.get<MembersHttpGetAll>('/members', {
            params: {
                itemsPerPage: 30,
                ...param,
                role: 'ROLE_USER'
            }
        }).then((res) => {
            return {
                entry: res.data['hydra:member'],
                pagination: {
                    page: param.page,
                    total: res.data['hydra:totalItems']
                }
            };
        }).catch(e => Promise.reject(e))
    },
    logout: () => {
        return axios.post('/members/credentials/revoke-all').then(res => {
            return res.data
        }).catch(e => Promise.reject(e))
    },

}