import {ordersHttp} from "../../api/OrdersHttp";
import {CommonActions} from "../slice/CommonSlice";
import {IEdge, IImportOrderAttribute, IMaterial, IOrderAttribute, ISetting} from "../../models/types";
import {MaterialHttp} from "../../api/MaterialHttp";
import axios from "axios";
import {ProjectActions} from "../slice/ProjectOrder/ProjectOrderSlice";
import {EdgesHttp} from "../../api/EdgesHttp";
import {AppDispatch, RootState} from "../index";
import {isCompareVersionProject} from "../slice/ProjectOrder/helpers";
import {OrderAttributeHttp} from "../../api/OrderAttributeHttp";
import {SettingsHttp} from "../../api/SettingsHttp";
import {SettingsDef} from "../../consts/settingsDef";
import settings from "../../api/settings";
import {fetchingStatusGetAll} from "./FetchingStatus";
import {fetchingCheckAuthenticationSystem} from "../slice/MembersSlice";
import {checkImportOfError} from "../../helpers/edges";

let isFetchingProjectData = false;
let isFetchingProjectSaveData = false;
type TFnFindId = (values: string) => number;
const findId: TFnFindId = (values: string) => {
    let str = values.split('/');
    let id: number = +str[str.length - 1];
    return id;
}
export const fetchingProjectData = (projectId: number) => async (dispatch: any) => {
    dispatch(CommonActions.toggleLoader(true));

    try {
        let materialData: IMaterial[] | any = [];
        let edgeData: IEdge[] | any = [];

        if (!isFetchingProjectData) {
            isFetchingProjectData = true;
            const order = await ordersHttp.getById(projectId);

            let materialFind = order.orderAttributes.map((part: IOrderAttribute) => {
                return part.material
            }).filter((material: string) => !!material);

            let edgesFind = order.orderAttributes.map((part: IOrderAttribute) => {
                return [(part.edgeBottom || ''), (part.edgeTop || ''), (part.edgeRight || ''), (part.edgeLeft || '')]
            }).flat(1).filter((edge: string) => !!edge);

            let uniqMaterial = new Set(materialFind);
            let uniqEdges = new Set(edgesFind);
            let requestGetMaterials = Array.from(uniqMaterial).map((materialId: string) => findId(materialId))
                .filter(id => !!id)
                .map(async (id: number) => await MaterialHttp.getById(id))

            let requestGetEdges = Array.from(uniqEdges).map((edgeId: string) => findId(edgeId)).filter(id => !!id)
                .map(async (id: number) => await EdgesHttp.getById(id))


            await axios.all(requestGetMaterials).then((res: any) => {
                materialData = res;
            }).catch(e => {
                console.log(e)
                return Promise.reject(e)
            });
            await axios.all(requestGetEdges).then((res: any) => {
                edgeData = res;
            }).catch(e => {
                console.log(e)
                return Promise.reject(e)
            });
            await dispatch(fetchingSettingsProject(1));
            await dispatch(fetchingStatusGetAll(1));
            await dispatch(fetchingCheckAuthenticationSystem());

            dispatch(ProjectActions.createProjectData({
                order: order,
                materials: materialData,
                edges: edgeData,

            }));

            dispatch(CommonActions.toggleLoader(false));
            isFetchingProjectData = false;
        }

    } catch (e) {

        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
        dispatch(CommonActions.toggleLoader(false));

    }
}

// interface IFetchingSaveProjectAfterCallCb {
//     // isSuccessSave:boolean,
//     cb?: () => void
// }

export const fetchingSaveProjectAfterCallCb = (
    cb?: () => void) => async (dispatch: AppDispatch) => {
    try {
        await dispatch(fetchingSaveProject());
        if (cb) {
            cb()
        }
    } catch (e) {

    }

}
export const fetchingSaveProject = () => async (dispatch: AppDispatch, getState: any) => {
    dispatch(CommonActions.toggleLoader(true))
    try {
        if (!isFetchingProjectSaveData) {
            isFetchingProjectSaveData = false;
            await dispatch(fetchingCheckAuthenticationSystem());
            const projectOrder = getState().projectOrder.order;
            const choiceMaterial = getState().projectOrder.choiceMaterial;
            const serverOrder = getState().projectOrder.orderServer;
            if (!isCompareVersionProject(serverOrder, projectOrder)) {

                const sendUpdatePart = projectOrder.orderAttributes.filter((part: IOrderAttribute) => {
                    let findPartOrderServer = serverOrder.orderAttributes.find((item: IOrderAttribute) => item.id === part.id)
                    let isChangeDetailing = JSON.stringify(part) !== JSON.stringify(findPartOrderServer)
                    return part.id > 0 && isChangeDetailing;
                }).map(async (part: IOrderAttribute) => await OrderAttributeHttp.updateById(part.id, {
                    ...part,
                    edgeBottom: part.edgeBottom || null,
                    edgeLeft: part.edgeLeft || null,
                    edgeRight: part.edgeRight || null,
                    edgeTop: part.edgeTop || null,
                    x: part.x.toString(),
                    y: part.y.toString(),
                    medias: part.medias && part.medias.length ? part.medias.map(media => media['@id']) : []


                }));
                const sendCreatePart = projectOrder.orderAttributes.filter((part: IOrderAttribute) => part.id < 0).map((part: IOrderAttribute) => {
                    let {id, ...detail} = part
                    return {
                        ...detail,
                        x: part.x.toString(),
                        y: part.y.toString(),
                        edgeBottom: part.edgeBottom || null,
                        edgeLeft: part.edgeLeft || null,
                        edgeRight: part.edgeRight || null,
                        edgeTop: part.edgeTop || null,
                        medias: part.medias && part.medias.length ? part.medias.map(media => media['@id']) : []
                    }
                });


                const deleteDetailing = serverOrder.orderAttributes.filter((part: IOrderAttribute) => {
                    return (part.id > 0) && !(projectOrder.orderAttributes.find((item: IOrderAttribute) => item.id === part.id))
                })


                await axios.all(sendUpdatePart).then((res: any) => {
                }).catch(e => {
                    console.log('update', e)
                    return Promise.reject(e)
                });
                if (deleteDetailing) {
                    //::TODO проверить пулл запросов на удаление
                    await axios.all(deleteDetailing.map(async (item: IOrderAttribute) => OrderAttributeHttp.removeById(item.id).catch(e => Promise.reject(e)))).then((res: any) => {
                    }).catch(e => {
                        console.log('catch', e)
                        return Promise.reject(e)
                    });
                }
                if (sendCreatePart.length) {
                    //::TODO Заглушка для названия проекта при отправке на обновление заказа
                    await ordersHttp.updateById(projectOrder.id, {
                        name: projectOrder.name || projectOrder.id + projectOrder.member.name,
                        orderAttributes: sendCreatePart
                    });
                }


                dispatch(fetchingProjectData(projectOrder.id));
                dispatch(CommonActions.toggleChangeProjectState(false))

            } else {
                // alert('project not changed');
            }
            dispatch(CommonActions.toggleLoader(false));
            isFetchingProjectSaveData = false;
        }
    } catch (e) {
        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
        dispatch(CommonActions.toggleLoader(false));
    }
}

export const fetchingSettingsNotAuthorized = (page: number) => async (dispatch: AppDispatch) => {
    dispatch(CommonActions.toggleLoader(true));
    try {
        const settingServer = await SettingsHttp.getNotAuthorized(page);


        dispatch(ProjectActions.setSettings({
            settings: {
                entry: settingServer.settings,
                pagination: settingServer.pagination
            }
        }));
        dispatch(CommonActions.toggleLoader(false));

    } catch (e) {
        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
        dispatch(CommonActions.toggleLoader(false));
    }
}

export const fetchingSettingsProject = (page: number) => async (dispatch: AppDispatch) => {
    dispatch(CommonActions.toggleLoader(true));
    try {
        const settingServer = await SettingsHttp.getAll(1);
        const createSettings = SettingsDef.filter(setting => !settingServer.settings.find(item => item.slug === setting.slug));
        let settingsState: ISetting[] | null = null;

        if (createSettings.length) {
            const requestCreateSettings = createSettings.map(async (settings) => await SettingsHttp.create(settings));
            await axios.all(requestCreateSettings).then(res => {
                if (settingsState) {
                    settingsState.concat(res, settingServer.settings);
                } else {
                    settingsState = [res, settingServer.settings].flat();
                }
            }).catch(e => {
                console.log(e)
            });
        } else {
            settingsState = settingServer.settings
        }

        dispatch(ProjectActions.setSettings({
            settings: {
                entry: settingsState,
                pagination: settingServer.pagination
            }
        }));
        dispatch(CommonActions.toggleLoader(false));

    } catch (e) {
        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
        dispatch(CommonActions.toggleLoader(false));
    }

}


export const fetchingSettingsEdit = (setting: ISetting, cb: any) => async (dispatch: AppDispatch) => {
    dispatch(CommonActions.toggleLoader(true));
    try {
        let {id, ...dataSend} = setting;
        const settingServer = await SettingsHttp.updateById(id, dataSend);
        dispatch(ProjectActions.updateSetting(settingServer));

        dispatch(CommonActions.toggleLoader(false));
        if (cb) cb();

    } catch (e) {
        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
        dispatch(CommonActions.toggleLoader(false));
    }

}
export const fetchingSettingsDelete = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(CommonActions.toggleLoader(true));
    try {
        // let {id, ...dataSend} = setting;
        const settingServer = await SettingsHttp.deleteById(id);
        dispatch(ProjectActions.deleteSetting(id));

        dispatch(CommonActions.toggleLoader(false));


    } catch (e) {
        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
        dispatch(CommonActions.toggleLoader(false));
    }

}
export const fetchingSettingsCreate = (setting: Pick<ISetting, 'slug' | 'value' | 'name'>) => async (dispatch: AppDispatch) => {
    dispatch(CommonActions.toggleLoader(true));
    try {
        // let {id, ...dataSend} = setting;
        const settingServer = await SettingsHttp.create(setting);
        dispatch(ProjectActions.addSetting(settingServer));
        dispatch(CommonActions.toggleLoader(false));
        dispatch(CommonActions.toggleModalCreateSetting(false));


    } catch (e) {
        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
        dispatch(CommonActions.toggleLoader(false));
    }

}

//@params maxProductNumber => return max value productNumber + 1 || 1
//@params data => file binary

export const fetchingImportDetailing = (data: any, maxProductNumber: number) => async (dispatch: AppDispatch, getState: any) => {
    dispatch(CommonActions.toggleLoader(true));
    try {
        let order = getState().projectOrder.order;
        let arrayIds = order && order.orderAttributes ? order.orderAttributes.map((part: IOrderAttribute) => Number(part.productNumber)) : []
        let maxProductNumberStore = (arrayIds && arrayIds.length) ? (Math.max(...arrayIds) + 1) : 1;
        const response = await OrderAttributeHttp.import(data);
        if (response.length) {
            const detailing = response.map((item: IImportOrderAttribute, index: number) => <IOrderAttribute>({
                material: item.material["@id"],
                id: -1,
                name: item.name,
                productNumber: maxProductNumberStore + index,
                quantity: item.quantity,
                x: item.x,
                y: item.y,
                texture: item.texture ? item.texture : false,
                edgeRight: item.edgeRight ? item.edgeRight["@id"] : null,
                edgeLeft: item.edgeLeft ? item.edgeLeft["@id"] : null,
                edgeTop: item.edgeTop ? item.edgeTop["@id"] : null,
                edgeBottom: item.edgeBottom ? item.edgeBottom["@id"] : null,

            }));

            let materials: IMaterial[] = [];

            for (let i = 0; i < response.length; i++) {

                if (materials.find(item => response[i].material['@id'] !== item["@id"])) {
                    materials.push(response[i].material)
                }
                if (materials.length === 0) {
                    materials.push(response[i].material)
                }
            }

            if (checkImportOfError(materials, detailing)) {
                throw{
                    title: 'В проекте неверно заполненные кромки!'
                }

            }
            dispatch(ProjectActions.addMaterialAfterImport(materials));
            dispatch(ProjectActions.addInOrderDetailing(detailing));
        }
        dispatch(CommonActions.toggleLoader(false));
        dispatch(CommonActions.toggleModalImportDetailing({isOpen: false, maxProductNumber: maxProductNumber}));


    } catch (e) {
        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
        dispatch(CommonActions.toggleLoader(false));
    }

}