import axiosApiInstance from "./settings";
import {IMedia} from "../models/types";
import {ResponseType} from "./types/types";

export const MediaHttp = {
    getAll: (page: number) => axiosApiInstance.get<ResponseType<IMedia[]>>(`/media?page=${page}&itemsPerPage=30`)
        .then(res => res.data).catch(e => Promise.reject(e)),
    getById: (id: number) => axiosApiInstance.get<ResponseType<IMedia>>(`/media/${id}`)
        .then(res => res.data).catch(e => Promise.reject(e)),
    create: (data:any) => axiosApiInstance.post<IMedia>(`/media`, data, {
        headers: { "Content-Type": "multipart/form-data" }
    })
        .then(res => res.data).catch(e => Promise.reject(e)),
    removeById: (id: number) => axiosApiInstance.delete<ResponseType<any>>(`/media/${id}`).then(res => res.data).catch(e => Promise.reject(e)),
}