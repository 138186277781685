import React from 'react';
import {Select} from "../../ui/Field";
import {IEdge} from "../../../models/types";

interface IProps {
    value?: string,
    edges: IEdge [] | [] | undefined,
    disabledEdges: IEdge[] | [],
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void,
    onKeyPress: (e: React.KeyboardEvent<HTMLSelectElement>) => void,

}

const EdgingSelect = ({edges, disabledEdges, onChange, onKeyPress, value}: IProps) => {
    return (
        <Select
            width={'100%'}
            minWidth={'max-content'}
            value={value}
            onChange={onChange}
            onKeyPress={onKeyPress}
        >
            <option value={''}>Не кромить</option>
            {edges ? edges.map(edge => {
                let disabled = disabledEdges.find(item=> item['@id'] === edge['@id']);
                return  !!disabled ? <option key={`edge_${edge['@id']}`} disabled={Boolean(!disabled)} value={edge['@id']}>{edge.z}</option>: <></>

            }) : <></>}
        </Select>

    );
};

export default EdgingSelect;