import {IEdge, IMaterial, IOrderAttribute} from "../models/types";


enum EnumEdgeOption {
    minEdge = 0.5
}

export const filteredEdgesActualForMin = (edges: IEdge[] | [] | undefined, part: IOrderAttribute) => {

    if (edges && edges.length) {
        let findAllMinEdges = edges.filter(edge => EnumEdgeOption.minEdge >= Number(edge.z)) || [];
        // if (findAllMinEdges.length) {
        let findAllMaxEdges = edges.filter(edge => EnumEdgeOption.minEdge < Number(edge.z));
        let detailChoiceMaxEdge = findAllMaxEdges.find(edge => (edge['@id'] === part.edgeTop) || (edge['@id'] === part.edgeBottom) || (edge['@id'] === part.edgeLeft) || (edge['@id'] === part.edgeRight))
        if (detailChoiceMaxEdge) {
            return [...findAllMinEdges, detailChoiceMaxEdge]
        } else {
            return edges;
        }
        // } else {
        //     return edges;
        // }

    }
    return []
}

export const filteredEdgesActualChangeSpase = (edges: IEdge[] | [] | undefined, part: IOrderAttribute, side: 'edgeTop' | 'edgeBottom' | 'edgeRight' | 'edgeLeft') => {
    let sideNaming = ['edgeTop', 'edgeBottom', 'edgeRight', 'edgeLeft']
    if (edges && edges.length) {
        let findAllMinEdges = edges.filter(edge => EnumEdgeOption.minEdge >= Number(edge.z)) || [];
        // if (findAllMinEdges.length) {
        let findAllMaxEdges = edges.filter(edge => EnumEdgeOption.minEdge < Number(edge.z));
        let sidesPart = {
            edgeTop: part.edgeTop,
            edgeBottom: part.edgeBottom,
            edgeLeft: part.edgeLeft,
            edgeRight: part.edgeRight
        }
        //@ts-ignore
        let checkEmptyAnotherSide = sideNaming.filter(item => item !== side).filter((side: string) => !!sidesPart[side])
        let detailChoiceMaxEdge = findAllMaxEdges.find(edge =>
            (edge['@id'] === part.edgeTop) ||
            (edge['@id'] === part.edgeBottom) ||
            (edge['@id'] === part.edgeLeft) ||
            (edge['@id'] === part.edgeRight));

        if (detailChoiceMaxEdge && checkEmptyAnotherSide.length) {
            return [...findAllMinEdges, detailChoiceMaxEdge]
        } else {
            return edges;
        }
        // } else {
        //     return edges;
        // }

    }
    return []
}

//@return true - has error
export const checkImportOfError = (materials: IMaterial[], detailing: IOrderAttribute[]) => {
    if (materials && materials.length && detailing && detailing.length) {
        let findsAllMaterialIncludesMinEdge = materials.filter(material => {
            let containEdgesMin = material.edges && material.edges.filter(edge => EnumEdgeOption.minEdge >= Number(edge.z))
            return  material.edges && material.edges.length
        });

        // console.log(findsAllMaterialIncludesMinEdge);
        if (findsAllMaterialIncludesMinEdge.length) {
            let noCorrectInDetailEdge = detailing.filter(part => {
                let material = findsAllMaterialIncludesMinEdge.find(material => material['@id'] === part.material);
                if (material && material.edges) {
                    let {edges} = material;
                    let findAllMaxEdges = edges.filter(edge => EnumEdgeOption.minEdge < Number(edge.z));

                    let partHasMaxEdge = findAllMaxEdges.filter(edge =>
                        (edge['@id'] === part.edgeTop) ||
                        (edge['@id'] === part.edgeBottom) ||
                        (edge['@id'] === part.edgeLeft) ||
                        (edge['@id'] === part.edgeRight));

                    if (partHasMaxEdge.length > 1) return true
                    return false;

                } else {
                    return false;
                }
            });


            if (noCorrectInDetailEdge.length) {
                // console.log('error not found max edge more 1')
                return true;
            }
        } else {
            // console.log('error not found max edge more 1')
            return false;
        }
        // return  false;
    }
    return false;
}