import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "./hooks";
import {fetchingSaveProject} from "../store/service/FetchingProjectData";
import {isCompareVersionProject} from "../store/slice/ProjectOrder/helpers";
import {CommonActions} from "../store/slice/CommonSlice";

const UseCompareProjectState = () => {
    const dispatch = useAppDispatch();
    const {isChangeProjectState} = useAppSelector(state => state.common);
    const {order, orderServer} = useAppSelector(state => state.projectOrder)
    const onSave = ()=>{
        dispatch(fetchingSaveProject());
    };
    useEffect(()=>{
        let interval= setTimeout(function (){
            if(orderServer && order) {
                if (orderServer && order && !isCompareVersionProject(orderServer, order)) {
                    dispatch(CommonActions.toggleChangeProjectState(true))
                }else{
                    dispatch(CommonActions.toggleChangeProjectState(false));
                }
            }
        }, 100);
        return ()=> {
            clearTimeout(interval)
        }
    }, [ order, orderServer])

    return {
        onSave: () => onSave(),
        isChangeProjectState: isChangeProjectState,
    }
};

export default UseCompareProjectState;