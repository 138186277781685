import React, {useCallback, useState} from 'react';
import Container from "../../components/ui/Container";
import Flex from "../../components/ui/Flex";
import {Title} from "../../components/ui/Typography";
import {IconFilter} from "../../components/ui/Icons";
import {returnObjHasValue} from "../../helpers/main";
import OrderCreate from "../../components/Orders/OrderCreate";
import OrdersFilter from "../../components/Orders/OrdersFilter/OrdersFilter";
import {fetchingOrders, IPayloadFilterChange, OrderAction, TypesFilter} from "./OrderSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {IParamsGetMembers} from "../../api/MemberHttp";
import {fetchingGetAllMembers, IMembersPayload} from "./MembersSlice";

interface IProps {
    filters: TypesFilter,
    isAdmin: boolean,
    onFetchingOrder: (page: number, filters:any) => void
}

const OrdersHeader = ({filters, isAdmin,  onFetchingOrder}: IProps) => {
    const dispatch = useAppDispatch();

    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const {members} = useAppSelector(state => state.members);

    const onChangeFilter = useCallback(({key, value}: IPayloadFilterChange) => {
        dispatch(OrderAction.changeValueFilter({key, value}));
    }, [dispatch]);
    const onResetFilter = useCallback( () => {
        dispatch(OrderAction.resetValueFilter());
        dispatch(fetchingOrders({
            page: 1,
            id: '',
            furnId: '',
            furnCode: '',
            name: '',
            "createdAt[after]": '',
            "createdAt[before]": '',
            member:''
        }));
    }, [dispatch]);

    const onFetchingMember = useCallback((params: IParamsGetMembers) => {
        dispatch(fetchingGetAllMembers(params))
    }, [dispatch]);
    const onFetchingSearchMember = useCallback((search: string, page: number) => {
        dispatch(fetchingGetAllMembers({page: page, search: search}))
    }, [dispatch]);

    return (
        <Container margin={'25px auto'} key={'orders-header'}>
            <Flex align={'center'} justify={'space-between'}>
                <Title level={1} margin={'0px'}>Мои заказы</Title>
                <Flex align={'center'}>
                    <IconFilter isHasFilter={Boolean(returnObjHasValue(filters).length)}
                                onClick={() => (setIsOpenFilter(!isOpenFilter))} style={{marginRight: '8px'}}/>
                    <OrderCreate isAdmin={isAdmin} members={members} onFetchingMembers={onFetchingSearchMember}/>

                </Flex>
            </Flex>
            {isOpenFilter &&
                <OrdersFilter filter={filters} key={'filter'} onChangeValue={onChangeFilter}
                              onReset={() => {
                                  onResetFilter()
                              }}
                              onSearch={() => onFetchingOrder(1, filters)}
                    // onSearch={() => onFetchingOrder(pagination.page)}
                              isAdmin={isAdmin}
                              members={members}
                              onFetchingMembers={onFetchingSearchMember}
                />}
        </Container>
    );
};

export default OrdersHeader;