import axiosApiInstance, {BASE_URL} from "./settings";
import * as querystring from "querystring";
import {BasicAuthDT, GrantTypes, IAuth, IClientCredentials, ILogin, IRefresh, NamingToken} from "../models/authTypes";
import axios, {AxiosResponse} from "axios";

const aut = {
    grant_type: 'password',
    client_id: '1cb89fb267fefa02b8554ceda8bd3e48',
    client_secret: '3819d734298e108ed48f863c0ca2062422d0342883a42aac869dc04d81cc290ac9c13590dce6fb9a70e952305cae70f2e50bd53151f8f609edd554526e0207cb',
    scope: 'user',
    password: '123',
    username: 'admin@admin.com'

}
const mockData = {
    grant_type: 'refresh_token',
    client_id: '1cb89fb267fefa02b8554ceda8bd3e48',
    client_secret: '3819d734298e108ed48f863c0ca2062422d0342883a42aac869dc04d81cc290ac9c13590dce6fb9a70e952305cae70f2e50bd53151f8f609edd554526e0207cb',
    scope: 'user',
    refresh_token: localStorage.getItem('_refreshToken')
}
export const AuthHttp = {
    login: (data: ILogin) => {
        let login = {
            grant_type: GrantTypes.PASSWORD,
            client_id: BasicAuthDT.CLIENT_ID,
            scope: BasicAuthDT.SCOPE,
            client_secret: BasicAuthDT.CLIENT_SECRET,
            username: data.username,
            password: data.password,
            // phone: data.phone
            // username: data.username
        } as IAuth;
        return axios.post(`${BASE_URL}/token`, querystring.stringify({...login})).then(res => {
            return res.data
        }).catch(e => {
            return Promise.reject(e.response.data)
        })
    },
    refreshToken: () => {
        let refresh = {
            grant_type: GrantTypes.REFRESH,
            refresh_token: localStorage.getItem(NamingToken.REFRESH_TOKEN) || '',
            client_id: BasicAuthDT.CLIENT_ID,
            scope: BasicAuthDT.SCOPE,
            client_secret: BasicAuthDT.CLIENT_SECRET,
        } as IRefresh
        return axios.post(`${BASE_URL}/token`, querystring.stringify({...refresh})).then(res => {
            return res.data;
        }).catch(e => {
            console.log(e)
            return Promise.reject(e)
        })
    },

    clientCredential: () => {
        let login = {
            grant_type: GrantTypes.CLIENT,
            client_id: BasicAuthDT.CLIENT_ID,
            scope: BasicAuthDT.SCOPE,
            client_secret: BasicAuthDT.CLIENT_SECRET,
        } as IClientCredentials;
        return axios.post(`${BASE_URL}/token`, querystring.stringify({...login})).then(res => {
            return res.data;
        }).catch(e => {
            console.log(e)
            return Promise.reject(e.response.data)
        })
    },


}