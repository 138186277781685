import React from 'react';
import {IconBlank} from "../ui/Icons";
interface IProps{
    furnCode?:string,
    //@ setting LINK_MOBILE_PORTAL
    linkMobilePortal?:string
}


const TdOrderFurnCode = ({furnCode, linkMobilePortal}:IProps) => {
    return (
       furnCode && linkMobilePortal ? <a href={`${linkMobilePortal.slice(-1) === '/' ? linkMobilePortal : linkMobilePortal+'/'}${furnCode}`} target={'_blank'} title={'Бланк заказа'}><IconBlank/></a> : <></>
    );
};

export default TdOrderFurnCode;