import {AppDispatch} from "../index";
import {CommonActions} from "../slice/CommonSlice";
import {IFilterOrders, IOrderCreate, IOrders} from "../../models/types";
import {ordersHttp} from "../../api/OrdersHttp";
import {fetchingOrders} from "../slice/OrderSlice";
import {fetchingSettingsProject} from "./FetchingProjectData";
import {fetchingStatusGetAll} from "./FetchingStatus";
import {ProjectActions} from "../slice/ProjectOrder/ProjectOrderSlice";
import {fetchingCheckAuthenticationSystem, fetchingGetAllMembers} from "../slice/MembersSlice";


export const fetchingCreateOrder = (order: IOrderCreate) => async (dispatch: AppDispatch) => {
    dispatch(CommonActions.toggleIsLoadingSearch(true));
    try {
        const create = await ordersHttp.create(order);
        if (create && create.id) {
            window.location.pathname = `/order/${create.id}`;
        }
        dispatch(CommonActions.toggleModalCreateOrder(false));
        dispatch(CommonActions.toggleIsLoadingSearch(false));
    } catch (e) {
        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
        dispatch(CommonActions.toggleIsLoadingSearch(false));
    }
}

let isFetchingOrdersPage = false
export const fetchingInitialOrders = () => async (dispatch: AppDispatch) => {
    if(!isFetchingOrdersPage) {
        isFetchingOrdersPage = true;
        try {
            await dispatch(fetchingOrders({page: 1}));
            await dispatch(fetchingSettingsProject(1));
            await dispatch(fetchingStatusGetAll(1));
            await dispatch(fetchingCheckAuthenticationSystem());
            // await dispatch(fetchingGetAllMembers({page:1}));
            await dispatch(ProjectActions.toggleChoiceMaterial({material: null}))
        } catch (e) {

            dispatch(CommonActions.toggleError({
                error: e,
                isError: true
            }))
        }
        isFetchingOrdersPage = true;
    }
}
export const fetchingDeleteOrders = (id: number, filter: IFilterOrders) => async (dispatch: AppDispatch, getState: any) => {

    try {

        const removed = await ordersHttp.delete(id);
        dispatch(fetchingOrders({
            ...filter
        }));
    } catch (e) {
        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }));
    }
}
export const fetchingCloneOrders = (order: IOrders, filters: IFilterOrders) => async (dispatch: AppDispatch, getState: any) => {

    try {
        // const {id, orderAttributes, ...restOrder} = order;
        // delete restOrder['@id'];
        // delete restOrder['@type'];
        //@ts-ignore
        // let ordersAttr:IOrderAttribute[] | [] = createObjectOrderAttribute(orderAttributes)
        const create = await ordersHttp.clone(order);

        dispatch(fetchingOrders(filters));
    } catch (e) {
        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
    }
}

export const fetchingPublishOrders = (id: number, filters: IFilterOrders) => async (dispatch: AppDispatch, getState: any) => {

    try {
        await ordersHttp.publish(id);
        dispatch(fetchingOrders(filters));
    } catch (e) {
        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
    }
}

export const fetchingEditOrders = (id: number, data: any, filters: IFilterOrders) => async (dispatch: AppDispatch, getState: any) => {
    try {

        await ordersHttp.updateById(id, data);
        if (filters.page !== 0) {
            dispatch(fetchingOrders(filters));
        }
    } catch (e) {

        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }));
        dispatch(CommonActions.toggleIsLoadingSearch(false));
    }
}

export const fetchingEditOrder = (id: number, data: any) => async (dispatch: AppDispatch, getState: any) => {
    dispatch(CommonActions.toggleIsLoadingSearch(true));

    try {

        let res = await ordersHttp.updateById(id, data);
        dispatch(ProjectActions.updateOrderName(res.name))
        dispatch(CommonActions.toggleIsLoadingSearch(false));

    } catch (e) {

        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
    }
    dispatch(CommonActions.toggleIsLoadingSearch(false));
}

