import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IMember, IPagination} from "../../models/types";
import {AuthHttp} from "../../api/AuthHttp";
import {IParamsGetMembers, MemberHttp} from "../../api/MemberHttp";
import {NamingToken} from "../../models/authTypes";
import {ROUTES} from "../../consts/routs";
import {getExpiryDate} from "../../api/settings";

interface PayloadMember {
    current: IMember | null
    error: any,
    isLoading: boolean,
    isError: boolean,
}

export interface IMembersPayload {
    entry: IMember[] | null,
    pagination: IPagination,
    isLoading: boolean,

}

interface IInitialState {
    member: PayloadMember,
    members: IMembersPayload,
    isAutorized: boolean

}

export const fetchingCheckAuthToken = createAsyncThunk(
    'fetchingCheckAuth',
    async (_, {rejectWithValue}) => {
        try {

            let response = await AuthHttp.refreshToken();
            if (response.access_token && response.refresh_token) {
                window.localStorage.setItem(NamingToken.ACCESS_TOKEN, response.access_token);
                window.localStorage.setItem(NamingToken.REFRESH_TOKEN, response.refresh_token);
                window.localStorage.setItem(NamingToken.ACCESS_TOKEN_EXPIRES, `${getExpiryDate(response.expires_in)}`);
            }
            return true;
        } catch (e) {
           return  rejectWithValue(e)
        }

    }
)
export const fetchingGetAllMembers = createAsyncThunk(
    'fetchingGetAllMembers',
    async (params:IParamsGetMembers, {rejectWithValue}) => {
        try {
            let members =  await MemberHttp.getAll(params);
            return members;
        } catch (e) {

           return  rejectWithValue(e);
        }
    }
)

export const fetchingCheckAuthenticationSystem = createAsyncThunk(
    'fetchingCheckAuntificationSysytem',
    async (_, {rejectWithValue}) => {
        try {
            const member = await MemberHttp.me();
            return member;
        } catch (e) {
           return  rejectWithValue(e);
        }


    }
)
export const fetchingLogoutSystem = createAsyncThunk(
    'fetchingLogoutSystem',
    async (_, {rejectWithValue}) => {
        try {
            debugger;
            // await MemberHttp.logout();
            localStorage.removeItem(NamingToken.REFRESH_TOKEN);
            localStorage.removeItem(NamingToken.ACCESS_TOKEN);
            localStorage.removeItem(NamingToken.ACCESS_TOKEN_EXPIRES);
            window.location.pathname = ROUTES.login;
        } catch (e) {
            localStorage.removeItem(NamingToken.REFRESH_TOKEN);
            localStorage.removeItem(NamingToken.ACCESS_TOKEN);
            localStorage.removeItem(NamingToken.ACCESS_TOKEN_EXPIRES);
            window.location.pathname = ROUTES.login;
            rejectWithValue(e);
        }

    }
)

const MembersSlice = createSlice(
    {
        name: 'members',
        initialState: {
            member: {
                current: null,
                error: null,
                isLoading: false,
                isError: false
            },
            isAutorized: false,
            members: {
                entry: null,
                pagination: {
                    page: 1, total: 1
                }
            }

        } as IInitialState,
        reducers: {
            toggleAutorized: (state, action: PayloadAction<boolean>) => {
                state.isAutorized = action.payload;
            }
        },
        extraReducers: (builder) => {
            builder.addCase(fetchingLogoutSystem.fulfilled, (state, action) => {
                state.member.current = null;
                state.member.error = null;
                state.member.isError = false;
                state.member.isLoading = false;
                state.isAutorized = false
            });
            builder.addCase(fetchingLogoutSystem.rejected, (state, action) => {
                state.member.current = null;
                state.member.error = null;
                state.member.isError = false;
                state.member.isLoading = false;
                state.isAutorized = false;
            });
            builder.addCase(fetchingCheckAuthenticationSystem.pending, (state, action) => {
                state.member.current = null;
                state.member.error = null;
                state.member.isError = false;
                state.member.isLoading = true;

            });
            builder.addCase(fetchingCheckAuthenticationSystem.fulfilled, (state, action) => {
                state.member.current = action.payload;
                state.member.error = null;
                state.member.isError = false;
                state.member.isLoading = false;
                state.isAutorized = true
            });
            builder.addCase(fetchingCheckAuthenticationSystem.rejected, (state, action) => {
                state.member.current = null;
                state.member.error = action.payload;
                state.member.isError = true;
                state.member.isLoading = false;
            });
            builder.addCase(fetchingCheckAuthToken.pending, (state, action) => {
                state.isAutorized = false;
            });

            builder.addCase(fetchingCheckAuthToken.fulfilled, (state, action) => {
                state.isAutorized = true;
            });
            builder.addCase(fetchingCheckAuthToken.rejected, (state, action) => {
                state.isAutorized = false;
            });

            builder.addCase(fetchingGetAllMembers.fulfilled, (state, action) => {
                state.members.isLoading = false
                //@ts-ignore
                state.members.entry = action.payload.entry;
                //@ts-ignore
                state.members.pagination = action.payload.pagination;
            });
            builder.addCase(fetchingGetAllMembers.rejected, (state, action) => {
                state.members.isLoading = false;
                state.members.entry = null;
                state.members.pagination = {
                    page: 1, total: 1
                };
            });
            builder.addCase(fetchingGetAllMembers.pending, (state, action) => {
                state.members.isLoading = true;
                state.members.entry = null;
                state.members.pagination = {
                    page: 1, total: 1
                };
            });
        }
    }
)
export const {reducer: memberReducer, actions: MemberActions} = MembersSlice;