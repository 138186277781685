import React from 'react';
import Table from "../ui/Table";
import Card from "../ui/Card";
import styled from "styled-components";
import {IOrders, IStatus} from "../../models/types";
import OrderTHeader from "./OrderTHeader";
import TdOpenOrder from "./TdOpenOrder";
import TdOrderSendTo from "./TdOrderSendTo";
import Flex from "../ui/Flex";
import TrOrderDelete from "./TrOrderDelete";
import TdOrderClone from "./TdOrderClone";
import TdOrderFurnCode from "./TdOrderFurnCode";
import TdOrderStatus from "./TdOrderStatus";
import OrderEditName from "./OrderEditName";
import {getTransformData} from "../../helpers/main";


const StyleCardOverScroll = styled(Card)`
  overflow-y: auto;;
`
const StyledActionsFlex = styled(Flex)`
  & > * {
    margin: 0px 5px;
  }
`

interface IProps {
    orders: IOrders[],
    onDeleteOrder: (id: number) => void,
    onCloneOrder: (order: IOrders) => void,
    onPublishOrder: (id: number) => void,
    linkMobilePortal?: string,
    onEditOrder: (id: number, status: string) => void,
    onChangeOrderName: (id: number, name: string) => void,
    statusList: IStatus[] | null,
    isAdmin: boolean,
    nameService: string
}

const OrderTable: React.FC<IProps> = ({
                                          orders,
                                          onDeleteOrder,
                                          onCloneOrder,
                                          onPublishOrder,
                                          linkMobilePortal,
                                          onEditOrder, statusList,
                                          onChangeOrderName, isAdmin, nameService
                                      }) => {


        return (
            <Card>
                <div className="table-response  table-head-center  table-body-center">
                    <Table>
                        <OrderTHeader key={'orders-table-header'} isAdmin={isAdmin}/>
                        <Table.Body>

                            {orders.length ? orders.map<React.ReactNode>(order => {
                                return (<Table.TR key={'order-table-tr'+order.id}>
                                    <Table.TD style={{textAlign: 'center'}} title={'№ Заказа'}>{order.id}</Table.TD>
                                    {isAdmin &&  <Table.TD style={{textAlign: 'center'}} title={'№ IFurn'}>{order.furnId}</Table.TD>}
                                    {isAdmin &&   <Table.TD style={{textAlign: 'center'}} title={'код IFurn'}>{order.furnCode}</Table.TD>}
                                    <Table.TD style={{textAlign: 'center'}} title={'код 1c'}>{order.code1c}</Table.TD>
                                    <Table.TD style={{textAlign: 'center'}} title={'Имя заказа'}>
                                            {order.furnCode ?
                                                <>{order.name}</>
                                                :
                                                <OrderEditName onChangeOrderName={onChangeOrderName} id={order.id}
                                                               name={order.name}/>
                                            }
                                    </Table.TD>
                                    {isAdmin &&    <Table.TD style={{textAlign: 'center'}} title={'Клиент'}>{order.member.name}</Table.TD>}

                                    <Table.TD style={{textAlign: 'center'}} title={'Статус'}><>
                                        {isAdmin ?
                                            <TdOrderStatus statusList={statusList} onChangeStatus={onEditOrder}
                                                           nameService={nameService}
                                                           order={order}/>
                                            :
                                            <>{order.status.name}</>
                                        }
                                    </>
                                    </Table.TD>
                                    <Table.TD style={{textAlign: 'center'}} title={'Дата'}>
                                        <Flex justify={'center'} align={'center'} direction={'column'}>
                                        <span
                                            style={{fontSize: '14px'}}>{order.createdAt && getTransformData(order.createdAt).time}</span>
                                            <span
                                                style={{fontSize: '14px'}}>{order.createdAt && getTransformData(order.createdAt).data}</span>
                                        </Flex>
                                    </Table.TD>
                                    <Table.TD style={{textAlign: 'center'}} title={'Действие'}>
                                        <StyledActionsFlex align={'center'} justify={'center'}>
                                            <TdOpenOrder id={order.id} furnId={order.furnId} furnLink={order.furnLink}/>
                                            <TdOrderClone onCloneOrder={() => onCloneOrder(order)} furnId={order.furnId}
                                                          hasDetail={Boolean(order.orderAttributes.length)}/>
                                            <TdOrderSendTo furnId={order.furnId}
                                                           nameService={nameService}
                                                           hasDetail={Boolean(order.orderAttributes.length)}
                                                           onSendHandler={() => (onPublishOrder(order.id))}/>
                                            <TdOrderFurnCode furnCode={order.furnCode} linkMobilePortal={linkMobilePortal}/>
                                            <TrOrderDelete furnId={order.furnId} onDelete={() => (onDeleteOrder(order.id))}
                                                           key={'remove-order' + order.id}/>

                                        </StyledActionsFlex>
                                    </Table.TD>
                                </Table.TR>)
                            }) : <Table.Empty/>}

                        </Table.Body>
                    </Table>
                </div>
            </Card>
        );
    }
;

export default OrderTable;