import React, {useRef, useState} from 'react';
import {IOrders, IStatus} from "../../models/types";
import {useOnClickOutside} from "../../hooks/useOutsideClick";
import {IconClose, IconEdit} from "../ui/Icons";
import Flex from "../ui/Flex";
import {Input, Select} from "../ui/Field";
import styled, {css} from "styled-components";


interface IProps {

    onChangeOrderName: (id: number, name: string) => void,
    id: number,
    name: string,
    isHead?: boolean
}

const StyledTooltip = styled.div<{ isHead: boolean }>`

  position: absolute;
  top: 0px;
  max-width: 220px;
  width: max-content;
  //width: 280px;
  background: white;
  box-shadow: 0 0 5px #ccc;
  padding: 8px;
  ${props => props.isHead ? css`left: 0` : css`right: 0`};
  z-index: 9;
`
const StyledFlex = styled(Flex)<{ isHead: boolean }>`
  position: relative;
  margin: 0px 5px;
  
`
const StyleText = styled(Flex)<{isHead:boolean}>`
  font-size: 14px;
  ${props => props.isHead ?css`

    border-bottom: 1px solid #ccc;
    padding: 5px;

  ` : ''}
`
//::TODO редакторовать статус может только Админ
const OrderEditName = ({onChangeOrderName, id, name, isHead=false}: IProps) => {
    const ref = useRef<any>();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(name);
    const onHandlerOutside = () => {
        if ((value !== name) && value) {
            onChangeOrderName(
                id, value
            );
        }
        setOpen(false)

    }

    useOnClickOutside(ref, onHandlerOutside);


    return (

        <>
            <StyledFlex align={'center'} isHead={isHead} justify={'center'}>
                <StyleText align={'center'} isHead={isHead}>{name} <IconEdit
                    onClick={() => setOpen(true)}/></StyleText>

                {open && <StyledTooltip ref={ref} isHead={isHead}>
                    <Flex justify={'end'} margin={'0px 0px 8px 0px'}>
                        <IconClose onClick={() => setOpen(false)}/>
                    </Flex>
                    <Input value={value} onChange={(e) => setValue(e.target.value)}/>
                </StyledTooltip>}
            </StyledFlex>

        </>

    );
};

export default OrderEditName;