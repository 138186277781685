import React from 'react';
import styled from "styled-components";
import {FiLogOut} from "react-icons/fi";

interface IStyleProps {

}

const StyleLogout = styled(FiLogOut)`
  font-size: 18px;
  cursor: pointer;

  &:hover {
    stroke: ${props => props.theme.colors.theme};
  }

`

interface IProps extends IStyleProps {
    onClick: () => void
}

const Logout = ({onClick, ...reset}: IProps) => {
    return (
        <StyleLogout onClick={() => onClick()} {...reset}/>

    );
};

export default Logout;