import React, {useState} from 'react';
import Flex from "../ui/Flex";
import {Title} from "../ui/Typography";
import Button from "../ui/Button";
import {AiOutlineSearch} from "react-icons/ai";
import Modal from "../ui/Modal";
import styled from "styled-components";
import SearchMaterialModal from "../Modal/SearchMaterial/SearchMaterialModal";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {CommonActions} from "../../store/slice/CommonSlice";

const StyleSearchIcon = styled(AiOutlineSearch)`
  font-size: 24px;
  cursor: pointer;

  &:hover {
    fill: ${props => props.theme.colors.theme};
  }
`
const WrapFlex = styled(Flex)`

`
const MaterialHead = () => {
    const dispatch = useAppDispatch();
    const onOpenModalSearch = () => dispatch(CommonActions.toggleIsOpenModalMaterialSearch(true))
    return (
        <>
            <WrapFlex key={'materialCardFlex'} direction={'row'} align={'center'} margin={'0px 0px 15px 0px'}
                      justify={'start'}>
                <Title level={5} margin={'0px'}><>Материал</>
                </Title>
                <Button key={'openModalSearchMaterial'} onClick={() => onOpenModalSearch()} padding={'0px'}
                        margin={'0px 0px 0px 8px'}><StyleSearchIcon onClick={() => onOpenModalSearch()}/></Button>
            </WrapFlex>
            <SearchMaterialModal/>
        </>

    )
};

export default MaterialHead;