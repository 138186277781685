import React, {useState} from 'react';
import Modal from "../../ui/Modal";
import styled from "styled-components";
import Flex from "../../ui/Flex";
import Button from "../../ui/Button";
import {ISetting} from "../../../models/types";
import {Field, Label} from "../../ui/Field";



const Input = styled.input`
  width: 100%;
  display: block;
  border: 1px solid ${props => props.theme.colors.grey};
  padding: ${props => '10px 10px'};
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.colors.navy_blue};
  &:focus{
    
  }
`

interface IProps {
    isOpen: boolean,
    onClose: () => void,
    onSubmit: (data: Pick<ISetting, 'name' | 'slug' | 'value'>) => void
}

const CreateSettingModal = ({isOpen, onClose, onSubmit}: IProps) => {
    const [name, setName] = useState('')
    const [slug, setSlug] = useState('')
    const [value, setValue] = useState('')
    return (
        <Modal title={'Создать'} onClose={() => {
            onClose()
        }} open={isOpen}>
            <Field>
                <Label>Символ <small style={{fontSize:'13px'}}>(используйте только латинские буквы, без пробелов)</small></Label>
                <Input
                    required={true} onChange={(e) => {
                    if (/[a-zA-Z]/.test(e.target.value)) {
                        setSlug(e.target.value)
                    } else {
                        setSlug('');
                    }

                }} value={slug}/>
            </Field>
            <Field>
                <Label>Параметр</Label>
                <Input required={true} onChange={(e) => {
                    setName(e.target.value)
                }} value={name}/>
            </Field>
            <Field>
                <Label>Значение</Label>
                <Input required={true} onChange={(e) => {
                    setValue(e.target.value)
                }} value={value}/>
            </Field>
            <Field justify={'center'}>
                <Button variant={'theme'}
                        disabled={!Boolean(name && slug && value)}
                        onClick={() => (onSubmit({name, value, slug}))}
                >Сохранить</Button>
            </Field>

        </Modal>
    );
};

export default CreateSettingModal;