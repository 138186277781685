import {IOrderAttribute} from "../models/types";

export const returnObjHasValue = (obj: any) => {
    return Object.keys(obj).filter(key => !!obj[key])
}

export const randomNumberRange = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min) + min);
}

export const createObjectOrderAttribute = (orders: IOrderAttribute[]) => {
    return orders.map(item => {

        let {id, order, medias, ...rest} = item;
        let actualKey = Object.keys(item).filter(key => ((key !== '@id')))
        let orderItem = {};
        for (const [key, value] of Object.entries(rest)) {
            if (actualKey.find(i => key === i)) {
                orderItem = {
                    ...orderItem,
                    [key]: value
                }
            }
        }
        if (medias && Array.isArray(medias) && medias.length) {
            orderItem = {
                ...orderItem,
                // medias: medias.map(item => item['@id'])
            }
        }


        return {
            ...orderItem,
            x: item.x.toString(),
            y: item.y.toString()
        }
    })
}

export const getTransformData = (data:string)=>{
    const date = new Date('2023-03-08T10:09:28+00:00');
    const [month, day, year] = [
        date.getMonth(),
        date.getDate(),
        date.getFullYear(),
    ];
    const [hour, minutes, seconds] = [
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
    ];

    return {
        time: `${+hour >= 10 ? hour : '0'+hour}:${+minutes >= 10 ? minutes : '0'+minutes}:${seconds}`,
        data: `${+day >= 10 ? day : '0'+day}/${(+month+1) >=10 ? +month+1 : '0'+(+month+1)}/${year}`
    }
}