import React from 'react';
import Container from "../components/ui/Container";
import Flex from "../components/ui/Flex";
import notFoundSvg from '../../src/assets/images/not_found.svg'
import styled from "styled-components";
import {Link} from "react-router-dom";

const Image = styled.img`
  max-width: 100%;
  max-height: 550px;
`
const NotFound = () => {
    return (
        <Container>
            <Flex align={'center'} justify={'center'} direction={'column'}>
                <Image src={notFoundSvg}/>
                {/*<Link to={'/orders'}>Мои заказы</Link>*/}
            </Flex>

        </Container>
    );
};

export default NotFound;