import React, {useEffect, useMemo} from 'react';
import OrderAcceptance from "./pages/OrderAcceptance";
import {Route, Routes} from "react-router-dom";
import {ROUTES} from "./consts/routs";
import Layout from "./components/common/Layouts/Layout";
import OrdersPage from "./pages/Orders/OrdersPage";
import NotFound from "./pages/NotFound";
import SettingsPage from "./pages/Settings/SettingsPage";
import LoginPage from "./pages/Login/LoginPage";
import RegistrationPage from "./pages/Registration/RegistrationPage";
import {useAppSelector} from "./hooks/hooks";
import {ROLES} from "./models/types";

function App() {

    const {member} = useAppSelector(state => state.members);

    const isAdmin = useMemo(() => {
        return !!(member && member.current && member.current.role === ROLES.ADMIN)
    }, [member.current]);


    return (
        <>
            <Routes>


                <Route key={'route-order'} path={ROUTES.orders} element={
                    <Layout><OrdersPage/></Layout>
                }></Route>
                <Route key={'router-acceptance'} path={`${ROUTES.order_acceptance}/:id`}
                       element={
                           <Layout><OrderAcceptance/></Layout>
                       }/>
                {isAdmin && <Route key={'router-settings'} path={`${ROUTES.settings}`} element={
                    <Layout><SettingsPage/></Layout>
                }/>}


                <Route key={'login'} path={ROUTES.login} element={<LoginPage/>}/>
                <Route key={'registration'} path={ROUTES.registration} element={<RegistrationPage/>}/>
                <Route key={'rout-not_found'} path={'*'} element={<NotFound/>}/>
            </Routes>
        </>
    )
        ;
}

export default App;
