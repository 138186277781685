import React from 'react';
import Table from "../ui/Table";
import {IMaterial} from "../../models/types";
import {StyledTheme} from "../../styled";
import Flex from "../ui/Flex";
import InputCheckbox from "../ui/Field/InputCheckbox";
import {StyleDeleteIcon} from "../ui/Icons";


type  IMaterialDt = Pick<IMaterial, "id" | "name" | "price">

interface IProps extends IMaterialDt {
    onChoiceMaterial: () => void,
    materialId: string
    checked: boolean,
    deleteMaterial: ()=> void,
    amount: number,
    currency: string
}

const MaterialItem = ({id, price, amount,name, materialId, onChoiceMaterial, deleteMaterial, checked, currency}: IProps) => {
    return <Table.TR key={`material_list_${materialId}`} color={StyledTheme.colors.navy_blue}
                     background={StyledTheme.table_bg.warning}>
        <Table.TD title={'#'} key={'material_list_id'}> {id}</Table.TD>
        <Table.TD title={'Материал'} key={'material_list_name'}>{name} </Table.TD>
        <Table.TD style={{whiteSpace:'nowrap'}} title={'Сумма'} key={'material_list_price'}>{amount.toLocaleString()} {currency}</Table.TD>
        <Table.TD title={'Действия'} key={'material_list_actions'}>
            <Flex align={'center'} wrap={'no-wrap'}>
                <InputCheckbox variant={'radio'} key={`checkbox_${materialId}`}
                               id={materialId || ''}
                               onChange={(e) => onChoiceMaterial()}
                               value={materialId || ''}
                               htmlFor={materialId || ''}
                               checked={checked}/>
                <StyleDeleteIcon title={'Удалить материал'} onClick={()=> deleteMaterial()}/>
            </Flex>
        </Table.TD>
    </Table.TR>

};

export default MaterialItem;