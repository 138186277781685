import React from 'react';
import styled from "styled-components";
import {retry} from "@reduxjs/toolkit/query";

interface IStyledTitle{
    level: number,
    margin?: string,
    color?: string,
    fontWeight?: string,
    children?: React.ReactNode,


}
const StyledTitleH1 = styled.h1<IStyledTitle>`
    font-size: ${props => props.theme.title.h1};
  margin: ${props => props.margin || '0px 0px 15px 0px'};
  color: ${props => props.color || props.theme.colors.navy_blue} ;
  font-weight:  ${props => props.fontWeight || '500'};
    @media (max-width: 992px){
      font-size: 26px;
    }
`
const StyledTitleH2 = styled.h2<IStyledTitle>`
  font-size: ${props => props.theme.title.h2};
  margin: ${props => props.margin || '0px 0px 15px 0px'};
  color: ${props => props.color || props.theme.colors.navy_blue} ;
  font-weight:  ${props => props.fontWeight || '500'};
  @media (max-width: 992px){
    font-size: 22px;
  }
`
const StyledTitleH3 = styled.h3<IStyledTitle>`
  font-size: ${props => props.theme.title.h3};
  margin: ${props => props.margin || '0px 0px 15px 0px'};
  color: ${props => props.color || props.theme.colors.navy_blue} ;
  font-weight:  ${props => props.fontWeight || '500'};
  @media (max-width: 992px){
    font-size: 18px;
  }
`
const StyledTitleH4 = styled.h4<IStyledTitle>`
  font-size: ${props => props.theme.title.h4};
  margin: ${props => props.margin || '0px 0px 15px 0px'};
  color: ${props => props.color || props.theme.colors.navy_blue} ;
  font-weight:  ${props => props.fontWeight || '500'};
  @media (max-width: 992px){
    font-size: 16px;
  }
`
const StyledTitleH5 = styled.h5<IStyledTitle>`
  font-size: ${props => props.theme.title.h5};
  margin: ${props => props.margin || '0px 0px 15px 0px'};
  color: ${props => props.color || props.theme.colors.navy_blue} ;
  font-weight:  ${props => props.fontWeight || '500'};
  @media (max-width: 992px){
    font-size: 14px;
  }
`
const Title= (props:IStyledTitle) => {
    if(Number(props.level) === 1){
        return <StyledTitleH1 {...props}/>
    }
    if(Number(props.level) === 2){
        return <StyledTitleH2 {...props}/>
    }
    if(Number(props.level) === 3){
       return <StyledTitleH3 {...props}/>
    }
    if(Number(props.level) === 4){
        return <StyledTitleH4 {...props}/>
    }
    if(Number(props.level) === 5){
        return <StyledTitleH5 {...props}/>
    }

    return (<></>)
};

export default Title;