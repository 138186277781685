import React, {PropsWithChildren} from 'react';
import styled from "styled-components";

interface IStyledText {
    fontSize?: string,
    fontWeight?: string,
    margin?: string,
    color?: string
}

const StyleText = styled.p<IStyledText>`
  font-weight: ${props => props.fontWeight || '300'};
  font-size:  ${props => props.fontSize || '16px'};
  color:  ${props => props.color || props.theme.colors.black};
  margin: ${props => props.margin || '0px'};
`

const Text = ({children, ...props}:PropsWithChildren<IStyledText>) => {
    return <StyleText{...props}>{children}</StyleText>;
};

export default Text;