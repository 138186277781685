
interface IFocusedNextElementDetailing {
    classN: string,
    row: number,
    col: number,
    cb?: () => void
}

export const focusedNexElementDetailing = ({classN, row, col, cb}: IFocusedNextElementDetailing) => {
    let current = document.querySelector<HTMLElement>(`.${classN}_${row}_${col}`);
    let next = document.querySelector<HTMLElement>(`.${classN}_${row}_${col + 1}`);
    if (next) {
        let findElement = next.querySelector<HTMLInputElement | HTMLSelectElement>('select, input');
        if (findElement) {
            findElement.focus()
        }

    }
    if(!next && cb){
        cb();
    }
}

