import React from 'react';
import styled from "styled-components";
interface IStyleContainer{
    padding?: string,
    margin?: string,
    background?: string,
    children?:React.ReactNode
}
const StyleContainer = styled.div<IStyleContainer>`
  padding: ${props => props.padding || '0px 15px'};
  background: ${props => props.background || 'transparent'};
  max-width:100%;
  margin:${props => props.margin || '0 auto'}; 
  @media ${props => props.theme.media.large} {
    max-width: 1440px;
  }
  
`
const Container= (props:IStyleContainer) => {
    return <StyleContainer {...props}/>;
};

export default Container;