import axiosApiInstance from "./settings";
import {AxiosResponse} from "axios";
import {IFilterOrders, IOrderCreate, IOrders} from "../models/types";
import {IError} from "./types/types";


interface OrdersHttpGetAll {
    'hydra:member': IOrders[],
    "hydra:totalItems": number


}

interface IGetById extends IOrders {

}


export const ordersHttp = {
    getAll: (paramsFilter: IFilterOrders) => axiosApiInstance.get<OrdersHttpGetAll>(`/orders`, {
        params: {
            itemsPerPage: 30,
            ...paramsFilter,

        }
    })
        .then((res: AxiosResponse<OrdersHttpGetAll>) => {
            return {
                pagination: {
                    page: paramsFilter.page,
                    total: res.data["hydra:totalItems"],
                },
                orders: res.data["hydra:member"],
            }
        }).catch((error: IError) => {
            return Promise.reject(error)
        }),
    getById: (id: number) => axiosApiInstance.get<IGetById>(`/orders/${id}`).then((res: AxiosResponse<IGetById>) => {
        return res.data
    }).catch((error) => Promise.reject(error)),

    updateById: (id: number, order: any) => axiosApiInstance.patch(`/orders/${id}`, order,
        {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            }
        }
    ).then(res => res.data).catch(e => Promise.reject(e)),
    create: (data: IOrderCreate) => axiosApiInstance.post(`/orders`, data, {
        headers: {
            'Content-Type': 'application/ld+json',
        }
    }).then(res => {
        return res.data
    }).catch(e => Promise.reject(e)),
    delete: (id: number) => axiosApiInstance.delete(`/orders/${id}`).then(res => {
        return res.status
    }).catch(e => Promise.reject(e)),
    publish: (id: number) => axiosApiInstance.put(`/orders/${id}/publish`).then(res => res.data).catch(e => {
        return Promise.reject(e)
    }),
    clone: (order:IOrders)=> {
        const {id, ...data} = order;
       return  axiosApiInstance.post(`/orders/${id}/copy`, data, {
            headers: {
                'Content-Type': 'application/ld+json',
            }
        }).then(res => {
            return res.data
        }).catch(e => Promise.reject(e))
    }
}