import React from 'react';
import {EnumPhoneCount, PHONE_COUNTRY, AliasCountry} from "../../consts/phoneCountry";
interface IProps{
    value: string ,
    setValue : (value:string) =>void
}
const EditCountryCodePhone = ({value, setValue}:IProps) => {
    return (
        <select value={value} onChange={(e)=> setValue(e.target.value)} key={'select_code_country'}>
            <option value={EnumPhoneCount['all'].toString()}> По умолчанию </option>

            {PHONE_COUNTRY.map((code:AliasCountry)=> {
                //@ts-ignore
              return   EnumPhoneCount[`${code}`] && <option key={code} value={EnumPhoneCount[`${code}`].toString()} disabled={Boolean(value === code)}>{code}</option>
            })}
        </select>
    );
};

export default EditCountryCodePhone;