import axiosApiInstance from "./settings";
import {IImportOrderAttribute, IOrderAttributeCol} from "../models/types";
import {ResponseType} from "./types/types";

interface IImport {
    'hydra:member': IImportOrderAttribute[],
    "hydra:totalItems": number


}

export const OrderAttributeHttp = {
    getAll: (page: number) => axiosApiInstance.get<ResponseType<IOrderAttributeCol[]>>(`/order-attributes?page=${page}&itemsPerPage=30`)
        .then(res => res.data).catch(e => e),
    getById: (id: number) => axiosApiInstance.get<ResponseType<IOrderAttributeCol>>(`/order-attributes/${id}`).then(res => res.data).catch(e => Promise.reject(e)),
    removeById: (id: number) => axiosApiInstance.delete<ResponseType<any>>(`/order-attributes/${id}`).then(res => res).catch(e => Promise.reject(e)),
    updateById: (id: number, data: any) => axiosApiInstance.patch<ResponseType<IOrderAttributeCol>>(`/order-attributes/${id}`, data, {
        headers: {
            'Content-Type': 'application/merge-patch+json',
        },
    }).then(res => res).catch(e => Promise.reject(e)),
    // create: () => axiosApiInstance.post(`/orders/`).then(res => res).catch(e => Promise.reject(e)),

    import: (data:any)=>axiosApiInstance.post<IImport>('/order-attributes/import', data, {
        headers: { "Content-Type": "multipart/form-data" }
    }) .then(res => {
        return res.data['hydra:member']
    }).catch(e => Promise.reject(e)),

}