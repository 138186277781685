import React from "react";
import Container from "../ui/Container";
import Flex from "../ui/Flex";
import {Text, Title} from "../ui/Typography";
import {StyledTheme} from "../../styled";

export class ErrorBoundary extends React.Component {
    constructor(props:any) {
        super(props);
        this.state = { hasError: false, message: '' };
    }

static getDerivedStateFromError(error:any) {
    console.log(error)
        // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
        return { hasError: true, message: error.message };
    }

    componentDidCatch(error:any, errorInfo:any) {
        // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
        console.info(error, errorInfo);
    }

    render() {
        //@ts-ignore
        if (this.state.hasError) {
            // Можно отрендерить запасной UI произвольного вида
            return <Container>
                <Flex justify={'center'} direction={'column'} margin={'25px 0px'}>
                    <Title level={3} color={StyledTheme.colors.danger}>Что-то пошло не так...</Title>
                    {/*//@ts-ignore*/}
                    <Text>{this.state.message}</Text>
                </Flex>
            </Container>
        }
        //@ts-ignore
        return this.props.children;
    }
}