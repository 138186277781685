import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ordersHttp} from "../../api/OrdersHttp";
import {IFilterOrders, IOrders, IPagination} from "../../models/types";

interface IState {
    entries: IOrders[] | [],
    isLoading: boolean,
    isError: boolean,
    error: any | null,
    pagination: IPagination,
    filter: TypesFilter
}

const initialState = {
    entries: [], error: null, isError: false, isLoading: true,
    pagination: {total: 1, page: 1}, filter: {
        id: '', furnId: '', furnCode: '', name: '', "createdAt[after]": '', "createdAt[before]": ''
    }
} as IState

type TKeyFilter = keyof TypesFilter;
export type TypesFilter = Omit<IFilterOrders, 'page' | 'order[createdAt]' | 'order[updateAt]'>;


export const fetchingOrders = createAsyncThunk(
    'orders/fetching',
    async (filters: IFilterOrders, {rejectWithValue, dispatch, getState}) => {
        try {

            let filterUpdate ={...filters}
           if(filterUpdate['createdAt[before]'] === '') delete  filterUpdate['createdAt[before]']
           if(filterUpdate['createdAt[after]'] === '') delete  filterUpdate['createdAt[after]']
           if(filterUpdate['id'] === '') delete  filterUpdate['id']
           if(filterUpdate['furnId'] === '') delete  filterUpdate['furnId']
           if(filterUpdate['furnCode'] === '') delete  filterUpdate['furnCode']
           if(filterUpdate['name'] === '') delete  filterUpdate['name'];
           if(filterUpdate['member'] === '') delete  filterUpdate['member'];


            let data = await ordersHttp.getAll({
                ...filterUpdate
            });

            return data;

        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

interface PayloadActionOrders {
    entries?: [] | null,
    pagination: {
        total: number,
        page: number
    }
}
export interface IPayloadFilterChange{
key: TKeyFilter, value: string | undefined
}
const orderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        changeValueFilter: (state, action: PayloadAction<IPayloadFilterChange>) => {
            state.filter[action.payload.key] = action.payload.value
        },
        resetValueFilter: (state) => {
            state.filter= {
                id: '', furnId: '', furnCode: '', name: '', "createdAt[after]": '', "createdAt[before]": ''
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchingOrders.pending, (state, action: PayloadAction) => {
            state.isLoading = true;
            state.error = null;
            state.isError = false
            state.entries = [];
            state.pagination = {page: 1, total: 1}

        }),
            builder.addCase(fetchingOrders.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isError = false;
                state.entries = action.payload.orders;
                state.pagination = action.payload.pagination;
            }),

            builder.addCase(fetchingOrders.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.isError = true
                state.entries = [];
                state.pagination = {page: 1, total: 1}
            })


    },
});

const {actions, reducer: ordersReducer} = orderSlice;
export const OrderAction = actions;
export default ordersReducer;