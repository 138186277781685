import React from 'react';
import {Link} from "react-router-dom";
import {ROUTES} from "../../consts/routs";
import {IconEye} from "../ui/Icons";
import styled from "styled-components";

interface IProps {
    furnId: number,
    id: number,
    furnLink: string | null
}
const StyledLink = styled(Link)`
display: flex;
align-items: center;
`
const StyledA = styled.a`
display: flex;
align-items: center;
`
const TdOpenOrder = ({furnId, id, furnLink}: IProps) => {
    return (
        !furnId ? <StyledLink
                to={`${ROUTES.order_acceptance}/${id}`}><IconEye/>
        </StyledLink>
            : furnLink ?
                <StyledA target={'_blank'} href={furnLink}><IconEye/></StyledA> : <></>

    );
};

export default TdOpenOrder;