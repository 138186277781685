import {AppDispatch} from "../index";
import {CommonActions} from "../slice/CommonSlice";
import {ProjectActions} from "../slice/ProjectOrder/ProjectOrderSlice";
import {OrderStatusHttp} from "../../api/OrderStatusHttp";
import {AuthStatusCode} from "../../models/authTypes";

export const fetchingStatusGetAll = (page: number) => async (dispatch: AppDispatch) => {

    dispatch(CommonActions.toggleIsLoadingSearch(true));
    try {

        const data = await OrderStatusHttp.getAll(page);
        dispatch(ProjectActions.setStatusProject({
            entry: data.entry,
            page: data.page,
            total: data.total
        }))

        dispatch(CommonActions.toggleIsLoadingSearch(false));
    } catch (e) {
        //@ts-ignore
        if(e.hasOwnProperty('status') && e.status === AuthStatusCode.Forbidden){
            return false
        }
        dispatch(CommonActions.toggleError({
            error: e,
            isError: true
        }))
        dispatch(CommonActions.toggleIsLoadingSearch(false));
    }
}