import styled, {css} from "styled-components";
import {IPagination} from "../../../models/types";
import React, {useMemo, useRef, useState} from "react";
import {HiArrowDownCircle, HiArrowUpCircle} from "react-icons/hi2";
import {useOnClickOutside} from "../../../hooks/useOutsideClick";
import {AiOutlineCaretDown} from "react-icons/ai";
import Loader from "../Loader";

interface IProps {
    options: Array<any>,
    value: string,
    onChange: (value: string) => void,
    onLoadNext: (page: number) => void,
    onLoadPrev: (page: number) => void,
    pagination: IPagination,
    optionTitle: string,
    optionValue: string,
    isLoading?: boolean
}

const SelectContainer = styled.div`
  position: relative;
  user-select: none;
  width: 100%;
`
const OptionsContainer = styled.div<{ isOverflowAllSide?: boolean }>`
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #CDCCD7;
  border-top: 0;
  background: #fff;
  transition: all 0.5s;
  max-height: 250px;
  ${props => props.isOverflowAllSide ? 'overflow: hidden' : 'overflow-y: auto'};
  padding: 10px 0px;

  z-index: 2;
  opacity: 1;
  visibility: visible;
  pointer-events: all;
`
const Select = styled.div`
  align-items: center;
  justify-content: space-between;
  border: 1px solid #CDCCD7;
  padding: 12px 25px 12px 10px;
  font-size: 14px;
  font-weight: 400;
  color: #050038;
  display: block;

  position: relative;
  cursor: pointer;
  transition: all 0.5s;
`
const Option = styled.div<{ selected: boolean }>`

  ${props => props.selected && css`
    color: white;
    background: ${props.theme.colors.theme};
  `}
  border-bottom: 1px solid #CDCCD7;
  font-size: 14px;
  padding: 8px 10px;
  cursor: pointer;

  &:last-child {
    border-bottom: 0px;
  }

`
const SelectTitle = styled.span`
  font-size: 14px;
`
const IconArrow = styled(AiOutlineCaretDown)<{ isOpen: boolean }>`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%) rotate(0);
  ${props => props.isOpen && css`
    transform: translateY(-50%) rotate(180deg);
  `}
`
const ActionLoadWrap = styled.div`
  padding: 8px;
  position: absolute;
  top: 100%;
  z-index: 2;
  right: 1px;
  background: white;
  box-shadow: 0px 0px 5px #ccc;
  display: flex;
  flex-direction: column;
  gap: 10px;

  svg {
    font-size: 18px;
    cursor: pointer;
    //fill: ;
  }

  svg:hover {
    fill: ${props => props.theme.colors.theme};
  }
`
export const SelectDynamic = ({
                                  options,
                                  onLoadNext,
                                  onLoadPrev,
                                  value,
                                  onChange,
                                  pagination,
                                  optionValue,
                                  optionTitle, isLoading
                              }: IProps) => {
    const [isShow, setIsShow] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null)
    useOnClickOutside(ref, () => {
        setIsShow(false)
    });
    const title = useMemo(() => {
        let option = options.find(option => option[optionValue] === value)
        return option && option[optionTitle] ? option[optionTitle] : '-------'
    }, [value]);
    return <SelectContainer ref={ref}>
        <Select onClick={() => setIsShow(!isShow)}>
            <SelectTitle>{title}</SelectTitle>
            <IconArrow isOpen={isShow}/>
        </Select>
        {isShow && <OptionsContainer isOverflowAllSide={isLoading}>
            {isLoading && <Loader key={'loader-members'} isFixedParent={true}/>}
            {options.map(option => {
                return <Option selected={Boolean(value === option[optionValue])} onClick={() => {
                    onChange(option[optionValue])

                }}>{option[optionTitle]}</Option>
            })}

        </OptionsContainer>
        }
        {isShow && <LoadingOptionActions onLoadNext={onLoadNext} onLoadPrev={onLoadPrev} pagination={pagination}/>}


    </SelectContainer>
}

type ILoadingOptionActions = Pick<IProps, 'pagination' | 'onLoadPrev' | 'onLoadNext'>
const LoadingOptionActions = ({pagination, onLoadPrev, onLoadNext}: ILoadingOptionActions) => {
    const totalPage = Math.ceil(pagination.total / 30);

    let page = pagination.page;
    if (totalPage === 1) return <></>
    return <ActionLoadWrap>
        {page >= 2 && <HiArrowUpCircle onClick={() => onLoadPrev((page - 1))}/>}
        {page !== totalPage && <HiArrowDownCircle onClick={() => onLoadNext((page + 1))}/>}
    </ActionLoadWrap>
}