import React, {useMemo, useState} from 'react';
import Modal from "../../ui/Modal";
import Flex from "../../ui/Flex";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {CommonActions} from "../../../store/slice/CommonSlice";
import {fetchingCreateMedia, fetchingDeleteMedia} from "../../../store/service/FetchingMedia";
import {IMedia} from "../../../models/types";
import styled, {css} from "styled-components";
import UploadForm from "../../Form/UploadForm";
import MediaItem from "./MediaItem";
import Empty from "../../ui/Empty";


const StyledTabHead = styled(Flex)`
  justify-content: center;
  border-bottom: 1px solid ${props => props.theme.colors.bg_grey};
  margin-bottom: 15px;
`
const StyledTab = styled.div<{ isActive: boolean }>`
  padding: 8px;
  margin: 0px 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;

  ${props => props.isActive && css`
    color: ${props => props.theme.colors.theme};
  `}

`

const StyledCount = styled.span`
  color: ${props => props.theme.colors.theme};
  font-weight: bold;
`
const UploadMediaModal = () => {
        const dispatch = useAppDispatch();
        const {isOpen, detailing} = useAppSelector(state => state.common.modalUploadMedia);
        const {order} = useAppSelector(state => state.projectOrder);
        const mediasList = useMemo(()=>{
           if(order&& order.orderAttributes && detailing){
               let findPart = order.orderAttributes.find(part=> part.productNumber === detailing.productNumber);
               return  findPart && findPart.medias ? findPart.medias : []
           }
            return []
        }, [order, detailing])
        const [isOpenCreateTab, setIsOpenCreateTab] = useState<boolean>(true)
        const sendFiles = (uploadFile: any) => {
            let bodyFormData = new FormData();
            bodyFormData.append('file', uploadFile[0]);
            if (detailing) {
                dispatch(fetchingCreateMedia(bodyFormData, detailing.productNumber));
            }
        }

        const closeModal = () => {
            dispatch(CommonActions.toggleModalUploadMedia({isOpen: false, detailing: null}));
            setIsOpenCreateTab(false);

        }
        const onDeleteMedia = (media: IMedia) => {
            if (detailing) {
                dispatch(fetchingDeleteMedia(media, detailing.productNumber));
            }
        }

        return (
            <Modal
                onClose={closeModal} open={isOpen} title={'Файлы'} key={'modal_file_upload'}>
                <StyledTabHead justify={'start'}  key={'modal_file__head'}>
                    <StyledTab onClick={() => setIsOpenCreateTab(true)} isActive={isOpenCreateTab}>Добавить файл</StyledTab>
                    <StyledTab onClick={() => setIsOpenCreateTab(false)} isActive={!isOpenCreateTab}>Список загруженных
                        файлов {mediasList && <StyledCount>{mediasList.length}</StyledCount> }</StyledTab>
                </StyledTabHead>
                {isOpenCreateTab ?
                    <div  key={'modal_file_div_form'}>
                        <UploadForm sendFiles={sendFiles}  key={'modal_file_form'}/>
                    </div>
                    : <div  key={'modal_file_div_list'}>
                        {mediasList.length ? mediasList.map((media: IMedia) => {
                                    return <MediaItem contentUrl={media.contentUrl} onDeleteMedia={() => onDeleteMedia(media)} id={media.id} key={`media_item_${media.id}`}/>

                                }
                            ) :

                            <Empty title={'Пусто'} key={'modal_file_upload_empty'}/>}
                    </div>}
            </Modal>
        );
    }
;

export default UploadMediaModal;