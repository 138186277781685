import {StyledTable, TBody, TD, TFoot, TH, THead, TR} from './styled'
import React, {PropsWithChildren} from "react";
import {TypeBody, TypeHead, TypeTable, TypeTd, TypeTh, TypeTr} from "./models";
import Empty from "../Empty";


export const Table = ({ children, ...rest }:TypeTable) => {
    return <StyledTable {...rest}>{children}</StyledTable>;
};

Table.Head = ({ children, ...rest }:TypeHead) => {
    return <THead {...rest}>{children}</THead>;
};

Table.Body = ({ children, ...rest }:TypeBody) => {
    return <TBody {...rest}>{children}</TBody>;
};

Table.Foot = ({ children, ...rest }:any) => {
    return <TFoot {...rest}>{children}</TFoot>;
};

Table.TH = ({ children, ...rest }:TypeTh) => {
    return <TH {...rest}>{children}</TH>;
};

Table.TR = ({ children, ...rest }:TypeTr) => {
    return <TR {...rest}>{children}</TR>;
};
Table.TD = ({ children, ...rest }:TypeTd) => {
    return <TD {...rest}>{children}</TD>;
};

Table.Empty = ({children, ...rest}:PropsWithChildren<any>)=>{
    return <TR style={{border: 'none', background: 'transparent'}}>
        <TD colSpan={12} style={{border: 'none', background: 'transparent'}}><Empty/></TD>
    </TR>

}