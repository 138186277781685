import React, {useEffect, useState} from 'react';
import Modal from "../../ui/Modal";
import {Input} from "../../ui/Field";
import SearchMaterialItem from "./SearchMaterialItem";
import styled from "styled-components";
import {IMaterial} from "../../../models/types";
import InputCheckbox from "../../ui/Field/InputCheckbox";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {CommonActions} from "../../../store/slice/CommonSlice";
import useDebounce from "../../../hooks/useDebounce";
import {fetchingMaterialAddProject} from "../../../store/service/FetchingMaterial";
import {Title} from "../../ui/Typography";
import PaginationContainer from "../../ui/Pagination/PaginationContainer";
import {ProjectActions} from "../../../store/slice/ProjectOrder/ProjectOrderSlice";
import Loader from "../../ui/Loader";

const StyleSearchWrap = styled.div`
  top: -15px;
  padding: 15px 15px;
  background-color: white;
  z-index: 1;
  box-shadow: 0 0 5px #ccc;
  margin: -15px -6px 0px -6px;
  position: sticky;
`
const StylePagination = styled(PaginationContainer)`
  padding: 15px 0px;
  box-shadow: 0 0 5px #ccc;
  margin: 0px -15px -15px -15px;`


const Content = styled.div`
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  position: relative;
`
const SearchMaterialModal = () => {
    const [term, setTerm] = useState<string>('');
    const debounceTerm = useDebounce(term, 500);
    const dispatch = useAppDispatch();
    const {
        isOpen,
        selectedMaterial,
        pagination,
        findListMaterial, isLoading
    } = useAppSelector(state => state.common.materialModalSearch);
    const {materials} = useAppSelector(state => state.projectOrder)
    const onSelected = {
        remove: (id: string) => dispatch(CommonActions.removeModalSelectMaterial(id)),
        added: (material: IMaterial) => dispatch(CommonActions.addModalSelectMaterial(material))
    }
    // Effect for API call
    useEffect(
        () => {
            if (debounceTerm) {
                dispatch(fetchingMaterialAddProject(term, pagination.page));

            } else {
            }
        },
        [debounceTerm] // Only call effect if debounced search term changes
    );

    const onClose = () => {
        setTerm('');
        dispatch(ProjectActions.addMaterial(selectedMaterial));
        dispatch(CommonActions.resetSearchModal());
    }

    return (
        <Modal open={isOpen} onClose={onClose} title={'Поиск '} key={'modal_material_search'}>
            <StyleSearchWrap key={'modal_material_search_header'}>
                <Input padding={'8px 10px'}
                       key={'modal_material_search_input'}
                       placeholder={'Лдсп...'} width={'100%'} type={'text'} value={term}
                       onChange={(e) => setTerm(e.target.value)}/>
            </StyleSearchWrap>
            <Content key={'modal_material_search_content'}>
                {isLoading && <Loader key={'material-loading'} isFixedParent={true} />}
                {findListMaterial.length ?
                    findListMaterial.filter(item=> materials && !materials.find((material:IMaterial) => material['@id'] === item['@id'])).map<any>(item => {
                        let selected = selectedMaterial && selectedMaterial.find(select => select['@id'] === item['@id'])
                        return <SearchMaterialItem key={`material_search_item_${item['@id']}`} >
                            <InputCheckbox key={`material_item_input${item['@id']}`}
                                           id={`material_checked${item['@id']}`}
                                           htmlFor={`material_checked${item['@id']}`}
                                           checked={Boolean(selected)}
                                           variant={'check'} onChange={(e) => {
                                if (e.target.checked) {
                                    onSelected.added(item);
                                } else {
                                    onSelected.remove(item['@id'])
                                }
                            }}/>
                            <Title level={5} margin={'0px 0px 0px 8px'} key={'modal_material_search_title'}>{item.name}</Title>
                        </SearchMaterialItem>
                    }) : term ? <Title level={4} margin={'25px auto'} key={'modal_material_search_result'}></Title> : <></>}
            </Content>
            <>
                <StylePagination key={'modal_material_search_pagination'}
                    page={pagination.page} setPage={(page) =>
                    dispatch(fetchingMaterialAddProject(term, page))} limit={30} total={pagination.total}/>
            </>


        </Modal>
    );
};

export default SearchMaterialModal;