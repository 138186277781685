import React from 'react';
import Table from "../../ui/Table";
import Flex from "../../ui/Flex";
import styled from "styled-components";
import {BiArrowToBottom, BiArrowToLeft, BiArrowToRight, BiArrowToTop} from "react-icons/bi";
import TooltipInfo from "../TooltipInfo";


const styleIconEdge = `
font-size: 20px;
color: white;
fill: white;
`
const StyleIconTop = styled(BiArrowToTop)`
  ${styleIconEdge}
`
const StyleIconBottom = styled(BiArrowToBottom)`
  ${styleIconEdge}
`
const StyleIconLeft = styled(BiArrowToLeft)`
  ${styleIconEdge}
`
const StyleIconRight = styled(BiArrowToRight)`
  ${styleIconEdge}
`
const StyleSpanTH = styled.span`
  font-size: 14px;
  color: white;
  position: relative;
`
const StyledTooltipInfo = styled(TooltipInfo)`
  right: 0;
  & svg{
    font-size: 15px;
  }
`
const THeader = () => {
    return (
        <Table.Head background={'#605D81'} isSticky={true}>
            <Table.TH color={'white'} background={'#605D81'}>#</Table.TH>
            <Table.TH color={'white'} background={'#605D81'}>Наименование</Table.TH>
            <Table.TH color={'white'} background={'#605D81'}>Длина Х</Table.TH>
            <Table.TH color={'white'} background={'#605D81'}>
                <Flex align={'center'}>
                    <StyleSpanTH>Кромка</StyleSpanTH> <StyleIconTop/>
                </Flex>
            </Table.TH>
            <Table.TH color={'white'} background={'#605D81'}>
                <Flex align={'center'}><StyleSpanTH>Кромка</StyleSpanTH> <StyleIconBottom/></Flex>
            </Table.TH>
            <Table.TH color={'white'} background={'#605D81'}>Ширина У</Table.TH>
            <Table.TH color={'white'} background={'#605D81'}>
                <Flex align={'center'}><StyleSpanTH>Кромка</StyleSpanTH> <StyleIconLeft/></Flex>
            </Table.TH>
            <Table.TH color={'white'} background={'#605D81'}>
                <Flex align={'center'}><StyleSpanTH>Кромка</StyleSpanTH> <StyleIconRight/></Flex></Table.TH>
            <Table.TH color={'white'} background={'#605D81'}>   <StyleSpanTH>Количество</StyleSpanTH></Table.TH>
            <Table.TH color={'white'} background={'#605D81'}>
                <Flex align={'center'} >
                    <StyleSpanTH style={{paddingTop: '5px'}}>Текстура</StyleSpanTH>
                    <StyledTooltipInfo color={'white'}>
                        <li> соблюдать текстуру вдоль горизонтальной стороны по X</li>
                    </StyledTooltipInfo>
                </Flex>


            </Table.TH>
            <Table.TH color={'white'} background={'#605D81'}>Действие</Table.TH>
        </Table.Head>
    );
};

export default THeader;