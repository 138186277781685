import React from 'react';
import styled from "styled-components";

interface IStyleSpan {
    fontSize?: string,
    fontWeight?: string,
    margin?: string,
    color?: string
}

const StyleSpan = styled.span<IStyleSpan>`
  font-weight: ${props => props.fontWeight || '300'};
  font-size:  ${props => props.fontSize || '16px'};
  color:  ${props => props.color || props.theme.colors.black};
  margin: ${props => props.margin || '0px'};
`

const Span:React.FC<{children:JSX.Element} & IStyleSpan> = (props) => {
    return <StyleSpan {...props}/>
};

export default Span;