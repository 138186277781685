import React, {useCallback} from 'react';
import OrderEditName from "../../Orders/OrderEditName";
import {IconHome, IconSave, IconXlsx} from "../../ui/Icons";
import useCompareProjectState from "../../../hooks/useCompareProjectState";
import {fetchingEditOrder} from "../../../store/service/FetchingOrders";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {CommonActions} from "../../../store/slice/CommonSlice";
import styled from "styled-components";
import {fetchingSaveProject, fetchingSaveProjectAfterCallCb} from "../../../store/service/FetchingProjectData";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../consts/routs";
import Flex from "../../ui/Flex";

interface IProps {
    logoUrl: string
}

const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  min-width: 80px;
  width: 120px;

  & > img {
    max-width: 100%;
    object-fit: contain;
  }
`
const StyledActionsPanelOrder = styled(Flex)`
  @media (max-width: 425px) {
    order: 3;
  }
`
const HeaderActionOrder = ({logoUrl}: IProps) => {
    const {onSave, isChangeProjectState} = useCompareProjectState();
    let dispatch = useAppDispatch();
    const {order} = useAppSelector(state => state.projectOrder);
    const navigate = useNavigate();
    const onEditOrderName = useCallback((id: number, name: string) => {
        dispatch(fetchingEditOrder(id, {name: name}));
    }, [dispatch, order]);
    const onOpenImportModal = useCallback(() => {

        dispatch(CommonActions.toggleModalImportDetailing({isOpen: true, maxProductNumber: 0}))
    }, [dispatch, order]);

    const onSaveProject = async () => {
        await dispatch(fetchingSaveProjectAfterCallCb(
            () => {
                navigate(ROUTES.orders)
            }
        ));

    }
    return (
        <>
            <StyledLogo><img src={logoUrl} alt="" onClick={onSaveProject}/></StyledLogo>
            <StyledActionsPanelOrder align={'center'}>
                {order &&
                    <OrderEditName key={'icon-edit-name-project'} onChangeOrderName={onEditOrderName} id={order.id}
                                   name={order.name} isHead={true}/>}
                <IconSave key={'icon-save-project'} ischangeorder={Boolean(isChangeProjectState)} title={'Сохранить'}
                          onClick={() => {
                              onSave();
                          }}/>
                <IconXlsx key={'icon-xlsx-ation'} onClick={onOpenImportModal}/>

                <IconHome key={'go-lk'} onClick={onSaveProject}/>
            </StyledActionsPanelOrder>

        </>
    );
};

export default HeaderActionOrder;