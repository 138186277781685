import React from 'react';
import styled from "styled-components";
import { FaAngleDoubleLeft , FaAngleLeft, FaAngleRight, FaAngleDoubleRight} from "react-icons/fa";
import Pagination , { bootstrap5PaginationPreset } from 'react-responsive-pagination';
type StylePagination = {
    borderColor?: string,
    margin?:string,
}
const StylePaginationContainer = styled(Pagination)<StylePagination>`
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: center;
  margin: ${props => props.margin || '25px 0px'};
  > * {
    margin: 0px 3px;
  }
  & li{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &.active a{
      background: ${(props)=>  props.theme.colors.theme};
      border-color:  ${(props)=>  props.theme.colors.theme};
    }
    & > a:hover{
      background: ${(props)=>  props.theme.colors.theme};
      border-color:  ${(props)=>  props.theme.colors.theme};
      color: white;
      opacity: 0.8;
    }
    
    &.active a{
      color: white;
      
    }
    &.disabled:not(:first-child),
    &.disabled:not(:last-child){
      border: none;
     
    }
    
    &.disabled a,
    &.disabled span{
      opacity: 0.5;
    }
  }
  & li a{
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    font-family: inherit;
    color: ${(props)=> props.theme.colors.black};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${(props)=> props.borderColor || props.theme.colors.grey};
   
  }
`;



 const PaginationContainer = ({ page, setPage, total, limit , ...reset}:IPagination) => {
     const totalPage = Math.ceil(total/limit)
     if(totalPage === 1) return <></>
   return  <StylePaginationContainer
       { ...bootstrap5PaginationPreset }
       current={page}
       total={totalPage}
       onPageChange={setPage}
       previousLabel={'«'}
       nextLabel={'»'}
   />
};

interface IPagination extends StylePagination{
    page: number,
    setPage: (page:number)=> void,
    total: number,
    limit:number
}
export default PaginationContainer;
