import React, {useRef, useState} from 'react';
import {IOrders, IStatus} from "../../models/types";
import {useOnClickOutside} from "../../hooks/useOutsideClick";
import {IconClose, IconEdit} from "../ui/Icons";
import Flex from "../ui/Flex";
import {Select} from "../ui/Field";
import styled from "styled-components";


interface IProps {
    statusList: IStatus[] | null,
    onChangeStatus: (id:number, status: string) => void,
    order: IOrders,
    nameService: string

}

const StyledTooltip = styled.div`

  position: absolute;
  top: 0px;
  max-width: 220px;
  width: max-content;
  //width: 280px;
  background: white;
  box-shadow: 0 0 5px #ccc;
  padding: 8px;
  right: 0;
  z-index: 9;
`
const StyledFlex = styled(Flex)`
  position: relative;
`
const StyleText = styled(Flex)`
  font-size: 14px;
`
//::TODO редакторовать статус может только Админ
const TdOrderStatus = ({statusList, onChangeStatus, order, nameService}: IProps) => {
        const ref = useRef<any>();
        const [open, setOpen] = useState(false);
        const [value, setValue] = useState(order.status['@id']);
        const onHandlerOutside = () => {
            if ((value !== order.status["@id"] )&& value) {
                let {status, ...reset} = order;
                onChangeStatus(
                    order.id, value
                );
            }
            setOpen(false)

        }

        useOnClickOutside(ref, onHandlerOutside);
        if (order.furnCode) return <StyleText justify={'center'}>выгружен в {nameService} </StyleText>

        return (
            statusList && order.status
                ?
                <>
                    <StyledFlex align={'center'} justify={'center'}>
                        <StyleText align={'center'}>{order.status.name} <IconEdit
                            onClick={() => setOpen(true)}/></StyleText>

                        {open && <StyledTooltip ref={ref}>
                            <Flex justify={'end'} margin={'0px 0px 8px 0px'}>
                                <IconClose onClick={() => setOpen(false)}/>
                            </Flex>
                            <Select onChange={(e) => setValue(e.target.value)} width={'100%'} value={value}>
                                {Array.isArray(statusList) && statusList.map(item => {
                                    return <option value={item['@id']} selected={Boolean(value === item['@id'])}>{item.name}</option>
                                })}
                            </Select>
                        </StyledTooltip>}
                    </StyledFlex>

                </>
                : <></>
        );


};

export default TdOrderStatus;