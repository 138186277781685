import React from 'react';
import styled, {css} from "styled-components";

type VariantButtonStyle = 'theme' | 'danger';


export type ButtonProps = {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; // to handle onClick functions
    children?: React.ReactNode; // make the component able to receive children elements
    variant?: VariantButtonStyle,
    fontSize?: string,
    fontWeight?: string,
    margin?: string,
    padding?: string,
    disabled?: boolean;
    // type: string
};
const COLOR = {
    theme: css`
      background-color: ${props => props.theme.colors.theme};
      border: 2px solid ${props => props.theme.colors.theme};
      color: white;
      transition: background-color, color 0.25ms;

      &:hover {
        background: transparent;
        color: ${props => props.theme.colors.theme};
      }

      &:disabled {
        cursor: not-allowed;
        background: #d4d4d4;
        color: #f5f5f5;
        border-color: #d4d4d4;
      }
    `,
    danger:css`
      background-color: ${props => props.theme.colors.danger};
      border: 2px solid ${props => props.theme.colors.danger};
      color: white;
      transition: background-color, color 0.25ms;

      &:hover {
        background: transparent;
        color: ${props => props.theme.colors.danger};
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.8;
      }
    `
};
const DISABLED = css`
  cursor: not-allowed;
  background: #d4d4d4;
  color: #f5f5f5;
  border-color: #d4d4d4;
`;
const StyledButton = styled.button<ButtonProps>`
  padding: ${({padding}) => padding || '8px 10px'};
  font-size: ${({fontSize}) => fontSize || '16px'};
  font-weight: ${({fontWeight}) => fontWeight || '500'};
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  width: max-content;
  max-width: 100%;
  cursor: pointer;
  background-color: transparent;
  

  ${(props) => props.variant && COLOR[props.variant]};

  margin: ${({margin}) => margin || '0px'};
  ${(props) => props.disabled && DISABLED};
}
`


const Button = (props: ButtonProps) => {
    return <StyledButton {...props}/>;
};

export default Button;