import React, {PropsWithChildren} from 'react';
import styled from "styled-components";
import {Title} from "../Typography";
import { AiOutlineCloseSquare } from "react-icons/ai";
import Flex from "../Flex";
interface IStyleModal {
    open?: boolean
}
const StyleModal = styled.div<IStyleModal>`
  display: ${props => props.open ? 'flex' : 'none'};
  z-index: ${props => props.open ? '9' : '-1'};
  background: #00000054;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100vh;
  transition: all 0.25ms;

`
const StyleModalContent = styled.div`
  background: white;
  border-radius: 4px;
  width: 650px;
  max-width:100%;
`
const StyleModalHeader = styled(Flex)`
  border-bottom: 1px solid #eee;
  padding: 15px 8px 15px 8px;
  position: relative;
  width: 100%;
  min-height: 35px;
`
const StyleModalBody = styled.div<{isRevertOverflow?: boolean}>`
  padding: 15px 8px;
  max-height: calc(100vh - 60px);
  ${props => !props.isRevertOverflow && 'overflow-y: auto'};
`
const StyleIconClose = styled(AiOutlineCloseSquare)`
  cursor: pointer;
`
interface IProps extends  IStyleModal{
    onClose: ()=> void,
    title?:string,
    isRevertOverflowBody?: boolean
}

const Modal = ({children, open, title, onClose, isRevertOverflowBody}:PropsWithChildren<IProps>) => {
    if(!open) return <></>
    return (
        <StyleModal open={open}>
            <StyleModalContent>
                <StyleModalHeader direction={'row'} align={'center'} justify={'space-between'}>
                    <Title level={3} margin={'0px'}>{title}</Title>
                    <StyleIconClose onClick={()=> onClose()}  size={'20px'}/>
                </StyleModalHeader>
                <StyleModalBody isRevertOverflow={isRevertOverflowBody}>
                    {children}
                </StyleModalBody>
            </StyleModalContent>
        </StyleModal>
    );
};

export default Modal;